
:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');


.mobile-splash-v2{
    overflow-y: hidden;
}

.mobile-splash-nav-v2{
    height: 80px;
    /* width: calc(85% - 2px); */
    width: 100%;
    position:fixed;
    /* padding-left: 2.5%; */
    /* padding-right: 2.5%; */
    /* border-radius: 20px; */
    /* border: 1px solid rgba(0, 0, 0, 0.078); */
    background-color: rgba(255, 255, 255, .85);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(15px);
    transition: background-color .35s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    /* background: red; */
}

.mobile-splash-tray-v2{
    height: 100vh;
    width: 100vw;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    overflow: scroll;
}
.test-long{
    height: 10000px;
    width: 100%;
    /* background: var(--launch-colour); */
}

.mobile-splash-tray-v2-worky{
    /* height: 100px; */
    /* width: 100%; */
    /* background: red; */
    /* margin-top: -120px; */
    display: flex;
    position: absolute;
    top: 30px;
    /* align-items: center; */
    justify-content: center;
    font-size: 45px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: var(--worky-colour);
    cursor: pointer;
    overflow: scroll;
}


.mobile-splash-tray-v2-button-holder{
    /* height: 500px; */
    padding-top: 40px;
    margin-top: 80px;
    width: 100%;
    /* background: blue; */
    display: flex;
    flex-direction: column;
}


.product-mobile-accordian{
    display: flex;
    align-items: center;
    justify-content: center;

}

.mobile-splash-tray-accordian::before{
    display: none;
}

.mobile-splash-tray-accordian{
    border: none;
    box-shadow: none;
    font-size: 40px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    color: var(--text-colour);
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content:flex-start;
    /* gap: 5px; */
}

.mobile-splash-tray-v2-option{
    border: none;
    background: none;
    height: 60px;
    /* background: red; */
    padding-left: 16px;
    margin: 0px;
    font-size: 40px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    color: var(--text-colour);
    color: #d7d7d7;
    display: flex;
    
    align-items: center;
    justify-content:flex-start;
    /* gap: 5px; */
}

.accordian-product-popdown{
    height: 170px;
    width: 100%;
    /* background: blue; */
    overflow-x: scroll;
    display: flex;
    overflow-y: hidden;
}
.accordian-careers-popdown{
    height: 70px;
    width: 100%;
    display: flex;
    gap:5px;
    align-items: center;
    /* justify-content: center; */
    /* background: blue; */
    overflow-x: scroll;
    overflow-y: hidden;
}








.splash-section1-holder-v2-mobile{
    height: calc(100vh - 100px);
    padding-top: 100px;
    width: 100%;
    margin: auto;

}


.splash-slider-container-mobile{
    /* height: calc(82.5% - 80px); */
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-top: 5%; */
    /* padding-bottom: 2.5%; */
    justify-content: space-between;
}

.splash-overlay-title-mobile{
    font-family: 'DM Sans', sans-serif;
    font-size: 70px;
    font-weight: 600;
    line-height: 80px;
    color: var(--text-colour);

}

.splash-behind-mobile{
    padding-top: 0px;
    height: 95%;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.splash-overlay-subtitle-mobile{
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    font-weight: 500;
    /* width: 500px; */
    color: #7d7d7d;
    padding-top: 20px;
    padding-bottom: 7.5px;
    /* background: red; */
}



@keyframes GFG { 
    0% { 
        transform: rotate(0deg) translateY(15px) rotate(0deg) scale(0.75); 
    } 

    100% { 
        transform: rotate(360deg) translateY(15px) rotate(-360deg) scale(0.75); 
    } 
} 
@keyframes GFG2 { 
    0% { 
        transform: rotate(0deg) translateY(-15px) rotate(0deg) scale(0.75); 
    } 

    100% { 
        transform: rotate(-360deg) translateY(-15px) rotate(360deg)scale(0.75); 
    } 
} 

.splash-behind-third-img-mobile{
    height: 170px;
    width: 300px;
    /* background: blue;/ */
    display: flex;
    cursor: pointer;
    position: absolute; /* Ensure it's positioned relative to parent */
    bottom: 60px;
    right: 35%;
    animation: GFG 35s linear infinite;
    z-index: 2; /* Higher than main image */
    transform: scale(0.7);
}

.splash-behind-third-img-mobile img{
    height: 150px;
    border-radius: 150px;
    border: 5px solid var(--worky-colour);
    opacity: 0.95;
}

.splash-behind-sec-img-mobile{
    height: 175px;
    width: 300px;
    /* background: blue; */
    overflow: hidden;
    position: absolute; /* Ensure it's positioned relative to parent */
    /* top: 410px; */
    /* right: 10%; */
    display: flex;
    bottom: -180px;
    right: -30px;
    flex-direction: row-reverse;
    animation: GFG2 35s linear infinite;
    transform: scale(0.8);
    z-index: 2; /* Higher than main image */
    
}

.splash-partner-container-mobile{
    /* background: red; */
    height: 300px;
    padding-top: 150px;
    padding-bottom: 20px;
}

.splash-partner-title-mobile{
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #7d7d7d;
    /* padding-top: 50px; */
    /* background: red; */
}

.worky-mission-mobile{
    width: 95%;
    margin: auto;
    margin-top: 50px;
    /* background-color: red; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    font-family: 'DM Sans', sans-serif;
    font-size: 75px;
    font-weight: 600;
    line-height: 90px;
    color: var(--text-colour);
}

.worky-mission-details-mobile{
    height: auto;
    width: 90%;
    /* background-color: red; */
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 50px;

}

.worker-employer-box-mobile{
    /* height: 600px; */
    /* width: calc(80% - 40px); */
    width: 95%;
    /* padding-left: 20px; */
    /* padding-right: 20px; */
    
    background: white;
    margin: auto;
    /* border-radius: 35px; */
    /* margin-top: 30px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding-top: 50px;
}

.worker-employer-box-info-mobile{
    height: auto;
    width: 100%;
}

.worker-employer-box-img-mobile{
    width: 100%;
    height: 500px;
    background-color: red;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.worker-employer-box-img-mobile img{
    height: 500px;
}

.worky-product-container-mobile{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.worky-product-content-container-mobile{
    height: 575px;
    width: 100%;
    /* background: var(--launch-highlight); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 20;
}

.worky-product-title-mobile{
    font-size: 75px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    color: var(--text-colour);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.worky-product-img-container-mobile{
    height: 575px;
    width: 100%;
    transition: 0.15s ease-in-out;
    /* border-radius: 50px; */
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    margin-top: -150px;
    /* overflow: hidden; */

}
.worky-product-img-container-mobile img{
    width: 100%;
    transition: 0.15s ease-in-out;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* overflow: hidden; */

}

.splash-contact-subtitle-v2-mobile{
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    /* width: 400px; */
    color: #7d7d7d;
    /* padding-top: 20px; */
    /* padding-bottom: 5px; */
}

.splash-contact-buttons-v2-mobile{
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    justify-content: center;
}

.splash-contact-button-v2-mobile{
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #7d7d7d;
    border: none;
    font-family: 'DM Sans', sans-serif;
    background: none;
    background: var(--launch-colour2);
    border-radius: 20px;
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    transition: 0.15s ease-in-out;
}

.splash-contact-inner-mobile{
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    height: auto;
}

.standard-basic-input-mobile{
    width: 100%;
    height: 75px; 
}
.standard-basic-message-mobile{
    width: 100%;
}
.splash-contact-container{
    height: auto;
    width: 100%;
    /* background: red; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
}
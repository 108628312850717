:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}



/* 
Checklist

    - create 2 overlays
        - one for under nav and just add it to the is visable block
        - one for the popups
    - create popup and route emails 

*/

#overlay{
    height: 100%;
    width: 100%;
    background: black;
    opacity: 40%;
    z-index: 999;
    position: fixed;
    transition: all ease-in-out 0.4s;
}




#employers-popup-mobile{
    height: auto;
    z-index: 1001;
    width: 90vw;
    background: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 35px;
    box-shadow: 0 0 50px rgba(0,0,0,0.3);
    transition: all ease-in-out 0.4s;
}

#employee-popup-mobile{
    height: auto;
    z-index: 1001;
    width: 90vw;
    background: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 35px;
    box-shadow: 0 0 50px rgba(0,0,0,0.3);
    transition: all ease-in-out 0.4s;
}



.navbar-mobile{
    height: 70px;
    width: 70px;
    background: var(--launch-colour);
    border-radius: 35px;
    margin-top: 40px;
    margin-left: 30px;
    z-index: 1000;
    position: fixed;
    transition: all ease-in-out 0.15s;    
    animation-delay: 0.15s;
    box-shadow: 0px 0px 25px #888;


}

.navbar-mobile-hidden {
    transform: translateY(-25%);
    opacity: 0;
  }
  
.navbar-mobile-visible {
transform: translateY(0%);
}

.menu-mobile{
    height: 200px;
    width: 150px;
    margin-top: 100px;
    margin-left: 25px ;
    transition: all ease-in-out 0.15s;    
    animation-delay: 0.15s;
    transition-delay: 0.15s;
}

.menu-option-mobile{
    height:40px ;
    width: 150px;
    text-align: left;
    border: none;
    color: var(--text-colour);
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    background: none;
}


.menu-option-mobile:hover{
    color: var(--worky-colour);
}

#trynow-mobile{
    background-color: var(--worky-colour);
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
    float: left;
    color: white;
    border-radius: 21px;
    margin-top: 5px;
    margin-left: 7px;
    transition: all ease-in-out 0.15s;    

}

#trynow-mobile:hover{
    background: var(--hightlight-colour);
}


#logo-mobile{
    height: 52.5px;
    width: 52.5px;
    background: var(--worky-colour);
    float: left;
    color: var(--launch-colour);
    margin-left: 8.75px;
    margin-top: 8.75px;
    border-radius: 100%;
    border: none;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    transition: all ease-in-out 0.12s;
    text-align: center;
}

#logo-mobile:hover{
    background: var(--hightlight-colour);
}




/* launch screen start */

#section1-mobile{
    overflow: hidden;
    transition: 3s ease-in-out;
    transition-delay: 0.5s;
    background-repeat: no-repeat;
    background-size:cover;
    height: 100vh;
}

#section1-holder-mobile{
    margin-top: 300px;
}

#section1-title-mobile{
    color: var(--launch-colour);
    text-align: center;
    font-size: 11vw;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 600;
    font-style: normal;
    text-shadow: 0 0 5px var(--text-colour);
}

#section1-subtext-mobile{
    margin-top: 12px;
    margin-bottom: 20px;
    color: var(--launch-colour);
    text-align: center;
    font-size: 18px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 300;
    font-style: normal;
    text-shadow: 0 0 5px var(--text-colour);
}

#section1-button-holder-mobile{
    height: 50px;
    width: 100%;
    margin-top: 45px;
}

.section1-button-mobile{
    height: 40px;
    width: 150px;
    margin-left: 10px;
    margin-right:10px;
    background: var(--worky-colour);
    color:white;
    border-radius: 40px;
    border: var(--worky-colour) 2px solid;
    font-size: 14px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: all ease-in-out 0.12s;    

}
.section1-button-mobile:hover{
    border: var(--hightlight-colour) 2px solid;
    background: var(--hightlight-colour);
}

/* launch screen finish */








/* section2 start */

#section2-mobile{
    padding-top: 50px;
    padding-bottom: 0px;
    height:auto;
    width:100%;
}

#section2-title-mobile{
    text-align: left;
    padding-left: 9vw;
    /* font-size: 17vw; */
    font-size: 75px;
    color: var(--text-colour);
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    line-height: 90px;
    /* line-height: 12vh; */
}

#section2-subtext-mobile{
    padding-left: 9vw;
    padding-right: 9vw;
    margin-top: 25px;
    font-size: 19px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 60px;

}

.button-mobile{
    height: 38px;
    width: 160px;
    background: var(--worky-colour);
    color: white;
    border: none;
    border-radius: 40px;
    font-size: 14px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: all ease-in-out 0.12s;    

}

.button-mobile:hover{
    background: var(--hightlight-colour);
}

#section2-map-mobile{
    margin-top: 80px;
    height: auto;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;

    margin-bottom: 130px;
}
.section2-fast-mobile{
    padding-top: 40px;
    padding-bottom: 40px;

    height: auto;
    width: 80vw;
}
#icon-safe-m{
    background-image: url('/public/splash/safe.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 250px;
}
.section2-subtext-m{
    font-size: 19px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 25px;
    text-align: center;
    color: var(--text-colour);

}

#section22-title-mobile{
    padding-right: 9vw;
    font-size: 55px;
    color: var(--text-colour);
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 600;
    font-style: normal;
    text-align: right;

}
#span{
    font-weight: 700;
    color: var(--worky-colour);
}

#section22-subtext-mobile{
    margin-top: 50px;
    margin-bottom: 75px;
    padding-left: 9vw;
    padding-right: 9vw;
    margin-top: 25px;
    font-size: 19px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
}

#section22-photo-mobile{
    /* height: 300px; */
    /* background: red; */
    height: 250px;
    width: 80vw;
    margin-bottom: 50px;

    margin-left: auto;
    margin-right: auto;
    /* background-image: url('/public/splash/techsupport.jpg'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 50px;

}

/* section2 fin */

/* section 3 start */

@keyframes scroll{
    0%{transform: translateX(0);}
    100%{transform: translateX(calc(-350px * 7));}
}


/* Section 3 - carousel */

.section3-mobile{
    padding-top: 100px;
    padding-bottom: 150px;
    height: 150px;
    width: auto;
    margin: auto;
    overflow: hidden;
    position: relative;

}

.section3-slide-mobile{
    animation: scroll 45s linear infinite;
    display: flex;
    width: calc(350px * 14);
}

.logo-mobile{
    height: 150px;
    width: 500px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-image: url('/public/splash/company_one.png');
}




/* section 3 fin */


/* section 4 start */
#section4-mobile{

    padding-bottom: 200px;

}

#section4-title-mobile{

    padding-right: 9vw;
    font-size: 75px;
    color: var(--text-colour);
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: right;
}

#section4-subtext-mobile{
    padding-left: 9vw;
    padding-right: 9vw;
    margin-top: 25px;
    font-size: 19px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 50px;

}

#section4-image-mobile{
    margin-top: 50px;
    background-image: url('/public/splash/Mockup.png');
    background-size: cover;
    background-position: center;
    margin-left:13.5%;
    margin-right:6.5%;
    height: 75vh;
    width: 80%;
}

/* section 4 finish */
/* section 5 start */
#section5-mobile{
    padding-bottom: 222px;
    /* background: rgb(239, 239, 239); */
}

#section5-title-mobile{

    padding-left: 9vw;
    font-size: 75px;
    color: var(--text-colour);
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: left;
}

#section5-subtext-mobile{
    padding-left: 9vw;
    padding-right: 9vw;
    margin-top: 25px;
    font-size: 19px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 50px;

}

#section5-image-mobile{
    margin-top: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 75vh;
    width: 100%;
}



/* section 5 finish */

#section6-mobile{
    padding-bottom: 150px;
    padding-top: 20px;
}

#section6-title-mobile{
    padding-left: 9vw;
    font-size: 58px;
    color: var(--worky-colour);
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 600;
    font-style: normal;
    text-align: left;
}

#section6-subtext-mobile{
    padding-left: 9vw;
    padding-right: 9vw;
    font-size: 25px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: left;

}
#span2{
    font-weight: 600;
    color: var(--worky-colour);
}

#section6-subsubtext-mobile{
    padding-left: 9vw;
    padding-right: 9vw;
    text-align: left;

    font-size: 19px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
}

#section6-icons-mobile{
    height: 30px;
    width: 180px;
    margin-left: 9vw;
    margin-top: 50px;
    /* background: var(--worky-colour); */
}

.icon-mobile{
    height: 30px;
    width: 30px;
    background: var(--worky-colour);
    float: left;
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
    transition: all ease-in-out 0.12s;    

}

.icon-mobile:hover{
    background: var(--hightlight-colour);
}

#section6-form-mobile{
    margin-top: 50px;
    margin-left: 9vw;
    height: auto;
    padding-bottom: 80px;
    width: 80vw;
    background: var(--launch-colour);
    border-radius: 35px;

}





#fullname-m{
    height: 40px;
    width: 85%;
    background-color: white;
    border-radius: 35px;
    margin: 16px auto;
    color: var(--text-colour);
    text-indent: 17px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    margin-top: 25px;
    margin-bottom: 10px;
    margin-left: 5%;
    margin-right: 5%;
    border:none;
    border: 1.5px solid lightgrey;
    /* box-shadow: 0px 0px 3px lightgrey;} */
}

#email-m{
    height: 40px;
    width: 85%;
    background-color: white;
    border-radius: 35px;
    margin: 16px auto;
    color: var(--text-colour);
    text-indent: 17px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5%;
    margin-right: 5%;
    border: 1.5px solid lightgrey;
}

#subject-m{
    height: 46px;
    width: calc(85% + 14px);
    background-color: white;
    border-radius: 35px;
    margin: 16px auto;
    color: #757575;
    text-indent: 17px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5%;
    margin-right: 5%;
    border: 1.5px solid lightgrey;
}

#message-m{
    height: 120px;
    width: 85%;
    background-color: white;
    border-radius: 25px;
    margin: 16px auto;
    color: var(--text-colour);
    text-indent: 17px;
    padding-top: 9px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5%;
    margin-right: 5%;
    border: 1.5px solid lightgrey;
    max-width: 85%;
    max-height: 180px;
    min-height: 40px;
    min-width: 85%;
}
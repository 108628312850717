:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}


.jobber-profile1{
    height: 45px;
    width: 100%;
    background: var(--launch-colour2);
    margin: auto;
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    /* justify-content: space-between; */
    /* background:yellow; */
}


.jobber-profile1:hover{
    background: var(--launch-colour);
}

.jobber-profile-icon1{
    height: 35px;
    width: 35px;
    /* background: red; */
    border-radius: 100%;
    margin-left: 15px;
}

.jobber-profile-icon1 img{
    height: 35px;
    width: 35px;
    border-radius: 100%;
}


.jobber-profile-name1{
    height: auto;
    width: calc(100% - 120px);
    padding-left: 15px;
    padding-right: 15px;
    /* background: green; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    color: var(--text-colour);
}





.jobber-profile2{
    margin-top: 5px;
    margin-bottom: 5px;
    height: 45px;
    width: 100%;
    background: var(--launch-colour2);
    margin: auto;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    /* background:yellow; */
    overflow:hidden;

}

.jobber-profile2:hover{
    background: var(--launch-colour);
}


.jobber-profile-icon2{
    height: 35px;
    width: 35px;
    /* background: red; */
    border-radius: 100%;
    margin-left:10px;
}
.jobber-profile-icon2 img{
    height: 35px;
    width: 35px;
    border-radius: 100%;
}


.jobber-profile-name2{
    height: auto;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    color: var(--text-colour);
    padding-left: 15px;
    padding-right: 15px;
    position: inherit;
    height: auto;
    width: calc(100% - 70px);
    padding-left: 15px;
    padding-right: 15px;
    /* background: green; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.jobber-profile-select2{
    height: 35px;
    width: 65px;
    /* background: blue; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
}



.jobber-profile-like2{
    height: 30px;
    width: 30px;
    background: red;
    border: none;
    border-radius: 100%;
}



.jobber-profile-dislike2{
    height: 30px;
    width: 30px;
    background: green;
    border: none;
    border-radius: 100%;
}


.test-div2{
    height: 100px;
    width: 500px;
    background: var(--launch-highlight);
    margin: auto;
    padding-top: 10px;
}




.jobber-profile-modal{
    height: auto;
    width: 450px;
    background: white;
    border-radius: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding-bottom: 12.5px;
}
.jobber-profile-modal-mobile{
    height: auto;
    width: 90%;
    background: white;
    border-radius: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding-bottom: 12.5px;
}



.jobber-profile-modal-top{
    height: auto;
    width: 95%;
    margin: auto;
    border-radius: 20px;
    /* background: var(--launch-colour2); */
    margin-top: 11.25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.jobber-profile-modal-top-icon{
    height: 95px;
    width: 95px;
    /* background: var(--launch-highlight); */
    border-radius: 100%;
}

.jobber-profile-modal-top-icon img{
    height: 95px;
    width: 95px;
    border-radius: 100%;
}

.jobber-profile-modal-top-details-holder{
    height: auto;
    padding-bottom: 10px;
    width: 100%;
    /* background: var(--launch-highlight); */
    /* margin-left: 25px; */
    margin-top: 10px;
}

.jobber-profile-modal-top-name{
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 28px;
    color: var(--text-colour);
    text-align: center;
    padding-bottom: 5px;
    /* background: red; */

}

.jobber-profile-modal-top-contact{
    height: auto;
    width: auto;
    /* background: red; */
    /* background: red; */
    display: flex;
    justify-content: center;
    /* justify-content: space-between; */
    gap: 5px;
}

.jobber-profile-modal-top-contact-info{
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    /* font-size: 28px; */
    font-size: 13px;
    color: var(--text-colour);
    text-align: left;
    /* width: 150px; */
    padding-left: 7.5px;
    padding-right: 7.5px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: var(--launch-colour2);
    cursor: pointer;
    border-radius: 10px;
    display: inline-block;
}

.jobber-profile-modal-top-contact-info:hover{
    background:var(--launch-colour);
    /* color: var(--worky-colour); */
}


.jobber-rating{
    /* background: red; */
    display: flex;
}

.jobber-profile-modal-bottom{
    height: auto;
    width: 95%;
    /* background: var(--launch-colour2); */
    margin: auto;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}


.jobber-profile-modal-resume{
    height: auto;
    width: calc(22.888888% - 10px);
    background: var(--launch-colour2);
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-colour);
}

.jobber-profile-modal-resume:hover{
    background: var(--launch-colour);
}

.accordian-title{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    color: var(--text-colour);
    padding-left: 10px;
}

.profile-summary-text{
    /* background: red; */
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: var(--text-colour);
    text-align: left;
}







.jobber-profile-modal-stars-holder{
    height: 35px;
    width: 100%;

    /* background: red; */
}


.jobber-profile-view-ratings{
    max-height: 155px;
    height: auto;
    width: 95%;    
    margin: auto;
    margin-top: 10px;
    border-radius: 20px;
    background: var(--launch-colour2);
    
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
    overflow-y: auto;
}

.jobber-profile-view-ratings-title{
    height: auto;
    width: 100%;
    /* border-top-left-radius: 20px; */
    /* border-top-right-radius: 20px; */
    padding-top: 10px;
    padding-bottom: 5px;
    /* background: var(--launch-colour2); */
    /* position: fixed; */
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    color: var(--text-colour);
}

.org-rating{
    min-height: 40px;
    height: auto;
    width: 95%;
    background: white;
    border-radius: 15px;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 7.5px;
}


.org-rating-name{
    height: 22.5px;
    width: 120px;
    /* background: red; */
    font-size: 12.5px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    text-align: left;
    /* padding: 5px; */
    /* padding-top: 5px; */
    margin-left: 12.5px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* padding-bottom: 2.5px; */
    color: var(--launch-highlight);
}


.org-rating-value{
    height: auto;
    width: 95%;
    padding-left: 10px;
    /* overflow-x: scroll; */
    /* background: red;     */
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
    display: flex;
    /* background: red; */
    gap: 15px;
    align-items: center;
}

.org-rating-value-star{
    /* background: blue; */
    display: flex;
}

.org-rating-value-text{
    /* background: red; */
    display: flex;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
}











.jobber-profile-send-ratings{
    height: 50px;
    width: 95%;
    margin: auto;
    margin-top: 10px;
    border-radius: 20px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    background: var(--launch-colour2);
}

.jobber-rating-send-input{
    height: 32px;
    width: 100%;
    text-indent: 12.5px;
    border: none;
    border-radius: 15px;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
}

.jobber-rating-send-button{
    height: 32px;
    width: 32px;
    background: var(--worky-colour);
    border: none;
    border-radius: 12.5px;
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-left: 5px;
}

.jobber-rating-send-button:hover{
    background: var(--hightlight-colour);
}

.jobber-rating-send-stars{
    margin-left: 10px;
    margin-right: 5px;
}
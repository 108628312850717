:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}
@import url('https://fonts.googleapis.com/css2?family=DM Sans:wght@400;700&display=swap');

.splash-body{
    position: absolute;
    height: auto;
    width: 100%;
    background: white;
    /* overflow-x: hidden; */
    /* padding-top: 25px; */

}
html{
    overflow-x: hidden;
}

.gradient-blue{
    background: linear-gradient(90deg, #5a9bd6, #2c68bd);
    -webkit-background-clip: text;
    color: transparent;
    background-clip: text;
}


.splash-navbar{
    height: 65px;
    width: calc(85% - 2px);
    position:fixed;
    margin-left: 7.5%;
    margin-right: 7.5%;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.078);
    background-color: rgba(245,245,247,.1);
    -webkit-backdrop-filter: blur(75px);
    backdrop-filter: blur(75px);
    transition: background-color .35s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
}


.splash-navbar-v3{
    height: 80px;
    /* width: calc(85% - 2px); */
    width: 90%;
    position:fixed;
    padding-left: 5%;
    padding-right: 5%;
    /* border-radius: 20px; */
    /* border: 1px solid rgba(0, 0, 0, 0.078); */
    background-color: rgba(255, 255, 255, .85);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(15px);
    transition: background-color .35s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    /* background: red; */
}

.splash-navbar-logo{
    font-size: 30px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: var(--worky-colour);
    padding-left: 20px;
    cursor: pointer;
    transition: ease-in-out 0.15s;

}
.splash-navbar-logo:hover{
    color: var(--hightlight-colour);
}


.splash-navbar-menu{
    height: 80%;
    /* background: red; */
    /* width: 300px; */
    width: auto;
    /* margin-left: -100px; */
    margin-left: 57.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 27.5px;
    /* padding-left: 15px; */
    /* padding-right: 15px; */
}

@keyframes wiggle-expand {
    0% {
        transform: rotate(0deg) scale(1);
    }
    25% {
        transform: rotate(-3deg) scale(1.3);
    }
    50% {
        transform: rotate(3deg) scale(1.3);
    }
    75% {
        transform: rotate(-3deg) scale(1.3);
    }

}


#demo-option{
    color: var(--worky-colour);
    background: var(--launch-colour2);
    transition: ease-in-out 0.15s;
}

#demo-option:hover{
    /* transform: scale(1.1); */
    animation: wiggle-expand 0.8s ;
    background: var(--worky-colour);
    color: white;
}
.popper-drawer {
    padding-top: 15px;
    padding-bottom: 50px;
    background-color: rgba(255, 255, 255, .85);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(15px);
    transition: background-color .35s;
    width: 100vw;
    max-width: 100vw;
    margin-top: 20px;
    z-index: 9999999999999;
    box-shadow: 0 5px 5px 0 rgba(111, 111, 111, 0.1);
}





#simple-popper{
    /* background: red; */
    transition: 0.275s ease-out;
    z-index: 5;
}
#simple-popper2{
    /* background: red; */
    transition: 0.275s ease-out;
    z-index: 5;
}

.popper-drawer-title{
    height: 30px;
    width: 80%;
    /* background: red;     */
    margin: auto;
    display: flex;
    align-items: center;
    color: #7d7d7d;
    color: var(--launch-highlight);
    font-family: 'DM Sans', sans-serif;
    font-size: 15.5px;
    font-weight: 600;
    letter-spacing: 0.5px;
}
.popper-drawer-line{
    height: 1.5px;
    width: 80%;
    background: var(--launch-colour);
    margin: auto;
}

.popper-products-holder{
    height: auto;
    width: auto;
    /* padding-left: 25px; */
    /* padding: 25px; */
    /* padding-right: 25px; */
    /* padding-right: 25px; */
    /* padding-right: 25px; */
    /* margin-top: 35px; */
    background-color: rgba(255, 255, 255, .98);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(35px);
    transition: background-color .35s;
    border-radius: 25px;
    box-shadow: 0 0px 5px 2px rgba(111, 111, 111, 0.1);
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    margin-top: 300px;
    
}
.popper-careers-holder{
    height: auto;
    width: auto;
    /* padding-left: 25px; */
    padding: 25px;
    /* padding-right: 25px; */
    /* padding-right: 25px; */
    /* padding-right: 25px; */
    /* margin-top: 35px; */
    background-color: rgba(255, 255, 255, .98);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(35px);
    transition: background-color .35s;
    border-radius: 25px;
    box-shadow: 0 0px 5px 2px rgba(111, 111, 111, 0.1);
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    margin-top: 313px;
}

.popper-careers-holder-inner{
    display: flex;
    width: 80%;
    /* background: green; */
    gap: 10px;
    margin: auto;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    flex-wrap: wrap;
}

.popper-careers-inner{
    height: auto;
    width: 260px;
    /* background: green; */
    gap: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

#view-all-jobs{
    width: 118px;
    background: none;
    /* background: var(--launch-colour2); */
}
#view-all-jobs-title{
    padding-right: 0px;
    padding-left: 15px;
    color: var(--worky-colour);
}
#view-all-jobs-icon{
    padding-left: 0px;
    padding-right: 5px;
    color: var(--worky-colour);
}

#view-all-jobs:hover{
    background: var(--launch-colour2);
}


.popper-job{
    height: 45px;
    width: 260px;
    background: none;
    border: none;
    /* background: var(--launch-colour2); */
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 10px;
    transition: 0.15s ease-in-out;
}

.popper-job:hover{
    background: var(--launch-colour2);
}
.popper-job:hover .popper-job-icon{
    color: var(--worky-colour);
    animation: wiggle-expand 0.8s ;
}
.popper-job:hover .popper-job-title{
    color: var(--worky-colour);
}

.popper-job-icon{
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    color: #7d7d7d;
    /* background: red; */
}

.popper-job-title{
    color: #7d7d7d;
    font-family: 'DM Sans', sans-serif;
    font-size: 13.5px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 25px;
    
    

}
.popper-job-arrow{
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}



.popper-products-close{
    /* background: white; */
    background: var(--launch-colour2);
    height: 40px;
    width: 40px;
    border: none;
    border-radius: 35px;
    color: var(--worky-colour);
    color: #7d7d7d;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ease-in-out 0.15s;
    position:absolute;
    top: -15px;
    right: -15px;
}
.popper-products-close:hover{
    background: var(--launch-colour);
    color: var(--worky-colour);
}

.popper-products-holder-inner{
    display: flex;
    width: 80%;
    /* background: green; */
    gap: 25px;
    margin: auto;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.popper-product{
    height: 162.5px;
    width: 220px;
    border: none;
    border-radius: 15px;
    background: none;
    /* background: var(--launch-colour2); */
    /* background: white; */
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 1px;
    flex-direction: column;
    /* box-shadow: 0 0px 3px 0px rgba(111, 111, 111, 0.1); */
    transition: 0.15s ease-in-out;
}


#view-all-popper-product{
    height: 162.5px;
    width: 100px;
    border: none;
    background: none;
    /* background:var(--launch-colour2); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    padding-top: 15px;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: var(--worky-colour);
    transition: 0.15s ease-in-out;
    border-radius: 15px;
    cursor: pointer;
}

#view-all-popper-product:hover{
    background:var(--launch-colour2);
    color: var(--worky-colour);
}
#view-all-popper-product:hover .view-all-products-icon{
    animation: wiggle-expand 0.8s ;

}




.popper-product:hover{
    background: var(--launch-colour2);
}

.popper-product:hover .popper-product-inner-img img{
    transform: scale(1.45);
}

.popper-product:hover .popper-product-inner-info{
    color: var(--worky-colour);
}
.popper-product-inner-img{
    height: 120px;
    background: white;
    width: 205px;
    margin-top: 7.5px;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.15s ease-in-out;
}

.popper-product-inner-img img{
    height: 180px;
    transition: 0.15s ease-in-out;
}



.popper-product-inner-info{
    height: 20px;
    /* background: white; */
    display: flex;
    color: #7d7d7d;
    align-items: center;
    justify-content: center;
    width: 235px;
    margin-top: 7.5px;
    border-radius: 10px;
    cursor: pointer;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-weight: 700;
}

.splash-navbar-option{
    height: 30px;
    background: none;
    border: none;
    background: none;
    cursor: pointer;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #7d7d7d;
    border-radius: 15px;
    padding-left: 12.5px;
    padding-right: 12.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ease-in-out 0.15s;

}
.splash-navbar-option:hover{
    /* font-weight: 600; */
    color: var(--worky-colour);
    background: var(--launch-colour2);

}


.splash-navbar-buttons{
    height: 100%;
    /* background: red; */
    display: flex;
    align-items: center;
    padding-right: 10px;
    gap: 10px;

}

.splash-navbar-login{
    border: none;
    background: none;
    font-size: 15px;
    cursor: pointer;
    color: var(--text-colour);
    font-family: 'DM Sans', sans-serif;
    transition: ease-in-out 0.15s;
}
.splash-navbar-login:hover{
    color: var(--dark-text);
    font-weight: 600;
}

.splash-navbar-trynow{
    height: 40px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    border: none;
    background: var(--worky-colour);
    border-radius: 13px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
    color: white;
    cursor: pointer;
    transition: ease-in-out 0.15s;

}

.splash-navbar-trynow:hover{
    background: var(--hightlight-colour);
}



.splash-section1{
    /* height: calc(100% + 30px); */
    /* width: 100%; */
    /* background: green; */
    /* background: var(--launch-highlight); */
    height: auto;
    margin-top: -30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
    background: white;
    padding-bottom: 100px;
}

.splash-section1-title-holder{
    height: 180px;
    /* width: 1000px; */
    /* background: var(--launch-colour); */
    /* margin-bottom: 10px; */
    margin-top: 150px;
    border-radius: 35px;
}





.splash-section1-title{
    /* font-family: 'DM Sans', sans-serif; */
    /* font-family: "mendl-sans-dusk", sans-serif; */
    /* font-family: "Poppins", sans-serif; */
    font-family: "DM Sans", sans-serif;
    font-weight: 900;
    font-style: normal;

    /* font-weight: 700; */
    color: var(--text-colour);
    font-size: 90px;
    /* text-shadow: 0px 0px 4px #333; */

}

.splash-section1-subtitle{
    /* font-family: 'DM Sans', sans-serif; */
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 600;
    color: var(--text-colour);
    font-size: 55px;
    /* padding-top: 5px; */
}


.splash-section1-text{
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 500;
    color: var(--text-colour);
    font-size: 35px;
    padding-top: 10px;

}




.splash-section1-button-holder{
    height: 40px;
    width: 500px;
    padding-top: 60px;
    /* background: white; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    /* margin-top: -15px; */
    margin-bottom: 20px;

}

.splash-section1-button{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    height: 40px;
    width: 180px;
    border: none;
    border-radius: 10px;
    background: var(--worky-colour);
    /* background: #148aff; */
    /* background: #0080ff; */
    color: white;
    cursor: pointer;
}

.splash-section1-button:hover{
    background: var(--hightlight-colour);
    /* background: var(--worky-colour); */
    /* background: #0080ff; */

}

.splash-section1-slider{
    height: auto;
    width: 85%;

    background: white;
    /* background: white; */
    border-radius: 35px;
}
.fade{
    /* border-radius: 40px; */
    border-radius: 80px;
}
.each-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    /* height: 100%; */
  }

 .each-slide img{
    /* width: 100%; */
    height: 750px;

 } 

.slide1{
    height: 300px;
    width: 450px;
    background-color: rgba(245,245,247,.7);
    -webkit-backdrop-filter: blur(75px);
    backdrop-filter: blur(15px);
    position: absolute;
    left: 50px;
    border-radius: 35px;
}

.slide2{
    height: 300px;
    width: 650px;
    background-color: rgba(245,245,247,.7);
    -webkit-backdrop-filter: blur(75px);
    backdrop-filter: blur(15px);
    position: absolute;
    border-radius: 35px;
}

.slide3{
    height: 270px;
    width: 650px;
    background-color: rgba(245,245,247,.7);
    -webkit-backdrop-filter: blur(75px);
    backdrop-filter: blur(15px);
    position: absolute;
    left:45px;
    bottom: 45px;
    border-radius: 30px;
}

.slide4{
    height: 300px;
    width: 500px;
    background-color: rgba(245,245,247,.7);
    -webkit-backdrop-filter: blur(75px);
    backdrop-filter: blur(15px);
    position: absolute;
    left:60px;

    border-radius: 30px;
}

.slide5{
    height: 225px;
    width: 550px;
    background-color: rgba(245,245,247,.7);
    -webkit-backdrop-filter: blur(75px);
    backdrop-filter: blur(15px);
    position: absolute;
    right:40px;
    bottom: 40px;
    border-radius: 30px;
}

.slide6{
    height: 425px;
    width: 425px;
    background-color: rgba(245,245,247,.7);
    -webkit-backdrop-filter: blur(75px);
    backdrop-filter: blur(15px);
    position: absolute;
    right:40px;
    /* top: 40px; */
    border-radius: 30px;
}

.slide7{
    height: 315px;
    width: 420px;
    background-color: rgba(245,245,247,.7);
    -webkit-backdrop-filter: blur(75px);
    backdrop-filter: blur(15px);
    position: absolute;
    left:40px;
    /* top: 40px; */
    border-radius: 30px;
}


.splash-section2{
    height: auto;
    width: 100%;
    background-color: darkblue;
    padding-top: 50px;
    display: flex;
}

.splash-section2-inner{
    height: 275px;
    width: 85%;
    /* background: white; */
    margin-left: 7.5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 35px;
}

.splash-section2-inner-left{
    height: 100%;
    width: calc(50% - 15px);
    background: white;
    border-radius: 35px;

}

.splash-section2-inner-right{
    height: 100%;
    width: calc(50% - 15px);
    /* background: blue; */
    border-radius: 35px;

}

.splash-section6{
    height: auto;
    width: 100%;
    background-color: darkblue;
    padding-top: 175px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}
.splash-section6-title{
    height: 75px;
    width: 85%;
    background: white;

}

.splash-section6-inner{
    height: 175px;
    width: 85%;
    background: white;
    /* margin-left: 7.5%; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 35px;
}


.splash-section6-card{
    height: 150px;
    background: var(--launch-colour);
    width: calc(100% / 3 - 20px);
    border-radius: 30px;

}

















.splash-section3{
    height: 265px;
    width: 100%;
    /* background: white; */
    display:flex;
    /* align-items: center; */
    justify-content: center;
}
.splash-section3-inner{
    height: 215px;
    width: 80%;
    background: white;
    /* margin-top: -30px; */
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0 0 4px rgba(0,0,0,0.1);
}

.worky-stats-container{
    height: 100%;
    width: calc(100% / 3 - 50px);
    /* background: blue; */
    display: flex;
    /* justify-content: spa; */
    flex-direction: column;
}

.worky-stats-title{
    height: 60px;
    width: 100%;
    background: greenyellow;
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
}

.worky-stats-value{
    height: 155px;
    width: 100%;
    background: orangered;
    display: flex;
    align-items: center;
    justify-content: center;
}

.worky-stats-value-icon{
    height: 100%;
    background: red;
    width: 40%;
}

.worky-stats-value-info{
    height: 100%;
    background: blue;
    width: 60%;
}



.splash-section7-container {
    height: auto;
    width: 100%;
    padding-bottom: 40px;
    /* background: green; */
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section7-title {
    height: 50px;
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 37px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    padding-top: 60px;
    color: var(--worky-colour);
}

.section7-subtitle {
    height: 80px;
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size: 30px; */
    /* font-family: "mendl-sans-dusk", sans-serif; */
    font-family: 'DM Sans', sans-serif;

    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);

}

#section7-button{
    margin-top: 25px;
    margin-bottom: 75px;
}

.section7-info-holder {
    /* background: red; */
    height: 200px;
    width: 85%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* justify-content: center; */
    /* gap: 10px; */
}



.section7-info-container {
    height: 100%;
    width: calc(100% / 3 - 45px);
    /* background: green; */
}

.section7-info-icon {
    height: 75px;
    width: 75px;
    /* background: grey; */
}

.section7-img{
    height: 75px;
    width: 75px;
    margin-left: -25px;
}

.section7-info-title {
    height: 40px;
    width: 100%;
    /* background: yellow; */
    display: flex;
    align-items: center;

    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
}

.section7-info-content {
    height: auto;
    width: 100%;
    /* background: blue; */
    text-align: left;


    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
}

.simple-bold{
    font-weight: 600;
}





.splash-employers{
    /* height: 95vh; */
    height: 750px;
    width: 100%;
    padding-bottom: 50px;
    background: white;
    display: flex;
    justify-content: space-between;
}

.splash-text-holder{
    height: 100%;
    width: 45%;
    display: flex;
    /* align-items: right; */
    flex-direction: column;
    justify-content: center;
    /* background-color: white; */
    z-index: 5;
}

.splash-img-holder{
    height: 100%;
    width: 45%;
    /* width: 37.5%; */
    /* padding-right: 7.5%; */
}



.splash-employers-title{
    text-align: right;
    font-size: 105px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: var(--text-colour);
}


.splash-employers-text{
    height: 125px;
    width: 450px;
    /* background: var(--launch-colour2); */
    font-family: 'DM Sans', sans-serif;
    color: var(--text-colour);
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-align: right;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: calc(100% - 450px);
}

.splash-employers-button{
    margin-left: calc(100% - 180px);
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    height: 40px;
    width: 180px;
    border: none;
    border-radius: 10px;
    background: var(--worky-colour);
    color: white;
    cursor: pointer;
}
.splash-employers-button:hover{
    background: var(--hightlight-colour);

}


.each-employer-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    height: 750px;
  }

 .each-employer-slide img{
    width: 130%;
    /* background: red; */
 } 



 .each-worker-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* padding-left: 15%; */
    height: 750px;
  }

 .each-worker-slide img{
    width: 140%;
    /* width: 125%;
    background-image:
    linear-gradient(to right, white, rgba(0,128,128,0) 150px),
    linear-gradient(to left , white, rgba(0,128,128,0) 150px); */
 } 
 .splash-img-holder-worker{
    height: 100%;
    /* width: 45%; */
    width: 45%;
    /* padding-left: 7.5%; */
}
















.splash-employees-title{
    text-align: left;
    font-size: 105px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: var(--text-colour);
}


.splash-employees-text{
    height: 125px;
    width: 400px;
    /* background: var(--launch-colour2); */
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: calc(100% - 400px);
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-align: left;
    color: var(--text-colour);
}

.splash-employees-button{
    margin-right: calc(100% - 180px);
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    height: 40px;
    width: 180px;
    border: none;
    border-radius: 10px;
    background: var(--worky-colour);
    color: white;
    cursor: pointer;
}
.splash-employees-button:hover{
    background: var(--hightlight-colour);

}















.splash-contact{
    height: 700px;
    padding-top: 75px;
    width: calc(100% - 15%);
    padding-left:7.5% ;
    padding-right:7.5% ;
    /* background:red; */
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-evenly; */

}


.splash-contact-title{
    font-size: 40px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--worky-colour);
}


.splash-contact-subtitle{
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    padding-top: 10px;
    padding-bottom: 15px;
}



.splash-contact-contactinfo-holder{
    height: 45px;
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-bottom: 20px;

}

.splash-contact-contactinfo{
    height: 100%;
    width: 25%;
    padding-top: 5px;
    padding-bottom: 5px;
    background:var(--launch-colour2);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    /* font-size: 20px; */
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
}

.splash-contact-contactinfo:hover{
    background:var(--launch-colour);
}

.splash-contact-contactinfo-info{
    width: 200px;
    height: auto;
    /* background: red; */
}

.splash-contact-container{
    /* height: auto; */
    width: 85vw;
    /* background: var(--launch-colour2); */
    display: flex;
    flex-direction: column;
    background: white;
    z-index: 25;
}


.splash-input-title{
    width: 700px;
    margin: auto;
    /* background: red; */
    text-indent: 20px;
    text-align: left;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: var(--text-colour);
    padding-top: 15px;
    padding-bottom: 10px;
}

.splash-input{
    height: 40px;
    width: 700px;
    margin: auto;
    border-radius: 10px;
    border: none;
    border: 2px solid var(--launch-colour);
    text-indent: 10px;
    
}

#splash-message{
    max-height: 150px;
    max-width: 680px;
    min-height: 90px;
    min-width: 680px;
    text-indent: 10px;
    padding: 10px;
    margin-bottom: 20px;
}

#splash-reason{
    height: 46px;
    width: 710px;
    text-indent: 10px;
    color: var(--text-colour);
}

.splash-button-holder{
    height: 40px;
    width: 700px;
    /* background: red; */
    margin: auto;
    display: flex;
    justify-content: flex-end;
}

.splash-submit{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    height: 40px;
    width: 180px;
    border: none;
    border-radius: 10px;
    background: var(--worky-colour);
    color: white;
    cursor: pointer;
    right: 0px;
}
.splash-submit:hover{
    background: var(--hightlight-colour);
}




.splash-footer{
    height: 200px;
    width: calc(100% - 15%);
    /* background: red; */
    display: flex;
    align-items: center;
    padding-left: 7.5%;
    padding-right: 7.5%;
    padding-bottom: 10px;
    padding-top: 40px;
    background: var(--launch-colour2);
    background: white;
    justify-content: space-between;
}

.splash-footer-title{
    font-size: 70px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: var(--worky-colour);
    cursor: pointer;
}

.splash-footer-socials{
    display: flex;
    gap: 7.5px;
}


.splash-footer-social{
    height: 35px;
    width: 35px;
    background-color: var(--worky-colour);
    border-radius: 10px;
    cursor: pointer;
    background-position: center;
    background-size: 25px;
    background-repeat: no-repeat;
}
#social-facebook{background-image: url('/public/splash/facebook-logo.png');}
#social-instagram{background-image: url('/public/splash/instagram-logo.png');}
#social-twitter{background-image: url('/public/splash/twitter-logo.png');}
#social-linkedin{background-image: url('/public/splash/linkedin-logo.png');}
#social-tiktok{background-image: url('/public/splash/tiktok-logo.png');}

.splash-footer-social:hover{
    background-color: var(--hightlight-colour);
}





.splash-our-partners{
    height: 400px;
    width: 85%;
    padding-left: 7.5%;
    padding-right: 7.5%;    
    background: white;
}

.splash-partners-title{
    height: 50px;
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 30px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    padding-top: 50px;

    color: var(--text-colour);
}

.splash-partners-subtitle{
    padding-bottom: 35px;
    padding-top: 5px;
    font-family: 'DM Sans', sans-serif;

    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
}

.splash-partners-container{
    height: 200px;
    width: 100%;
    /* background: red; */
    display: flex;
    justify-content: space-evenly;
}

.splash-partner{
    width: calc(100% / 4);
    /* background: blue; */
}

.splash-partner img{
    width: 300px;
    /* background: white; */
    opacity: 0.6;
}

.splash-partner img:hover{
    opacity: 1;
}


.ontime-holder{
    height: 325px;
    width: 85%;
    padding-left: 7.5%;
    padding-right: 7.5%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ontime-inner{
    height: 200px;
    width: 100%;
    background: var(--launch-colour2);
    /* background: white; */
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
}

.ontime{
    height: 220px;
    width: calc(100% / 3 - 20px);
    /* background: red; */
    /* background: grey; */
}

.ontime-title{
    height: 90px;
    width: 100%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: var(--text-colour);
}

.ontime-value{
    height: 70px;
    font-family: "mendl-sans-dusk", sans-serif;
    width: 100%;
    /* background-color: blue; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 75px;
    font-weight: 700;
    color: var(--text-colour);
    color: var(--worky-colour);
}


.ontime-value img{
    height: 150%;
}


.mobile-ontime-value img{
    padding-top: 60px;
    width:50%;
}


.each-slidev2{
    height: calc(90% - 70px);
    margin-top: 120px;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
}


.splash-section1-holder-v2{
    height: 100vh;
    width: 90%;
    margin: auto;

}
.indicators{
    margin-top: 30px;
}

.splash-slider-container{
    height: calc(82.5% - 80px);
    margin-top: 80px;
    
    display: flex;
    align-items: center;
    /* padding-top: 5%; */
    /* padding-bottom: 2.5%; */
    justify-content: space-between;
}


.splash-our-partners{
    height: 120px;
    width: 80%;
    margin: auto;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
}

.splash-our-partners-overlay{
    height: 120px;
    width: auto;
    margin: auto;
    /* background: blue; */
    color: #d7d7d7;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-family: 'DM Sans', sans-serif;
    line-height: 30px;
    font-weight: 600;
    text-align: right;
}

.splash-our-partners-top{
    height: 50px;
    width: auto;
    /* background: green; */
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    color:var(--launch-highlight);

}

.splash-our-partners-carousel{
    height: 80px;
    width: 100%;
    margin: auto;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.splash-our-partners-carousel-mobile{
    height: 80px;
    width: 100%;
    margin: auto;
    margin-top: 120px;
    /* background: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.splash-our-partners-carousel-partner{
    height: 100%;
    width: 150px;
    /* background: var(--launch-colour2); */
}

.splash-our-partners-carousel-partner img{
    transform: scale(0.8);
    opacity: 0.8;
}


.worky-mission{
    height:200px;
    width: 90%;
    margin: auto;
    margin-top: 50px;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    font-family: 'DM Sans', sans-serif;
    font-size: 80px;
    font-weight: 600;
    line-height: 92px;
    color: var(--text-colour);
}

.worky-mission-details{
    height: 280px;
    width: 90%;
    /* background-color: red; */
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 50px;

}

.worky-mission-block{
    height: 280px;
    width: 400px;
    background: white;
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: 0.15s ease-in-out;
}
.worky-mission-block:hover{
    height: 280px;
    width: 400px;
    background: var(--launch-colour2);
    border-radius: 35px;
}
.worky-mission-block:hover .worky-mission-block-icon{
    background: linear-gradient(90deg, #5a9bd6, #2c68bd);
    color: white;
}
.worky-mission-block-icon{
    height: 50px;
    width: 50px;
    border-radius: 15px;
    background: var(--launch-colour2);
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--worky-colour);
    transition: 0.15s ease-in-out;

}

.worky-mission-block-title{
    height: 65px;
    width: 100%;
    /* background-color: red; */
    font-family: 'DM Sans', sans-serif;
    font-size: 35px;
    font-weight: 600;
    color: var(--text-colour);
    display: flex;
    align-items: center;
    justify-content: center;

}

.worky-mission-block-text{
    /* height: 65px; */
    width: 80%;
    /* background-color: red; */
    font-family: 'DM Sans', sans-serif;
    font-size: 17px;
    font-weight: 600;
    color: var(--text-colour);
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    color: #7d7d7d;
}

.splash-overlay{
    height: auto;
    width: 90%;
    /* background: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* position: absolute; */
    /* margin-top: -40px; */
}

.splash-overlay-title{
    font-family: 'DM Sans', sans-serif;
    font-size: 80px;
    font-weight: 600;
    line-height: 92px;
    color: var(--text-colour);
}
.splash-overlay-subtitle{
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    font-weight: 500;
    width: 500px;
    color: #7d7d7d;
    padding-top: 20px;
    padding-bottom: 7.5px;
    /* background: red; */
}


.splash-overlay-buttons{
    height: 60px;
    width: auto;
    /* background: red; */
    display: flex;
    
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-top: 20px;
}
/* @keyframes takeOff {
    0% {
      transform: translateY(0);
    }
    
    20%{
        transform: translateY(6px) rotate(-30deg);
    }
    50% {
      transform: translateY(-10px) translateX(5px);
    }
  } */
@keyframes takeOff {
    0% {
      transform: translateY(0);
    }
    20%{
        transform: translateY(6px) rotate(-30deg);
    }
    67% {
      transform: translateY(-40px) translateX(15px);
    }
    68% {
        transform: translateY(40px) translateX(-10px);
      }
  }
.splash-overlay-button:hover .rocket-icon{
    animation: takeOff 0.5s ease forwards;
}
.splash-overlay-button:hover .devices-icon{
    animation: wiggle-expand 0.5s ease forwards;
}
.splash-overlay-button{
    height: 60px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 16px;
    font-weight: 500;
    color: #7d7d7d;
    border: none;
    font-family: 'DM Sans', sans-serif;
    background: none;
    background: var(--launch-colour2);
    border-radius: 20px;
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: 0.15s ease-in-out;
    cursor:pointer;
}

.splash-overlay-button:hover{
    color: white;
    background: linear-gradient(90deg, #5a9bd6, #2c68bd);
}

.splash-behind{
    height: 95%;
    width: 100%;
    margin-bottom: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: blue; */
    /* background: blue; */
}


.splash-behind-main-img{
    height: 400px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 575px;
    background: white;
    position: relative; /* Ensure positioning context */
    z-index: 1; /* Set the base layer */
}

@keyframes GFG { 
    0% { 
        transform: rotate(0deg) translateY(15px) rotate(0deg); 
    } 

    100% { 
        transform: rotate(360deg) translateY(15px) rotate(-360deg); 
    } 
} 
@keyframes GFG2 { 
    0% { 
        transform: rotate(0deg) translateY(-15px) rotate(0deg); 
    } 

    100% { 
        transform: rotate(-360deg) translateY(-15px) rotate(360deg); 
    } 
} 

.splash-behind-third-img-overlay{
    height: 57.5px;
    width: 165px;
    position: absolute;
    border-radius: 15px;
    right: 15px;
    top: 15px;
    z-index: 1;
    /* background: var(--launch-colour2); */
    box-shadow: 0 5px 5px 0 rgba(111, 111, 111, 0.3);
    background-color: rgba(255, 255, 255, .85);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(15px);
    transition: background-color .35s;
    transition: 0.15s ease-in-out;
}

.splash-behind-third-img-overlay:hover{
    transform: scale(1.15);
}
.splash-behind-sec-img-overlay:hover{
    transform: scale(1.15);
}

.third-img-overlay-title{
    height: 21.5px;
    /* width: 100%; */
    /* background: red; */
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: var(--text-colour);
    color: #7d7d7d;
    text-align: center;
    display: flex;
    align-items:flex-end;
    justify-content: left;
    padding-left: 15px;
    gap: 5px;
}

.third-img-overlay-bottom{
    height: 36px;
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
}


.third-img-overlay-bottom-date{
    height: 25px;
    width: auto;
    background: white;
    font-size: 11px;
    font-weight: 600;
    color: var(--text-colour);
    color: #7d7d7d;
    padding-left: 7.5px;
    padding-right: 7.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 7.5px;
}

.third-img-overlay-bottom-time{
    height: 25px;
    width: auto;
    background: white;
    font-size: 11px;
    font-weight: 600;
    color: var(--text-colour);
    color: #7d7d7d;
    padding-left: 7.5px;
    padding-right: 7.5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    border-radius: 7.5px;
}




.splash-behind-third-img{
    height: 170px;
    width: 300px;
    /* background: blue; */
    display: flex;
    cursor: pointer;
    position: absolute; /* Ensure it's positioned relative to parent */
    top: 120px;
    right: 35%;
    animation: GFG 35s linear infinite;
    z-index: 2; /* Higher than main image */
}

.splash-behind-third-img img{
    height: 150px;
    border-radius: 150px;
    border: 5px solid var(--worky-colour);
    opacity: 0.95;
}

.splash-behind-sec-img{
    height: 175px;
    width: 300px;
    /* background: blue; */
    overflow: hidden;
    position: absolute; /* Ensure it's positioned relative to parent */
    top: 410px;
    right: 10%;
    display: flex;
    
    flex-direction: row-reverse;
    animation: GFG2 35s linear infinite;
    z-index: 2; /* Higher than main image */
    
}

.splash-behind-sec-img-overlay{
    height: 80px;
    width: 165px;
    position: absolute;
    border-radius: 15px;
    left: 20px;
    bottom: 15px;
    z-index: 3;
    /* background: var(--launch-colour2); */
    box-shadow: 0 5px 5px 0 rgba(111, 111, 111, 0.3);
    background-color: rgba(255, 255, 255, .85);
    cursor: pointer;
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(15px);
    transition: background-color .35s;
    transition: 0.15s ease-in-out;
}

.jobber-accept-img-holder{
    width: 140px;
    height: 140px;
    overflow: hidden;
    border-radius: 100%;
    border: 5px solid var(--worky-colour);
    /* margin-top: -40px; */
}
.jobber-accept-img-holder img{
    height:190px;
    width:190px;
    margin-top: -10px;
    margin-left: -5px;
}

.splash-behind-main-img img{
    width: 100%;
}

.third-img-overlay-middle{
    height: 25px;
    /* background: red; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.third-img-overlay-middle-tag{
    height: 22.5px;
    margin-top: 5px;
    border-radius: 5px;
    display: flex;
    gap: 7.5px;
    align-items: center;
    justify-content: center;
    width: 145px;
    background: var(--worky-colour);
    background: linear-gradient(90deg, #5a9bd6, #2c68bd);
    color: white;
    font-family: 'DM Sans', sans-serif;
    font-size: 12.5px;
    font-weight: 700;
    /* cursor: pointer; */
    /* transition: 0.15s ease-in-out; */
}

.third-img-overlay-middle-tag:hover{
    /* background: var(--hightlight-colour); */
    /* transform: scale(1.08); */
}













.splash-section1-left{
    height: 500px;
    width: 45%;
    /* background: red; */
}


.splash-section1-right{
    height: 500px;
    max-height: 500px;
    width: 52.5%;
    /* background: blue; */
}
.react-slideshow-container{
    height: 500px;
}

.splash-partner-container{
    height: 20%;
    /* background: greenyellow; */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* justify-content: space-evenly; */
    /* border-top: 2px var(--launch-colour) solid; */
}

.splash-partner-container-inner{
    height: auto;
    width: 90%;
    /* background: red; */
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.splash-partner-title{
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #7d7d7d;
    padding-top: 50px;
    /* background: red; */
}

.splash-bottom-partner{
    height: 75px;
    width: 150px;
    background: var(--launch-colour2);
    opacity: 0.5;
    transition: 0.15s ease-in-out;
}
.splash-bottom-partner:hover{
    height: 75px;
    width: 150px;
    background: var(--launch-colour2);
    opacity: 1;
}

.splash-footer-copyright{
    height: 35px;
    width:100%;
    background: var(--worky-colour);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    cursor:default;
}
.splash-footer-copyright:hover{
    /* background: var(--hightlight-colour); */
}

.worker-employer-inner{
    padding-top: 40px;
}

.worker-employer-box{
    height: 600px;
    width: calc(80% - 40px);
    padding-left: 20px;
    padding-right: 20px;
    
    background: white;
    margin: auto;
    border-radius: 35px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
.worker-employee-box{
    height: 600px;
    width: calc(80% - 40px);
    padding-left: 20px;
    padding-right: 20px;
    
    background: white;
    margin: auto;
    border-radius: 35px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.worker-employer-box-img{
    height:560px;
    width: calc(70% - 40px);
    /* background: red; */
    margin: auto;
    border-radius: 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.15s ease-in-out;

    /* margin-top: 20px; */
}
.worker-employee-box-img{
    height:560px;
    width: calc(70% - 40px);
    /* background: red; */
    margin: auto;
    border-radius: 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    
    /* margin-top: 20px; */
}

.worker-employee-box-img img{
    width: 100%;
    /* max-width: 800px; */
    /* margin-top: 5px; */
    transition: 0.15s ease-in-out;
    transform: scale(0.97);
}

.worker-employer-box-img img{
    width: 100%;
    max-width: 800px;
    margin-top: 35px;
    transition: 0.15s ease-in-out;
    transform: scale(0.85);
    margin-top: 100px;
}

.worker-employer-box-info{
    height:560px;
    width: 30%;
    
    background-color: var(--launch-colour2);
    background: white;
    margin: auto;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    align-items: center;
    /* margin-top: 20px; */
}

.worker-employer-box-info-section{
    display: flex;
    height: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    /* background-color: red; */
}

.worker-employer-box-info-title{
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: var(--text-colour);
    display: flex;
    align-items: center;
    justify-content: left;
    /* width: 100%; */
    gap: 10px;
    padding-left: 20px;
    padding-bottom: 5px;
}

.worker-employer-box-info-text{
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: var(--text-colour);
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    gap: 10px;
    padding-left: 60px; 
}

.worker-employer-container{
    padding-top:40px;
    padding-bottom:40px;
}

.img-carousel{
    height: 400px;
    padding-top: 40px;
    margin: auto;
    width: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    /* background-color: var(--launch-highlight); */
}

.carousel-img-holder{
    height: 400px;
    width: 370px;
    border-radius: 30px;
    background: var(--launch-colour2);
    overflow: hidden;
}


.carousel-inner-img{
    height: 400px;
    /* transform: translateX(-60%); */
}


.splash-contact-container{
    height: 750px;
    width: 100%;
    /* background: red; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
}
.splash-contact-title-v2{
    font-family: 'DM Sans', sans-serif;
    font-size: 80px;
    font-weight: 600;
    line-height: 92px;
    z-index: 50;
}

.splash-contact-subtitle-v2{
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    font-weight: 500;
    /* width: 400px; */
    color: #7d7d7d;
    /* padding-top: 20px; */
    /* padding-bottom: 5px; */
}

.splash-contact-buttons-v2{
    height: 60px;
    width: auto;
    /* background: red; */
    display: flex;
    
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-top: 20px;
}

.splash-contact-button-v2{
    height: 60px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 16px;
    font-weight: 500;
    color: #7d7d7d;
    border: none;
    font-family: 'DM Sans', sans-serif;
    background: none;
    background: var(--launch-colour2);
    border-radius: 20px;
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    transition: 0.15s ease-in-out;
}
.splash-contact-button-v2:hover{
    color: white;
    background: linear-gradient(90deg, #5a9bd6, #2c68bd);
}

.splash-contact-inner{
    height: auto;
    width: 70%;
    margin: auto;
    background: white;
    /* background: red; */
    display: flex;
    gap: 40px;
    padding-top: 75px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.splash-contact-inner-mobile{
    height: auto;
    background: white;
    /* background: red; */
    display: flex;
    gap: 10px;
    padding-top: 25px;
    /* align-items: center; */
    /* justify-content: center; */
    /* flex-direction: column; */
}

.splash-contact-inner-row{
    display: flex;
    
    gap:40px;
}

.standard-basic-input{
    width: 300px;
    /* background: red; */
}
.standard-basic-message{
    width: 640px;
}

.try-now-modal{
    height: auto;
    width: 35vw;
    background: white;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    border-radius: 40px;
    padding-bottom: 20px;
    z-index: 999999999999999999999999999999;
}

.get-started-button-holder{
    height: 50px;
    width: auto;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 145px;
    gap: 20px;
    /* margin-top: 50px; */
}

.get-started-button{
    height: 50px;
    /* width: 120px; */
    padding-left: 30px;
    padding-right: 30px;
    border: none;
    border-radius: 15px;
    background: var(--worky-colour);
    color: white;
    font-family: 'DM Sans', sans-serif;
    font-size: 14.5px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.15s ease-in-out;
}

#get-started-demo{
    background: var(--launch-colour);
    color: var(--text-colour);
}

#get-started-demo:hover{
    background: linear-gradient(90deg, #5a9bd6, #2c68bd);
    color: white;
}

.get-started-button:hover{
    background: var(--hightlight-colour);
}

.try-now-modal-close{
    position: absolute;
    top: 15px;
    right: 15px;
    height: 40px;
    width: 40px;
    /* background:red; */
    background: none;
    cursor: pointer;
    border: none;
    border-radius:50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.15s ease-in-out;
    color: var(--text-colour);
}

.try-now-modal-close:hover{
    color: var(--worky-colour);
    background: var(--launch-colour2);
}

.try-now-modal-title{
    font-family: 'DM Sans', sans-serif;
    font-size: 50px;
    font-weight: 600;
    color: var(--text-colour);
    padding-top: 50px;
}

.try-now-modal-button-holder{
    height: 365px;
    padding-top: 25px;
    width: calc(100% - 40px);
    margin: auto;
    /* background: red; */
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.try-now-modal-button{
    height: 100%;
    width: 100%;
    border: none;
    background: none;
    border-radius: 35px;
    transition: 0.15s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    cursor: pointer;
}

.try-now-modal-button:hover{
    background: var(--launch-colour2);
}

.try-now-modal-button-title{
    font-family: 'DM Sans', sans-serif;
    font-size: 35px;
    font-weight: 600;
    color: var(--text-colour);
    padding-top: 50px;
    transition: 0.15s ease-in-out;
}
.try-now-modal-button-subtitle{
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    color: #7d7d7d;
    margin-top:10px ;
    margin-bottom:40px ;
    width: 70%;
    
}
.try-now-modal-button-icon{
    height: 90px;
    width: 90px;
    border-radius: 20px;
    background: var(--launch-colour2);
    transition: 0.15s ease-in-out;
    color: var(--worky-colour);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}


.try-now-modal-button:hover .try-now-modal-button-title{
    background: linear-gradient(90deg, #5a9bd6, #2c68bd);
    -webkit-background-clip: text;
    color: transparent;
    background-clip: text;
}

.try-now-modal-button:hover .try-now-modal-button-icon{
    color:white;
}

.try-now-modal-button:hover .try-now-modal-button-icon{
    background: linear-gradient(90deg, #5a9bd6, #2c68bd);

}





:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}
:root {
    --fc-small-font-size: .85em;
    --fc-page-bg-color: #fff;
    --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
    --fc-neutral-text-color: #808080;
    --fc-border-color: #ddd;
  
    --fc-button-text-color: #fff;/*buttons colour*/
    --fc-button-bg-color: #2c68bd; /*buttons colour*/
    --fc-button-border-color: #2c68bd;/*buttons colour*/
    --fc-button-hover-bg-color: #1d438a;/*buttons colour*/
    --fc-button-hover-border-color: #1d438a;/*buttons colour*/
    --fc-button-active-bg-color: #1a252f;/*buttons colour*/
    --fc-button-active-border-color: #151e27;/*buttons colour*/
  
    --fc-event-bg-color: #2c68bd; /* event icon coloures*/
    --fc-event-border-color: #3788d8;/* event icon coloures*/
    --fc-event-text-color: #fff;
    --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);
  
    --fc-more-link-bg-color: #d0d0d0;
    --fc-more-link-text-color: inherit;
  
    --fc-event-resizer-thickness: 8px;
    --fc-event-resizer-dot-total-width: 8px;
    --fc-event-resizer-dot-border-width: 1px;
  
    --fc-non-business-color: rgba(215, 215, 215, 0.3);
    --fc-bg-event-color: rgb(143, 223, 130);
    --fc-bg-event-opacity: 0.3;
    --fc-highlight-color: rgba(188, 232, 241, 0.3);
    --fc-today-bg-color: rgba(44,104,189, 0.20);
    --fc-now-indicator-color: red;
  }
.shift-event{
    background: var(--launch-colour2);
    cursor: pointer;
    color:var(--text-colour)
}
.shift-event:hover{
    background: var(--launch-colour);
}

.view-calendar{
    color: var(--text-colour);
    border-radius: 30px;
}

.calender-header{
    color: var(--text-colour);
}

.fc-toolbar-title{
    color: var(--text-colour);
}

.fc-today-button.fc-button.fc-button-primary{
    display: none;
}

.fc-event-time{
    height: 0;
    width: 0;
    display: none;
}

.account-settings-container{
    height: 170px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: red; */
}




.account-settings-title{
    height: 35px;
    width: 100%;
    font-size: 35px;
    text-align: left;
    text-indent: 50px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    padding-bottom: 10px;
}

.account-settings-holder{
    height: 120px;
    margin-top: 5px;
    padding: 20px;
    width: auto;
    position: absolute;
    max-width: calc(80% - 40px);
    background: white;
    border-radius: 35px;
    display: flex;
    gap: 30px;
    /* box-shadow: 0 0 2px rgba(0,0,0,0.1); */
}



.account-icon-holder{
    height: 120px;
    width: 120px;
    /* background: red; */
    display: flex;
    justify-content: center;
    align-items: center;
}


.account-icon{
    height: 110px;
    width: 110px;
    background:var(--launch-colour);
    border-radius: 150px;
}

.account-icon:hover{
    background: var(--launch-highlight);
}

.account-info-holder{
    /* background-color: blue; */
    width: auto;
}


.account-name{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 35px;
    color: var(--text-colour);
    text-align: left;
}


.account-org{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 22px;
    color: var(--text-colour);
    text-align: left;
}












.payments-container{
    min-height: 220px;
    height: auto;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
    /* background: green; */
    display: flex;
    flex-direction:column;
    gap: 10px;
}
.payments-container-mobile{
    min-height: 220px;
    height: auto;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px ;
    margin-top: 70px;
    /* background: green; */
    display: flex;
    flex-direction:column;
    gap: 10px;
}


.payments-title{
    height: 35px;
    width: 100%;
    font-size: 35px;
    text-align: left;
    text-indent: 50px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    /* background: yellow; */
}

.payments-title-mobile{
    height: 35px;
    width: 100%;
    font-size: 35px;
    text-align: left;
    text-indent: 10px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    /* background: yellow; */
}


.payments-holder{
    min-height: 130px;
    margin-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    max-width: calc(100% - 40px);
    background: white;
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
}


.payments-made{
    height: auto;
    min-height: 60px;
    padding-top: 4px;
    width: 100%;
    /* background:red; */
    display: flex;
    flex-direction: column;
    align-items: center;
}


.payments-due{
    height: auto;
    min-height: 60px;
    padding-top: 4px;
    width: 100%;
    /* background:red; */
    border-bottom: 2px solid var(--launch-colour);
    display: flex;
    flex-direction: column;
    align-items: center;
}


.payments-static{
    height: 64px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: red; */
}

.payments-inner-title{
    /* background: red; */
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: var(--text-colour);
}

.payments-inner-right{
    display: flex;
    flex-direction: row;
    /* background: red; */
    gap: 20px;
    align-items: center;
    padding-right: 25px;
}

.payments-chevron{
    height: 30px;
    width: 30px;
    background: var(--launch-colour);
    border-radius: 30px;
    color: var(--text-colour);
    border: none;
    font-weight: 700;
    cursor: pointer;
}


.payments-inner-value{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 19px;
    color: var(--worky-colour);
}

.payments-made-dropdown{
    height: auto;
    width: 100%;
    padding-bottom: 25px;
}

.payments-due-dropdown{
    height: auto;
    width: 100%;
    padding-bottom: 25px;
}




















.shifthistory-container{
    height: auto;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 45px;
    /* background: red; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 45px;
}

.shifthistory-container-mobile{
    height: auto;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 45px;
    /* background: red; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 45px;
}

.shifthistory-title{
    height: 35px;
    width: 100%;
    font-size: 35px;
    text-align: left;
    text-indent: 50px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
}
.shifthistory-title-mobile{
    height: 35px;
    width: 100%;
    font-size: 35px;
    text-align: left;
    text-indent: 10px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
}


.shifthistory-holder{
    min-height: 120px;
    margin-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    max-width: calc(100% - 40px);
    background: white;
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
}




.shifthistory-inprogress{
    height: auto;
    min-height: 60px;
    width: 100%;
    /* background:red; */
    border-bottom: 2px solid var(--launch-colour);
}

.shifthistory-inprogress-dropdown{
    height: auto;
    width: 100%;
    padding-bottom: 25px;
}


.shifthistory-assigned{
    height: auto;
    min-height: 60px;
    width: 100%;
    /* background:blue; */
    border-bottom: 2px solid var(--launch-colour);

}
.shifthistory-assigned-dropdown{
    height: auto;
    width: 100%;
    padding-bottom: 25px;
}

.shifthistory-completed-dropdown{
    height: auto;
    width: 100%;
    padding-bottom: 25px;
}


.shifthistory-completed{
    height: auto;
    min-height: 60px;
    width: 100%;
    /* background:green; */
}
.shifthistory-assigned-completed{
    height: auto;
    width: 100%;
    padding-bottom: 25px;
}


.shiftCancel{
    height: 22px;
    width: 60px;
    background: var(--launch-colour);
    border: none;
    border-radius: 10px;
    color: var(--text-colour);
    font-weight: 600;
    cursor: pointer;
}

.shiftCancel:hover{
    background: var(--launch-highlight);
    color: white;
}

.shiftCancel:active{
    background: var(--worky-colour);
    color: white;
}

.shift-cancel-modal{
    height: auto;
    width: 380px;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 30px;
    padding: 15px;
}

.modal-button-holder{
    height: 35px;
    width: 180px;
    margin: auto;
    /* background: red; */
    display: flex;
    flex-direction: row;
    justify-content:space-between ;
    padding-top: 10px;
    padding-bottom: 10px;

}

.modal-button{
    height: 35px;
    width: 80px;
    border-radius: 10px;
    border: none;
    background: var(--launch-colour2);
    color: var(--text-colour);
    font-weight: 600;    font-family: "mendl-sans-dusk", sans-serif;

}

.modal-button:hover{
    background: var(--launch-colour);
    color: var(--text-colour);
}
.modal-button:active{
    background: var(--launch-highlight);
    color: white;
}

#modal-title{
    font-family: "mendl-sans-dusk", sans-serif;
    color: var(--text-colour);
    font-weight: 600;
}

#modal-description{
    font-family: "mendl-sans-dusk", sans-serif;
    color: var(--text-colour);
    font-weight: 500;

}







.cancel-shift-modal{
    height: auto;
    max-width: 360px;
    width: 80%;
    padding: 20px;
    position: absolute;
    background: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.cancel-shift-button-holder{
    height: 40px;
    width: 195px;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    gap: 15px;
}

.cancel-shift-button{
    height: 40px;
    width: 90px;
    border-radius: 10px;
    border: none;
    background: var(--launch-colour2);
    color: var(--text-colour);
    font-weight: 500;    font-family: "mendl-sans-dusk", sans-serif;
    cursor: pointer;
}

.cancel-shift-button:hover{
    background: var(--launch-colour) ;
    font-weight: 600; 
}

#cancel-shift-button-no{
    background: var(--worky-colour);
    color: white;
}



.no-shifts-container{
    height: auto;
    max-width: 550px;
    width: 60%;
    padding: 50px;
    background: white;
    border-radius: 30px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.no-shifts-title{
    font-size: 28px;
    color: var(--text-colour);
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    padding-bottom: 10px;
}
.no-shifts-subtitle{
    font-size: 18px;
    color: var(--text-colour);
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-style: normal;
    padding-bottom: 30px;
}
.no-shifts-button{
    height: 45px;
    width: 125px;
    border-radius: 10px;
    font-size: 15px;
    border: none;
    background: var(--worky-colour);
    color: white;
    font-weight: 500;    
    font-family: "mendl-sans-dawn", sans-serif;
    cursor: pointer;
}

.no-shifts-button:hover{
    background: var(--hightlight-colour);

}


.toggle-carousel{
    height: 40px;
    width: 150px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    top: 100px;
    left: 420px;
    border-radius: 10px;
}

.toggle-calendar{
    height: 40px;
    width: 80px;
    /* background: white; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    top: 100px;
    right: 10%;
    border-radius: 10px;
    /* box-shadow: 0 0 2px rgba(0,0,0,0.1); */
    /* z-index: 1; */
}

.toggle-calendar-mobile{
    height: 40px;
    width: 70px;
    /* background: white; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    top: 100px;
    right: 5%;
    border-radius: 10px;
    /* box-shadow: 0 0 2px rgba(0,0,0,0.1); */
    /* z-index: 1; */
}



.calendar-toggle{
    background: none;
    border: none;
    background: var(--worky-colour);
    border-radius: 5px;
    padding: 5px;
    padding-left: 7px;
    padding-right: 7px;
    color: white;
    transition: 0.13s ease-in-out;
    width: auto;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 600;
    font-style: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

#toggleClosed:hover{
    background: var(--launch-colour);
}

#toggleClosed{
    background: none;
    color: var(--text-colour);
    width: auto;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.calendar-title{
    height: 35px;
    /* width: auto; */
    padding-left: 12%;
    font-size: 35px;
    text-align: left;
    text-indent: 25px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    /* padding-bottom: 10px; */
    margin-top: 70px;
}

.calendar-title-mobile{
    height: 35px;
    /* width: auto; */
    padding-top: 30px;
    padding-left: 2.5%;
    font-size: 35px;
    text-align: left;
    text-indent: 25px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    /* padding-bottom: 10px; */
    margin-top: 70px;
}


.calendar-container{
    height: 500px;
    width: calc(80% - 80px);
    padding: 40px;
    padding-bottom: 70px;
    margin: auto;
    background: white;
    margin-top: 20px;
    border-radius: 30px;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
    overflow: hidden;
}

.calendar-container-mobile{
    height: 500px;
    width: calc(90% - 20px);
    /* padding: 40px; */
    padding: 15px;
    padding-top: 25px;

    padding-bottom: 60px;
    margin: auto;
    background: white;
    margin-top: 20px;
    border-radius: 15px;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
    overflow: hidden;
}

.fc .fc-toolbar.fc-header-toolbar{
    margin-bottom: 15px;
}


.calendar-shifts-modal{
    height: auto;
    width: 650px;
    background: white;
    /* background: var(--launch-colour2); */
    padding-bottom: 30px;
    /* padding-left: 30px; */
    /* padding-right: 30px; */
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    /* background-color: red; */
}

.calendar-shift-modal-carousel{
    /* margin-top: 30px; */
    height: 190px;
   
    /* max-width: 600px; */
    padding-bottom: 15px;
    display: flex;
    justify-content: center;
    /* background: red; */
}

.srstatus-holder2{
    height: 190px;
    padding-top: 20px;
    padding-bottom: 20px;
    min-width: 400px;
    position: absolute;
    max-width: calc(80% - 40px);
    /* background: white; */
    border-radius: 35px;
    display: flex;
    overflow-y: scroll;
    flex-wrap:nowrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    /* background: red; */
    scroll-behavior: smooth;
}
:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}

.loading-screen{
    height: 100%;
    width: 100%;
    background: var(--worky-colour);
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 10000000000000000;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out;
    align-items: center;
    justify-content:center;
}

.worky-loading-icon{
    height: 70px;
    width: 70px;
    background: var(--worky-colour);
    color: var(--launch-colour2);
    border: none;
    border-radius: 35px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 22px;
    animation: pulse 4s linear infinite;
}

@keyframes pulse {
    0% { background: var(--worky-colour); }
    50% { background: var(--hightlight-colour); }
    100% { background: var(--worky-colour); }
}

.loading-text{
    padding: 15px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 200;
    font-style: normal;
    font-size: 15px;
    color: white;
}
:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}
@keyframes wiggle-expand {
    0% {
        transform: rotate(0deg) scale(1);
    }
    25% {
        transform: rotate(-3deg) scale(1.3);
    }
    50% {
        transform: rotate(3deg) scale(1.3);
    }
    75% {
        transform: rotate(-3deg) scale(1.3);
    }

}

.careers-header{
    padding-top: 200px;
    /* background: red;     */

}

.careers-header-title{

    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 60px;
    color: var(--worky-colour);
}

.careers-header-subtitle{
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: var(--text-colour);
    padding-top: 12.5px;
}

.careers-openings{
    font-weight: 500;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    color: white;
    background: var(--worky-colour);
    border: none;
    border-radius: 15px;
    padding: 15px;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    margin-top: 30px;
    transition: ease-in-out 0.15s;
    /* margin-bottom: 50px; */
}

.careers-openings:hover{
    background: var(--hightlight-colour);

}



.at-worky-container{
    height: 600px;
    width: 85%;
    /* background-color: red;    */
    background-color: white;
    padding-left: 7.5%;
    padding-right: 7.5%;
    padding-top: 150px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.at-worky-container-mobile{
    height: 1600px;
    width: 85%;
    /* background-color: red;    */
    background-color: white;
    padding-left: 7.5%;
    padding-right: 7.5%;
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.at-worky-img{
    height: 100%;
    width: 100%;
    /* background-color: var(--launch-colour2); */
    border-radius: 30px;
    overflow:hidden;    transition: 0.15s ease-in-out;

}
.at-worky-img-mobile{
    height: 300px;
    width: 100%;
    /* background-color: var(--launch-colour2); */
    border-radius: 30px;
    overflow:hidden;    transition: 0.15s ease-in-out;

}

.at-worky-img-mobile img{
    /* height: 100%; */
    width: 130%;
}

.at-worky-img:hover{
    /* transform: rotate(-2deg); */
    /* transform: scale(1.025); */
    /* animation: wiggle-expand 0.15s; */
    box-shadow: 0 0 2px rgba(0,0,0,0.3);

}

.at-worky-img img{
    /* height: 100%; */
    width: 130%;
}

.at-worky-info-holder{
    height: 100%;
    width: 70%;
    /* background: blue;     */
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    flex-direction: column;
    gap: 20px;
}


.at-worky-info-holder-mobile{
    height: 100%;
    width: 100%;
    /* background: blue;     */
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    flex-direction: column;
    gap: 20px;
}

.at-worky-left{
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 40%;
}

.at-worky-title-holder{
    width: 100%;
    height: 300px;
    /* padding: 30px; */
    /* height: 200px; */
    /* background: red;  */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 10px;
    /* padding-bottom: 10px; */
}
.at-worky-title{
    font-size: 38px;
    /* font-family: "mendl-sans-dawn", sans-serif; */
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--worky-colour);
    /* color: var(--text-colour); */
    transition: 0.15s ease-in-out;
    cursor: pointer;
    /* background:red; */
}
.at-worky-title:hover{
    font-size: 38px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--hightlight-colour);
    /* color: var(--text-colour); */
    /* background:red; */
}


.at-worky-info{
    width: calc(50% - 70px);
    height: calc(50% - 70px);
    background-color: var(--launch-colour2);
    border-radius: 30px;
    padding: 30px;
    /* height: 150px; */
    display:flex;
    flex-direction: column;
    transition: 0.15s ease-in-out;
}
.at-worky-info-mobile{
    width: calc(100% - 60px);
    height: 220px;
    background-color: var(--launch-colour2);
    border-radius: 30px;
    padding: 30px;
    /* height: 150px; */
    display:flex;
    flex-direction: column;
    transition: 0.15s ease-in-out;
}


.at-worky-info:hover{
    background-color: var(--launch-colour2);
}

.atworky-top{
    height: 45px;
    width: 100%;
    /* background-color: red; */
    display: flex;
}

.atworky-icon{
    height: 45px;
    width: 45px;
    background-color: var(--worky-colour);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    transition: transform .15s ease-in-out;
}

.atworky-icon:hover{
    animation: wiggle-expand 0.8s;

    background-color: var(--hightlight-colour);
}

.atworky-title{
    height: 45px;
    width: auto;
    /* background-color: green; */
    display: flex;
    align-items: center;
    /* justify-content: center; */
    font-size: 20px;
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-weight: 600;
    padding-left: 15px;
    padding-top: 15px;
    color: var(--text-colour);
}

.atworky-info{
    height: calc(100% - 45px);
    width: calc(100% - 65px);
    padding-left: 15px;
    padding-top: 7.5px;
    text-align: left;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
}

.at-worky-subtitle{
    padding-top: 5px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    /* background-color: red; */
    padding-left: 30px;
    padding-right: 30px;
    color: var(--text-colour);
}

.careers-launch{
    height: 350px;
    width: 85%;
    margin: auto;
    margin-top: 75px;
    margin-bottom: 10px;
    /* background-color: var(--launch-colour2); */
    border-radius: 45px;
    display: flex;
    flex-direction: row;
    gap:20px;
}

.careers-img-holder:hover{
    /* transform: rotate(-2deg); */
    transform: scale(1.025);
    box-shadow: 0 0 2px rgba(0,0,0,0.3);

}

.careers-img-holder{
    height: 100%;
    width: 25%;
    background: var(--launch-colour2);
    border-radius: 30px;
    overflow: hidden;
    transition: 0.15s ease-in-out;
}
.careers-img-holder img{
    /* height: 120%;
    width: 130%; */
    /* size: contain; */
}


.current-openings-holder{
    height: auto;
    width: 85%;
    padding-left: 7.5%;
    padding-right: 7.5%;
    background-color: white;
    padding-top: 150px;
}

.current-openings-holder-mobile{
    height: auto;
    width: 95%;
    padding-left: 2.5%;
    padding-right: 2.5%;
    background-color: white;
    padding-top: 275px;
}

.current-openings-title{
    font-family: 'DM Sans', sans-serif;
    /* font-family: "mendl-sans-dawn", sans-serif; */

    font-size: 48px;
    font-weight: 700;
    transition: 0.15s ease-in-out;
    color: var(--text-colour);
}

.current-openings-subtitle{
    font-family: 'DM Sans', sans-serif;
    /* font-family: "mendl-sans-dawn", sans-serif; */
    width: 50%;
    margin: auto;
    padding-top: 15px;
    /* font-size: px; */
    font-weight: 400;
    color:#7f7f7f;
}

.current-openings-subtitle-mobile{
    font-family: 'DM Sans', sans-serif;
    /* font-family: "mendl-sans-dawn", sans-serif; */
    width: 80%;
    margin: auto;
    padding-top: 15px;
    /* font-size: px; */
    /* font-weight: 600; */
    color:var(--text-colour);
}

.opening-holder{
    height: auto;
    padding-top: 75px;
    /* padding-bottom: 75px; */
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 60px;
    /* background-color: red; */
}
.opening-holder-mobile{
    height: auto;
    padding-top: 75px;
    /* padding-bottom: 75px; */
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 60px;
    /* background-color: red; */
}

.opening{
    margin: auto;
    height: 145px;
    width: calc(100% - 60px);
    /* background: var(--launch-colour2); */
    border-radius: 30px;
    padding: 30px;
    /* box-shadow: 0 0 2px rgba(0,0,0,0.1); */

}
.opening-mobile{
    width: 100%;
    padding: 0;
}


.opening:hover{
        /* box-shadow: 0 0 2px rgba(0,0,0,0.1); */
    /* background: var(--launch-colour2); */
}

.opening-top{
    height: 60%;
    border-bottom: 1px solid var(--launch-colour);
    display: flex;
    flex-direction: row;
}

.opening-top-mobile{
    height: auto;
    border-bottom: 1px solid var(--launch-colour);
    display: flex;
    flex-direction: column;
}

.opening-topleft{
    width: 80%;
    height: 100%;
    font-family: 'DM Sans', sans-serif;
    font-size: 25px;
    font-weight: 600;
    color: var(--text-colour);
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: baseline; */
    /* margin-top: -15px; */
    padding-left: 30px;

    /* background-color: red; */
}

.opening-topleft-mobile{
    width: 100%;
    height: 100%;
    font-family: 'DM Sans', sans-serif;
    font-size: 25px;
    font-weight: 600;
    color: var(--text-colour);
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: baseline; */
    /* margin-top: -15px; */
    /* padding-left: 30px; */

    /* background-color: red; */
}

.opening-topleft-chips{
    /* background-color: blue; */
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--text-colour);
    /* justify-content: center; */
    gap: 15px;
}

.opening-chip{
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    font-weight: 600;
    border: 2px solid var(--text-colour);
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 25px;
    cursor: pointer;
}
.opening-chip:hover{
    background-color: var(--worky-colour);
    border: 2px solid var(--worky-colour);
    color: white;
}

.opening-topleft-title{
    /* background-color: blue; */
    display: flex;
    color: var(--text-colour);
}

.opening-topright{
    width: 20%;
    height: 100%;
    /* background-color: blue; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.opening-topright-mobile{
    width: 100%;
    height: 100%;
    /* background-color: blue; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.apply-now{
    margin-top: -30px;
    height: 45px;
    width: 125px;
    border: 2px solid var(--worky-colour);
    /* background-color: var(--worky-colour); */
    background: none;
    border-radius: 15px;
    cursor: pointer;
    color: white;
    color: var(--worky-colour);
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
}
.apply-now-mobile{
    /* margin-top: -15px; */
    margin-bottom: 15px;
    height: 45px;
    width: 100%;
    border: 2px solid var(--worky-colour);
    /* background-color: var(--worky-colour); */
    background: none;
    border-radius: 15px;
    cursor: pointer;
    color: white;
    color: var(--worky-colour);
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
}

.apply-now:hover{
    background-color: var(--worky-colour);
    border: 2px solid var(--worky-colour);
    color: white;
}

.opening-bottom{
    padding-left: 30px;
    text-align: left;
    padding-top: 15px;
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #7f7f7f;
}

.opening-bottom-mobile{
    /* padding-left: 30px; */
    text-align: left;
    padding-top: 15px;
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
}
:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}
.test-div{
    height: 100px;
    width: 100px;
    background: red;
    position: absolute;

}

.ji-total-container{
    height: 100vh;
    width: 100vw;
    position: absolute;
    background: white;
}

.jhp-greeting{
    height: 100%;
    width: 45%;
    float: left;

}

.jhp-worky-logo{
    height: auto;
    width: 100%;
    font-size: 65px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: var(--worky-colour);
    /* padding-top: 125px; */
    padding-bottom: 15px;
}

.jhp-worky-logo2{
    height: auto;
    width: 100%;
    font-size: 65px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: var(--worky-colour);
    padding-top: 50px;
    padding-bottom: 15px;
}

.jhp-title{
    height: 25px;
    width: calc(100% - 30px);
    font-size: 20px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 200;
    font-style: normal;
    padding-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;
}


.jhp-animation-holder{
    height: 350px;
    width: 350px;
    background-image: url('/public/coin-walk.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: auto;
    margin-bottom: 10px;

}





/* CASE 1 START */


.jhp-case1{
    height: 100%;
    width: 55%;
    float: right;
    display: flex;
    /* background-color: grey; */
}

.jhp-case1-right{
    height: 100%;
    width: 100%;
    float: left;
    display: flex;
    justify-items: center;
    align-items: center;

}


.jhp-profile-holder{
    height: 75px;
    width: 325px;
    margin: 0 auto;
    padding-left: 25px;
    display: flex;
    padding-bottom: 50px;
    padding-top: 50px;
}

.jhp-pp{
    height: 75px;
    width: 75px;
    background: white;
    /* background: red; */
    border-radius: 75px;


}

.hello{
    font-size: 20px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 300;
}

.jhp-name{
    /* height: 75px; */
    line-height: 1.2;
    text-align: left;
    padding-left: 25px;
    padding-top: 5px;
    /* background: blue; */
    float: left;
    font-size: 25px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
}


/* CASE 1 FINISH */

.ji-submit-success-screen2{    
    height: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ji-submit-success-title2{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: var(--text-colour);
    font-size: 50px;
    padding-top: 50px;
}

.ji-submit-success-subtext2{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 200;
    font-style: normal;
    color: var(--text-colour);
    /* background: red; */
    padding-left: 50px;
    padding-right: 50px;
    font-size: 17.5px;
}

.grid1{
    margin-top: 5vh;
    margin-bottom: 2.5vh;

    margin-left: 3vw;
    background: var(--launch-colour2);
    border-radius: 35px;
    padding-bottom: 15px;
}








.jobber-upload-screen{
    height: 650px;
    max-width: 800px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.jobber-upload-title{
    height: auto;
    width: calc(100% - 15px);
    /* background: blue; */
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 30px;
    color: var(--text-colour);
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.jobber-upload-subtext{
    height: auto;
    width: calc(100% - 35px);
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 100;
    font-style: normal;
    font-size: 16px;
    color: var(--text-colour);
    padding-left: 35px;    
    padding-bottom: 15px;

}


#jobber-upload-portal{
    height: 500px;
    background: white;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    border-radius: 35px;
    border: 7px var(--launch-colour) solid;
    cursor: hand;
}

#file{
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}



#label{
    height: 400px;
    background: white;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 50px;
    border-radius: 35px;
    border: 10px var(--launch-colour) solid;
    background-image: url('/public/upload-icon.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 160px;
    display: inline-block;
    opacity: 1;
    color: var(--launch-highlight);
}



.jobber-upload-next{
    height: 45px;
    width: 100px;
    color: #fff;
    text-align: center;
    font-family: "mendl-sans-dusk", sans-serif;
    border-radius: 15px;
    border: none;
    font-size: 15px;
    background: var(--primary-color);
    float:right;
    margin-right: 35px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: 0.02s ease-in-out;
}
.jobber-upload-next:hover{
    background: var(--hightlight-colour);
}

.jobber-upload-next:active{
    background: var(--hightlight-colour);
}

.other-company {
    height: 25px;
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 15px;
    background:white;
    width: auto;
    border-radius: 28px;
    margin: 5px; 
    border: 1px solid #bdc5cf;
    font-size: 15px;
    cursor:pointer;
    color:#1e2329;
}
.other-company:hover{
    background: #f1f4f8;
}

.company-container {
    display: flex;
    padding-top: 5px;
    flex-wrap: wrap;
    justify-content: center;
}

.submit-button:hover{
    background: var(--hightlight-colour);
    border: var(--hightlight-colour);
}



.modal-dd-info{
    /* background: red; */
    padding-bottom: 30px;
    padding-top: 30px;

}

.cancel-button-holder{
    display: flex;
    flex-direction: row;
    width: 80%;
    gap: 20px;
    margin: auto;
}

.cancelReason{
    height: 80px;
    margin-bottom: 20px;
    width: 80%;
    border: 2px solid var(--launch-colour);
    border-radius: 15px;
}
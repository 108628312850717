:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --text-colour:#4c4c4c;
    --hightlight-colour:#1d438a;
}
/* body{
    background:white;
} */
/* fonts */
/* 
Mendl Sans Dawn Regular
font-family: "mendl-sans-dawn", sans-serif;
font-weight: 400;
font-style: normal;

Mendl Sans Dawn Medium
font-family: "mendl-sans-dawn", sans-serif;
font-weight: 500;
font-style: normal;

Mendl Sans Dawn SemiBold
font-family: "mendl-sans-dawn", sans-serif;
font-weight: 600;
font-style: normal;

Mendl Sans Dawn Bold
font-family: "mendl-sans-dawn", sans-serif;
font-weight: 700;
font-style: normal;

Mendl Sans Dawn XBold
font-family: "mendl-sans-dawn", sans-serif;
font-weight: 800;
font-style: normal;

Mendl Sans Dusk Regular
font-family: "mendl-sans-dusk", sans-serif;
font-weight: 400;
font-style: normal;

Mendl Sans Dusk Medium
font-family: "mendl-sans-dusk", sans-serif;
font-weight: 500;
font-style: normal;

Mendl Sans Dusk SemiBold
font-family: "mendl-sans-dusk", sans-serif;
font-weight: 600;
font-style: normal;

Mendl Sans Dusk Bold
font-family: "mendl-sans-dusk", sans-serif;
font-weight: 700;
font-style: normal;

Mendl Sans Dusk XBold
font-family: "mendl-sans-dusk", sans-serif;
font-weight: 800;
font-style: normal; */






html{
    margin: 0;
    padding: 0;
}

body{
    margin:0;
    background: white;
    padding: 0;
}

#overlay{
    height: 100%;
    width: 100%;
    background: black;
    opacity: 40%;
    z-index: 1000;
    position: fixed;
    transition: all ease-in-out 0.4s;
}

/* EMPLOYERS POPUP */
#employers-popup{
    height: auto;
    z-index: 1001;
    width: 500px;
    background: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 35px;
    box-shadow: 0 0 50px rgba(0,0,0,0.3);
    transition: all ease-in-out 0.4s;
}

.popup-blurb{
    height: 30px;
    width: 100%;
    color: var(--text-colour);
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 100;
    font-style: normal;
    font-size: 14px;
    text-align: left;
    text-indent: 40px;
}

.popup-top{
    margin-top: 40px;
    height: 40px;
    margin-bottom: 40px;
}
.close-button{
    height: 40px;
    width: 40px;
    background: var(--launch-colour);
    float: right;
    border-radius: 40px;
    border: none;
    margin-right: 20px;
    margin-top: -20px;
    color: grey;
    transition: all ease-in-out 0.2s;

}

.close-button:hover{
    background-color: lightgrey;
}

.popup-title{
    color: var(--worky-colour);
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 35px;
    text-align: left;
    text-indent: 30px;
}


.popup-content{
    width: 85%;
    margin: 0 auto;
}

.popup-fullname{
    height: 40px;
    width:98%;
    margin-bottom: 20px;
    text-indent: 20px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    border: 1.5px solid lightgrey;
    border-radius: 35px;
}
.popup-email{
    height: 40px;
    width: 98%;
    margin-bottom: 20px;
    text-indent: 20px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    border: 1.5px solid lightgrey;
    border-radius: 35px;
}
.popup-company{
    height: 40px;
    width: 98%;
    margin-bottom: 20px;
    text-indent: 20px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    border: 1.5px solid lightgrey;
    border-radius: 35px;

}
.popup-message{
    height: 40px;
    width: 98%;
    margin-bottom: 20px;
    max-width: 98%;
    min-width: 98%;
    text-indent: 20px;
    height: 100px;
    min-height: 100px;
    max-height: 200px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    border: 1.5px solid lightgrey;
    border-radius: 25px;
    padding-top: 10px;

}
.popup-submit{
    height: 40px;
    width: 162.5px;
    border-radius: 20px;
    float: right;
    background: var(--worky-colour);
    color: var(--launch-colour);
    border: 2px var(--worky-colour) solid;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 100;
    font-style: normal;
    transition: all ease-in-out 0.12s;
    margin-bottom: 20px;
}



/* EMPLOYEE POPUP */

#employee-popup{
    height: auto;
    z-index: 1001;
    width: 500px;
    background: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 35px;
    box-shadow: 0 0 50px rgba(0,0,0,0.3);
    transition: all ease-in-out 0.4s;
}

.popup-top{
    margin-top: 40px;
    height: 40px;
    margin-bottom: 40px;
}
.close-button{
    height: 40px;
    width: 40px;
    background: var(--launch-colour);
    float: right;
    border-radius: 40px;
    border: none;
    margin-right: 20px;
    margin-top: -20px;
    color: grey;
    transition: all ease-in-out 0.2s;

}

.close-button:hover{
    background-color: lightgrey;
}

.popup-title{
    color: var(--worky-colour);
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 35px;
    text-align: left;
    text-indent: 30px;
}


.popup-content{
    width: 85%;
    margin: 0 auto;
}

.popup-firstname{
    height: 40px;
    width:98%;
    margin-bottom: 20px;
    text-indent: 20px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    border: 1.5px solid lightgrey;
    border-radius: 35px;
}
.popup-lastname{
    height: 40px;
    width:98%;
    margin-bottom: 20px;
    text-indent: 20px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    border: 1.5px solid lightgrey;
    border-radius: 35px;
}
.popup-email{
    height: 40px;
    width: 98%;
    margin-bottom: 20px;
    text-indent: 20px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    border: 1.5px solid lightgrey;
    border-radius: 35px;
}
.popup-phone{
    height: 40px;
    width: 98%;
    margin-bottom: 20px;
    text-indent: 20px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    border: 1.5px solid lightgrey;
    border-radius: 35px;
}
.popup-address{
    height: 40px;
    width: 98%;
    margin-bottom: 30px;
    text-indent: 20px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    border: 1.5px solid lightgrey;
    border-radius: 35px;
}

.popup-submit{
    height: 40px;
    width: 162.5px;
    border-radius: 20px;
    float: right;
    background: var(--worky-colour);
    color: var(--launch-colour);
    border: 2px var(--worky-colour) solid;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 100;
    font-style: normal;
    transition: all ease-in-out 0.12s;
    margin-bottom: 20px;
}






/* NAVBAR */
.navbar{
    height: 70px;
    width:70%;
    margin-left:15% ;
    margin-right: 15%;
    background:var(--launch-colour);
    margin-top: 30px;
    position: fixed;
    border-radius: 35px;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    transition: all ease-in-out 0.15s;
    z-index: 8;
}
.navbar-hidden {
    /* transform: translateY(-200%); */
    transform: translateY(-50%);
    opacity: 0;
  }
  
  .navbar-visible {
    transform: translateY(0%);
  }

#logo{
    height: 52.5px;
    width: 52.5px;
    background: var(--worky-colour);
    float: left;
    color: var(--launch-colour);
    margin-left: 8.75px;
    margin-top: 8.75px;
    border-radius: 100%;
    border: none;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 22px;
    transition: all ease-in-out 0.12s;
}

#logo:hover{
    background: var(--hightlight-colour);
}

#menu{
    height: auto;
    width: auto;
    float: right;
    padding-right: 30px;
    margin-top: 17px;

}

.option1{
    float:left;
    background: none;
    height: 25px;
    width: auto;
    border-radius: 35px;
    margin-left: 15px;
    font-size: 15px;
    margin-top: 6px;
    margin-right: 15px;
    color: var(--text-colour);
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 100;
    font-style: normal;
    border: none;
    transition: all ease-in-out 0.12s;
    border: 1.5px solid var(--launch-colour);
}

.option1:hover{
    /* border: 1.5px solid grey; */
    /* text-decoration: underline; */
    color: var(--worky-colour);

}

.option2{
    float:left;
    background: none;
    height: 25px;
    width: auto;
    font-size: 15px;
    border-radius: 35px;
    margin-left: 15px;
    margin-top: 6px;
    margin-right: 15px;
    color: var(--text-colour);
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 100;
    font-style: normal;
    border: none;
    transition: all ease-in-out 0.12s;
    border: 1.5px solid var(--launch-colour);
}

.option2:hover{
    color: var(--worky-colour);

}

.option3{
    float:left;
    background: none;
    height: 25px;
    width: auto;
    font-size: 15px;
    border-radius: 35px;
    margin-left: 15px;
    margin-top: 6px;
    margin-right: 15px;
    color: var(--text-colour);
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 100;
    font-style: normal;
    border: none;
    transition: all ease-in-out 0.12s;
    border: 1.5px solid var(--launch-colour);

}

.option3:hover{
    color: var(--worky-colour);
}


.option4{
    float:left;
    background: none;
    height: 25px;
    width: auto;
    border-radius: 35px;
    margin-left: 15px;
    margin-top: 6px;
    font-size: 15px;
    margin-right: 15px;
    color: var(--text-colour);
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 100;
    font-style: normal;
    border: none;
    transition: all ease-in-out 0.12s;
    border: 1.5px solid var(--text-colour);

}

.option4:hover{
    border: 1.5px solid var(--worky-colour);
    color: white;
    background: var(--worky-colour);
}



/* section 1 start - launch screen*/


#section1{
    overflow: hidden;
    transition: 3s ease-in-out;
    background-repeat: no-repeat;
    background-size:cover;
    height: 100vh;
    transition-delay: 0.5s;

}


#launch-text{
    padding-top: 260px;
    height: 280px;
    width: 80%;
    margin: 0 auto;
    color: var(--launch-colour);
    text-align: center;
    font-size: 100px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 600;
    font-style: normal;
    text-shadow: 0 0 15px var(--text-colour);

}

#launch-subtext{
    height: 35px;
    width: 100%;
    color: var(--launch-colour);
    text-align: center;
    font-size: 20px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 300;
    font-style: normal;
}

#launch-button-holder{
    margin-top: 55px;
    height: 40px;
    width: 350px;
    margin-left: auto;
    margin-right: auto;

}

#launch-button-left{
    height: 40px;
    width: 162.5px;
    border-radius: 20px;
    float: left;
    background: var(--worky-colour);
    border: 2px var(--worky-colour) solid;
    color: white;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 100;
    font-style: normal;
    transition: all ease-in-out 0.12s;
}

#launch-button-left:hover{
    background: var(--hightlight-colour);
    border: 2px var(--hightlight-colour) solid;
}
#launch-button-right:hover{
    background: var(--hightlight-colour);
    border: 2px var(--hightlight-colour) solid;
}


#launch-button-right{
    height: 40px;
    width: 162.5px;
    border-radius: 20px;
    transition: all ease-in-out 0.12s;
    float: right;
    background: var(--worky-colour);
    border: 2px var(--worky-colour) solid;    
    color: white;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 100;
    font-style: normal;
}




/* section 2 - how it works*/


#section2{
    height: 1600px;
    width: 100%;
    /* background-color: var(--launch-colour); */
}

#section2-title{
    height: 240px;
    width: 100%;
    color: var(--text-colour);
    text-align: center;
    font-size: 105px;
    padding-top: 100px;
    font-family: "mendl-sans-dUSK", sans-serif;
    font-weight: 500;
    font-style: normal;
    line-height: 110px;

}

#section2-subtext{
    height: auto;
    width: 60%;
    margin:0 auto;
    text-align: center;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 300;
    font-style: normal;
    margin-top: 15px;
}

#section2-button-holder{
    height: 40px;
    width: 162.5px;
    margin:50px auto;
}

#section2-button{
    height: 40px;
    width: 162.5px;
    border-radius: 20px;
    background: var(--worky-colour);
    color: white;
    border: none;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: all ease-in-out 0.12s;
}
#section2-button:hover{
    background: var(--hightlight-colour);
}


#section2-map{
    height: 500px;
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 100px;    
    margin-top: 140px;

}

.section2-fast{
   height: 500px;
   width: 33.3333333333333333333%;
   float: left;
}

.section2-fast:hover .section2-title{
    color: var(--worky-colour);
    font-size: 50px;
}


.section2-icon{
    height: 250px;
    width: 100%;
}
.section2-title{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 40px;
    color: var(--text-colour);
    transition: all ease-in-out 0.12s;

}
.section2-subtext{
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 25px;
    text-align: center;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 300;
    font-style: normal;
    color: var(--text-colour);
}

#icon-fast{
    background-image: url('/public/splash/fast.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

#icon-reliable{    
    background-image: url('/public/splash/reliable.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

#icon-safe{    
    background-image: url('/public/splash/safe.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 58%;
}




#section2-subcontent{
    height: 315px;
    width: 100%;

}

#section2-left{
    height: 315px;
    width: 50%;
    float: left;
    text-align: right;
}

#section2-left-title{
    font-size: 50px;
    color: var(--text-colour);
    padding-right: 15px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 600;
    font-style: normal;
}

#section2-left-subtext{
    font-size: 18px;
    color: var(--text-colour);
    margin-top: 15px;
    height: 50px;
    width:370px;
    float: right;
    text-align: center;
    padding-right: 15px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 300;
    font-style: normal;
}

#section2-right{
    height: 315px;
    float: left;
    padding-left: 15px;

}

#section2-right-img{
    height: 290px;
    width: 400px;
    /* background: var(--launch-colour); */
    /* background-image: url('/public/splash/techsupport.jpg'); */
    /* background-image: url('./portrait-female-worker-customer-service.jpg'); */
    background-size: cover;
    float: right;
    border-radius: 50px;
    /* box-shadow: 0 0 5px lightgray; */

}







@keyframes scroll{
    0%{transform: translateX(0);}
    100%{transform: translateX(calc(-350px * 7));}
}


/* Section 3 - carousel */

.section3{
    padding-top: 100px;
    padding-bottom: 150px;
    height: 150px;
    width: auto;
    margin: auto;
    overflow: hidden;
    position: relative;

}

.section3-slide:hover{
    animation-play-state: paused;
    opacity: 1;

}

.section3-slide{
    animation: scroll 45s linear infinite;
    display: flex;
    width: calc(350px * 14);
    opacity: 0.75;
    transition: all ease-in-out 0.12s;
}

.logo{
    height: 150px;
    width: 350px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;

    
}

#logo0{
    background-image: url('/public/splash/logo0.png');
    background-size: 80%;

}


#logo1{
    background-image: url('/public/splash/company_one.png');
    background-size: contain;

}

#logo2{
    background-image: url('/public/splash/review1.png');
    background-size: 95%;

}

#logo3{
    background-image: url('/public/splash/review2.png');    background-size: 95%;

}


#logo4{
    background-image: url('/public/splash/logo2.png');
    background-size: contain;

}

#logo5{
    background-image: url('/public/splash/logo3.png');
    background-size: contain;

}

#logo6{
 
    background-size: contain;
    background-image: url('/public/splash/logo4.png');
}





/* #one{
    margin-right: 30px;
    background-image: url('./company_one.png');
    position:relative;
}

#two{
    margin-left: 30px;
    margin-right: 30px;
    background-image: url('./company_one.png');
}
#three{    
    margin-left: 30px;
    margin-right: 30px;
    background-image: url('./company_one.png');
}
    
#four{
    margin-left: 30px;
    background-image: url('./company_one.png');
} */

/* .logo1{
    background-image: url('./company_one.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    height: 150px;
    width: 250px;
    margin: 0 60px;
    border: none;

} */


/* section 4 - for employers */

#section4{
    height: 900px;
    width: 100%;
}

#section4-left{
    height: 800px;
    width: 50%;
    float: left;

}

#section4-left-title{
    height: 290px;
    text-align: right;
    font-size: 105px;
    padding-right: 20px;
    margin-top: 110px;
    color: var(--text-colour);
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
}

#section4-left-subtext{
    height: 100px;
    width: 380px;
    padding-right: 20px;
    font-size: 18px;
    color: var(--text-colour);
    float:right;
    font-family: "mendl-sans-dawn", sans-serif;
font-weight: 400;
font-style: normal;

}

#section4-button{
    height: 40px;
    width: 162.5px;
    border-radius: 20px;
    float: right;
    background: var(--worky-colour);
    color: white;
    border: none;
    margin-top: 85px;
    margin-right: 60px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 200;
    font-style: normal;
    transition: all ease-in-out 0.12s;
}

#section4-button:hover{
    background: var(--hightlight-colour);
}
#section4-right{
    height: 800px;
    width: 50%;
    float: right;
}

.section4-right-img-holder{
    height: 100%;
    width: 100%;
    background-image:url('/public/splash/Mockup.png');
    background-size:100%;
    background-repeat: no-repeat;
}

/* section 5 - for employees */

#section5{
    height: 850px;
    width: 100%;

}

#section5-left{
    height: 800px;
    width: 50%;
    float: left;

}

.section5-left-top{
    height: 800px;
    width: 50%;
    float: left;
    /* background-image: url('/public/splash/section5front.png'); */
    background-size: 95%;
    background-repeat: no-repeat;
    z-index: 0;
    position: absolute;
}


.section5-left-bottom{
    height: 800px;
    width: 50%;
    float: left;
    /* background-image: url('/public/splash/section5back.png'); */
    background-size: 95%;
    background-repeat: no-repeat;
    z-index: -1;
    position: absolute;
}






#section5-right{
    height: 900px;
    width: 50%;
    float: right;

}

#section5-right-title{
    height: 290px;
    text-align: left;
    font-size: 105px;
    padding-left: 20px;
    margin-top: 110px;
    color: var(--text-colour);
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
}

#section5-right-subtext{
    height: 100px;
    width: 380px;
    padding-left: 20px;
    font-size: 18px;
    color: var(--text-colour);
    text-align: center;
    font-family: "mendl-sans-dusk", sans-serif;
font-weight: 400;
font-style: normal;

}

#section5-button{
    height: 40px;
    width: 162.5px;
    border-radius: 20px;
    float: left;
    background: var(--worky-colour);
    color: white;
    border: none;
    margin-top: 85px;
    margin-left: 60px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 200;
    font-style: normal;
    transition: all ease-in-out 0.12s;
}

#section5-button:hover{
    background: var(--hightlight-colour);
}


/* section 6 - contact us */

#section6{
    height: 700px;
    text-align: left;
    width: 100%;
    padding-top: 120px;
}

#section6-title{

    width: 70%;

    height: 100px;
    margin: 0 auto;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 600;
    font-style: normal;
    /* color: var(--text-colour); */
    color: var(--worky-colour);
    font-size: 65px;
}

#section6-left{
    height: 400px;
    margin-left: 16%;
    width:25.5%;
    float:left;

}

#section6-left-subtext{
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: var(--text-colour);

}

#section6-social-holder{
    height: 21px;
    width: 100px;
    /* background: red; */
    margin-top: 20px;
}

.social{
    height: 18px;
    width: 18px;
    background: var(--worky-colour);
    float: left;
    border-radius: 5px;
    text-align: center;
    color: var(--launch-colour);
    transition: all ease-in-out 0.12s;
}

.social:hover{
    background:var(--hightlight-colour);
}

#linkedin{
    background-image: url('/public/splash/linkedin-logo.png');
    background-repeat: no-repeat;
    background-size:100%;
}
#instagram{
    background-image: url('/public/splash/instagram-logo.png');
    background-repeat: no-repeat;
    background-size:100%;
}
#tiktok{
    background-image: url('/public/splash/tiktok-logo.png');
    background-repeat: no-repeat;
    background-size:100%;
}
#facebook{
    background-image: url('/public/splash/facebook-logo.png');
    background-repeat: no-repeat;
    background-size:100%;
}


#section6-right{
    height: auto;
    float: left;
    background: var(--launch-colour);
    width: 43%;
    border-radius: 35px;
    padding-bottom: 20px;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);

}

#section6-right-form{

}

#fullname{
    height: 40px;
    width: 90%;
    background-color: white;
    border-radius: 35px;
    margin: 16px auto;
    color: var(--text-colour);
    text-indent: 17px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    margin-top: 25px;
    margin-bottom: 10px;
    margin-left: 5%;
    margin-right: 5%;
    border:none;
    border: 1.5px solid lightgrey;
    /* box-shadow: 0px 0px 3px lightgrey;} */
}

#email{
    height: 40px;
    width: 90%;
    background-color: white;
    border-radius: 35px;
    margin: 16px auto;
    color: var(--text-colour);
    text-indent: 17px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5%;
    margin-right: 5%;
    border: 1.5px solid lightgrey;
}

#subject{
    height: 46px;
    width: calc(90% + 5px);
    background-color: white;
    border-radius: 35px;
    margin: 16px auto;
    color: #757575;
    text-indent: 17px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5%;
    margin-right: 5%;
    border: 1.5px solid lightgrey;
}

#message{
    height: 120px;
    width: 90%;
    background-color: white;
    border-radius: 25px;
    margin: 16px auto;
    color: var(--text-colour);
    text-indent: 17px;
    padding-top: 9px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5%;
    margin-right: 5%;
    border: 1.5px solid lightgrey;
    max-width: 90%;
    max-height: 180px;
    min-height: 40px;
    min-width: 90%;
}

#submit{
    height: 40px;
    width: 162.5px;
    border-radius: 20px;
    float: right;
    background: var(--worky-colour);
    color: var(--launch-colour);
    border: 2px var(--worky-colour) solid;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 100;
    font-style: normal;
    transition: all ease-in-out 0.12s;
    margin-top: 15px;
    margin-right: 5%;
}

#submit:hover{
    background: var(--hightlight-colour);
    border: 2px var(--hightlight-colour) solid;
}
:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}


.myworkforce-title{

    font-size: 35px;
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    position: absolute;
    left: 12.7%;
    top: 100px;
}
.myworkforce-title-mobile{
    margin-top: 70px;
    font-size: 35px;
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    font-size: 35px;
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    /* background: red; */
    margin-top: 70px;
    padding-top: 30px;
    padding-left: 7.5%;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.workforce-holder-mobile{
    height: auto;
    /* padding: 30px; */
    /* padding-top: 10px; */
    width: calc(100% - 60px);
    /* background: red; */
    margin: auto;
    /* margin-top: 130px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    /* gap: 30px; */
}

.workforce-holder{
    height: auto;
    padding: 30px;
    padding-top: 10px;
    width: calc(80%);
    /* background: red; */
    margin: auto;
    margin-top: 130px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.workforce-jobber-card{
    height: 300px;
    width: 375px;
    background: white;
    border-radius: 30px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.01);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.workforce-jobber-card:hover{
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}

.workforce-jobber-card-icon {
    height: 110px;
    width: 110px;
    background: var(--launch-colour);
    border-radius: 100%;
    overflow: hidden;
}

.workforce-jobber-card-icon img {
    height: 120px;
    width: 120px;
    border-radius: 100%;
}

.workforce-jobber-card-name {
    /* height: 55px; */
    padding-top: 20px;
    padding-bottom: 10px;
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 24px;
    color: var(--text-colour);
}

.workforce-jobber-card-stars {
    /* height: 40px; */
    padding-bottom: 10px;
    padding-top: 5px;
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.workforce-jobber-card-contact-holder {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.workforce-jobber-card-contact-email {
    height: auto;
    width: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    background: var(--launch-colour2);
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
}

.workforce-jobber-card-contact-phone {
    height: auto;
    width: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    background: var(--launch-colour2);
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
}

.workforce-jobber-card-bottom {
    /* Styles for the bottom div */
}

.workforce-jobber-card-summary-holder {
    /* Styles for the summary holder div */
}

.workforce-jobber-card-summary-title {
    /* Styles for the summary title div */
}

.workforce-jobber-card-summary-value {
    /* Styles for the summary value div */
}

.workforce-jobber-card-resume {
    /* Styles for the resume div */
}

:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');




.mobile-splash-body{
    position: absolute;
    height: 100%;
    width: 100%;
    background: white;
    padding-top: 25px;
}


.mobile-splash-nav{
    height: 80px;
    /* width: calc(85% - 2px); */
    width: 90%;
    position:fixed;
    padding-left: 5%;
    padding-right: 5%;
    /* border-radius: 20px; */
    /* border: 1px solid rgba(0, 0, 0, 0.078); */
    background-color: rgba(255, 255, 255, .85);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(15px);
    transition: background-color .35s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    /* background: red; */
}



.mobile-splash-logo{
    font-size: 30px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: var(--worky-colour);
    padding-left: 35px;
    cursor: pointer;
}

.mobile-splash-logo:hover{
    color: var(--hightlight-colour);
}

.mobile-splash-right{
    height: 45px;
    width: 150px;
    /* background: blue; */
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.mobile-splash-trynow{
    height: 45px;
    width: 100px;
    background: var(--worky-colour);
    color: white;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    border: none;
    border-radius: 13px;
    opacity: 0;
    transition: 0.175s ease-in-out;
}

#mobile-splash-trynow-visible{
    opacity: 1;

}

.mobile-splash-tray{
    height: 45px;
    width: 45px;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: none;
    border: none;
}

.mobile-options-container{
    height: 220px;
    width: 90%;
    margin: auto;
    margin-left: 5%;
    margin-right: 5%;
    /* background: red; */
    margin-top: 80px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.078);
    background-color: rgba(245,245,247,.1);
    -webkit-backdrop-filter: blur(75px);
    backdrop-filter: blur(75px);
    transition: background-color .35s;
    padding-bottom: 15px;
    z-index: 100;
}

.mobile-option{
    height: 40px;
    width: auto;
    font-size: 16px;
    background: none;    
    border: none;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: var(--text-colour);
    cursor: pointer;
}

#mobile-option-trynow{
    background: var(--worky-colour);
    /* width: 150px; */
    width: 80%;
    text-align: center;
    /* margin-right: calc(80% - 150px); */
    color: white;
    border-radius: 10px;
    /* padding-left: 30px; */
    /* padding-right: 30px; */
}






.mobile-splash-section1 {
    height: auto;
    margin-top: -30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
    background: white;
    /* background: red; */
    padding-bottom: 100px;
}

.mobile-splash-section1-title-holder {
    height: 180px;
    margin-top: 150px;
    border-radius: 35px;

}

.mobile-splash-section1-title {
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 700;
    color: var(--text-colour);
    font-size: 58px;
}

.mobile-splash-section1-subtitle {
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 600;
    color: var(--text-colour);
    font-size: 28px;
}

.mobile-splash-section1-text {
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 500;
    color: var(--text-colour);
    font-size: 25px;
    padding-top: 10px;
}

.mobile-splash-section1-button-holder {
    height: 40px;
    width: 100%;
    padding-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
    /* background-color: red; */
}

.mobile-splash-section1-button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    height: 40px;
    width: 150px;
    border: none;
    border-radius: 10px;
    background: var(--worky-colour);
    color: white;
    cursor: pointer;
}

.mobile-splash-section1-button:hover {
    background: var(--hightlight-colour);
}


.mobile-splash-section1-slider{
    height: 600px;
    width: 90%;
    border-radius: 30px;
    margin-top: 220px;
}

.mobile-fade{
    height: 600px;
    width: 100%;
    border-radius: 30px;
}

.mobile-each-slide{
    display: flex;
    justify-content: center;
    align-items: center;
}
.mobile-slide-img{
    height: 600px;
    /* margin-left: -100px; */
    margin: none;
}



.mobile-splash-section7-container {
    height: auto;
    width: 100%;
    padding-top: 130px;
    padding-bottom: 40px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile-section7-title {
    height: 50px;
    width: 90%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 37px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--worky-colour);
    padding-bottom: 10px;
}

.mobile-section7-subtitle {
    /* height: 80px; */
    width: 90%;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
}

#mobile-section7-button {
    margin-top: 25px;
    margin-bottom: 75px;
}

.mobile-section7-info-holder {
    height: auto;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
}

.mobile-section7-info-container {
    height: 100%;
    width: 100%;
}

.mobile-section7-info-icon {
    height: 75px;
    width: 75px;
}

.mobile-section7-img {
    height: 75px;
    width: 75px;
    margin-left: -25px;
}

.mobile-section7-info-title {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
}

.mobile-section7-info-content {
    height: auto;
    width: 100%;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
}



.mobile-ontime-holder {
    height: auto;
    width: 85%;
    padding-left: 7.5%;
    padding-right: 7.5%;
    background: white;
    display: flex;
    align-items: center;

}

.mobile-ontime-inner {
    /* height: 220px; */
    width: 100%;
    /* padding-top: 30px; */
    /* padding-bottom: 30px; */
    /* background: var(--launch-colour2); */
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    /* justify-content: space-between; */
}

.mobile-ontime {
    height: auto;
    width: 100%;
    /* background: red; */
}

.mobile-ontime-title {

    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: var(--text-colour);
}

.mobile-ontime-value {
    font-family: "mendl-sans-dusk", sans-serif;

    font-size: 75px;
    font-weight: 700;
    color: var(--worky-colour);
}




.mobile-splash-our-partners {
    height: 400px;
    width: 85%;
    padding-left: 7.5%;
    padding-right: 7.5%;
    background: white;
}

.mobile-splash-partners-title {
    height: 50px;
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 30px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    padding-top: 50px;

    color: var(--text-colour);
}

.mobile-splash-partners-subtitle {
    padding-bottom: 35px;
    padding-top: 5px;
    font-family: 'Montserrat', sans-serif;

    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
}

.mobile-splash-partners-container {
    height: 150px;
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
}

.mobile-splash-partner {
    width: calc(100% / 4);
}

.mobile-splash-partner img {
    width: 300px;
    background: white;
    opacity: 0.6;
}

.mobile-splash-partner img:hover {
    opacity: 1;
}


.mobile-partner-fade{
    height: 200px;
    width: 100%;
    border-radius: 30px;
}

.mobile-partner-each-slide{
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-partner-slide-img{
    height: 150px;
    /* margin-left: -100px; */
    margin: none;
}



.mobile-splash-employers {
    /* height: 95vh; */
    height: auto;
    width: 100%;
    padding-bottom: 50px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mobile-splash-text-holder {
    height: auto;
    width: 85%;
    display: flex;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    /* background: red; */
}

.mobile-splash-employers-title {
    text-align: center;
    font-size: 65px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: var(--text-colour);
}

.mobile-splash-employers-text {
    /* height: 125px; */
    height: auto;
    width: 100%;
    /* background: var(--launch-colour2); */
    font-family: 'Montserrat', sans-serif;
    color: var(--text-colour);
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    /* text-align: right; */
    margin-top: 20px;
    margin-bottom: 20px;
    /* margin-left: calc(100% - 450px); */
}

.mobile-splash-employers-button {
    /* margin-left: calc(100% - 180px); */
    margin: auto;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    height: 40px;
    width: 180px;
    border: none;
    border-radius: 10px;
    background: var(--worky-colour);
    color: white;
    cursor: pointer;
}

.mobile-splash-employers-button:hover {
    background: var(--hightlight-colour);
}

.mobile-splash-img-holder {
    /* background: red; */
    height: 500px;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 37.5%; */
    /* padding-right: 7.5%; */
}

.mobile-splash-img-holder img {
    height: 500px;
    margin-left: 7.5%;
}





.mobile-splash-contact{
    height: auto;
    padding-top: 75px;
    width: calc(100% - 15%);
    padding-left:7.5% ;
    padding-right:7.5% ;
    /* background:red; */
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-evenly; */
}

.mobile-splash-contact-title{
    font-size: 40px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--worky-colour);
}

.mobile-splash-contact-subtitle{
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    padding-top: 10px;
    padding-bottom: 15px;
}

.mobile-splash-contact-contactinfo-holder{
    height: 45px;
    width: 100%;
    /* background: red; */
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    
    gap: 10px;
    padding-bottom: 20px;
}

.mobile-splash-contact-contactinfo{
    height: 100%;
    width: calc(50% - 10px);
    background:var(--launch-colour2);
    /* padding-left: 10px; */
    /* background: blue; */
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
}

.mobile-splash-contact-contactinfo:hover{
    background:var(--launch-colour);
}


.mobile-splash-contact-container{
    height: auto;
    width:100%;
    /* background: var(--launch-colour2); */

    display: flex;
    flex-direction: column;
}

.mobile-splash-input-title{
    width: 100%;
    margin: auto;
    /* background: red; */
    text-indent: 20px;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: var(--text-colour);
    padding-top: 15px;
    padding-bottom: 10px;
}

.mobile-splash-input{
    height: 40px;
    width: 100%;
    margin: auto;
    border-radius: 10px;
    border: none;
    border: 2px solid var(--launch-colour);
    text-indent: 10px;
    max-height: 150px;
    min-height: 40px;
    max-width: 100%;
    min-width: 100%;
}

#mobile-splash-message{
    max-height: 150px;
    max-width: 100%;
    min-height: 90px;
    min-width: 680px;
    text-indent: 10px;
    padding: 10px;
    margin-bottom: 20px;
}

#mobile-splash-reason{
    height: 46px;
    width: 710px;
    text-indent: 10px;
    color: var(--text-colour);
}

.mobile-splash-button-holder{
    height: 40px;
    width: 100%;
    /* background: red; */
    padding-top: 20px;
    margin: auto;
    display: flex;
    justify-content: flex-end;
}

.mobile-splash-submit{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    height: 40px;
    width: 180px;
    border: none;
    border-radius: 10px;
    background: var(--worky-colour);
    color: white;
    cursor: pointer;
}

.mobile-splash-submit:hover{
    background: var(--hightlight-colour);
}




.mobile-splash-footer{
    height: 150px;
    width: calc(100% - 15%);
    /* background: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 7.5%;
    padding-right: 7.5%;
    padding-bottom: 60px;
    padding-top: 200px;
    /* background: var(--launch-colour2); */
    background: white;
    justify-content: space-between;
}

.mobile-splash-footer-title{
    font-size: 70px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: var(--worky-colour);
}

.mobile-splash-footer-socials{
    display: flex;
    gap: 7.5px;
}

.mobile-splash-footer-social{
    height: 35px;
    width: 35px;
    background-color: var(--worky-colour);
    border-radius: 10px;
    cursor: pointer;
    background-position: center;
    background-size: 25px;
    background-repeat: no-repeat;
}
.mobile-splash-footer-social:hover{
    background-color: var(--hightlight-colour);
}

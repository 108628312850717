:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.app-body{
    height: 88vh;
    width: 92vw;
    /* margin-top: 5vh; */
    background: var(--launch-colour2);
    border-radius: 50px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6vh;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.app-body::-webkit-scrollbar {
    display: none;
  }


.nav-vertical{
    height: 88vh;
    width: 70px;
    background: var(--worky-colour);
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 35px;
    border-top-left-radius: 35px;
    
    /* margin-left: 60px; */
    position: absolute;
    transition: all ease-in-out 0.05s;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* opacity: 0.1; */
}

.nav-vertical:hover{
    width: 280px;    
    border-bottom-right-radius: 35px;
    border-top-right-radius: 35px;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.3);
    transition: all ease-in-out 0.05s;

}


.nav-logo:hover{
    background-color: var(--hightlight-colour);
}

.nav-logo{
    height: 70px;
    width: 70px;
    margin-left: auto;
    margin-right: auto;
    background: var(--worky-colour);
    color: var(--launch-colour2);
    border: none;
    border-radius: 35px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 22px;
    margin-top: 50px;
    transition: all ease-in-out 0.05s;
}

.nav-optionholder{
    height: auto;
    width: 100%;
    transition: all ease-in-out 0.05s;
}

.nav-option{
    height: 70px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border: none;
    background: none;
    width: 100%;
    transition: all ease-in-out 0.05s;
    border-radius: 35px;
    color: var(--launch-colour2);


}

.nav-option:hover{
    background-color: var(--hightlight-colour);
    border-radius: 0px;
}

#navo-new-sr{
    background: url('/public/sr-plus-icon.png');
    background-repeat: no-repeat;
    background-size: 39px;
    background-position: center;

}






.nav-logout{
    height: 40px;
    width: 70px;
    margin-left: auto;
    margin-right: auto;
    border: none;
    background-color: var(--worky-colour);
    border-radius: 35px;
    margin-bottom: 80px;
    color: var(--launch-colour2);
    background-image: url('/public/logout.png');
    background-position: center;
    background-size: 16px;
    background-repeat: no-repeat;
    transition: all ease-in-out 0.05s;


}

.nav-logout:hover{
    background-color: var(--hightlight-colour);
}


/* .app-body{
    height: 88vh;
    width: 92vw;
    background: var(--launch-colour2);
    border-radius: 50px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6vh;
    overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;  
} */

.app-body-mobile{
    height: auto;
    background: var(--launch-colour2);
    width: 100vw;    position: relative; padding-bottom: 70px;
    padding-top: 0.1px;

}

.bottomNav{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    backdrop-filter: blur(10px); /* Adjust the blur amount as needed */
    background-color: rgba(255, 255, 255, 0.7); /* Adjust the alpha value for transparency */

}


.app-card{
    height: 140px;
    width: 400px;
    background: white;
    border-radius: 25px;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
}

.card-m{
    border-radius: 50px;
    background: white;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    display: flex;
    margin-top: 1%;
    width: 100%;
    flex-direction: row;
    padding-top: 15px;
}



.card-d{
    border-radius: 50px;
    background: white;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    margin-left: 10%;
    display: flex;
    margin-top: 4.5%;
    flex-direction: row;
}


.card-info-d{
    /* background: red; */
    /* width: calc(35% - 60px); */
    padding-left: 30px;
    padding-right: 30px;
    margin: auto;
    align-self: center;
}


.card-info-m{
    padding-top: 10px;
}

.card-content-d-container{
    /* width: 65%; */
    height: 85%;
    margin: auto;

    align-self: center;    
    flex-direction: column;
    /* background: blue; */
    position: relative;

}




.card-info-d-title{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 40px;
    color: var(--text-colour);
}
.card-info-d-subtext{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: var(--text-colour);
}

.card-info-d-content{
    height: 150px;
    width: 296px;
    border-radius: 30px;
    /* border: 2px solid var(--launch-colour); */
    margin: auto;
    margin-top: 25px;

}

.card-info-d-identity{
    height: auto;
    width: 100%;
    /* background: red; */
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.identity-org-profileholder{
    height: 60px;
    width: 200px;
    /* background: green; */
    display: flex;
    flex-direction: row;
}

.identity-org-topholder{
    height: auto;
    width: 150px;
    /* background: blue; */
    text-align: left;
    padding-left:10px ;
}

.identity-org-icon{
    height: 56px;
    width: 56px;
    border: 2px solid var(--launch-colour);
    background: white;
    border-radius: 55px;
    /* margin-left: 29px; */
    /* align-items: center; */
}
.identity-org-name{
    padding-top: 8px;
    /* padding-bottom: 10px; */
    /* background:red; */
    width: auto;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    color: var(--text-colour);

}

.identity-org-username{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;

    /* background: blue; */
}


.identity-org-bottomholder{
    /* background: red; */
    /* background-color: red; */
    padding-top: 15px;
    width: auto;
    color: var(--text-colour);
}
#org-info-label{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 13px;
}


.identity-org-info{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    text-align: left;
    padding-top: 2.5px;
}








.card-info-d-button{
    height: 40px;
    width: 150px;
    border-radius: 20px;
    margin-top: 75px;
    background: var(--launch-highlight);
    color: white;
    border: 2px var(--launch-highlight) solid;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 100;
    font-style: normal;
    transition: all ease-in-out 0.12s;
}

.card-info-d-button:disabled,
button[disabled]{
  background-color: #eeeeee;
  border: 2px #eee solid;
  color: #888888;
}


.card-info-d-progress{
    height: 65px;
    width: 250px;
    /* background: blue; */
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    /* flex-direction: row; */

    align-items: center;
    transition: 0.5s ease-in-out;

}


.progress-bar{
    height: 3px;
    width: 190px;
    background: var(--launch-colour);
    position: absolute;
    display: flex;
    margin-left: 30px;
    transition: 0.5s ease-in-out;
}

.progress-bar-inner-1{
    height: 3px;
    width: 55px; /*55,135,190*/
    background: var(--worky-colour);
    align-self: center;
    transition: 0.5s ease-in-out;
}
.progress-bar-inner-2{
    height: 3px;
    width: 135px; /*55,135,190*/
    background: var(--worky-colour);
    align-self: center;
    transition: 0.5s ease-in-out;
}
.progress-bar-inner-3{
    height: 3px;
    width: 190px; /*55,135,190*/
    background: var(--worky-colour);
    align-self: center;
    transition: 0.5s ease-in-out;
}

.stateholder{
    height: 34px;
    width: 200px;
    position: absolute;
    margin-left: 25px;
    z-index: 2;
    /* background: red; */
    display: flex;
    justify-content: space-between;
    transition: 0.5s ease-in-out;

}
.progress-state{
    height: 30px;
    width: 30px;
    /* background: var(--worky-colour); */
    background-color: white;
    border-radius: 55px;
    z-index: 2;
    border: 2px solid var(--worky-colour);
    transition: 0.5s ease-in-out;

}

#state1-1{
    background: var(--worky-colour);
    background-image: url('/public/add-icon.png');
    background-position:center;
    background-repeat: no-repeat;
    background-size: 15px;
}
#state1-2{
    background: var(--worky-colour);
    background-image: url('/public/add-icon.png');
    background-position:center;
    background-repeat: no-repeat;
    background-size: 15px;
}
#state1-3{
    background: var(--worky-colour);
    background-image: url('/public/add-icon.png');
    background-position:center;
    background-repeat: no-repeat;
    background-size: 15px;
}





#state2-1{
    background-image: url('/public/calender-grey.png');
    background-position:center;
    background-repeat: no-repeat;
    background-size: 15px;
    border: 2px solid var(--launch-colour);
}
#state2-2{
    background: var(--worky-colour);
    background-image: url('/public/calender-white.png');
    background-position:center;
    background-repeat: no-repeat;
    background-size: 15px;
}
#state2-3{
    background: var(--worky-colour);
    background-image: url('/public/calender-white.png');
    background-position:center;
    background-repeat: no-repeat;
    background-size: 15px;
}






#state3-1{
    background-image: url('/public/reciept-grey.png');
    background-position:center;
    background-repeat: no-repeat;
    background-size: 15px;
    border: 2px solid var(--launch-colour);
}
#state3-2{
    background-image: url('/public/reciept-grey.png');
    background-position:center;
    background-repeat: no-repeat;
    background-size: 15px;
    border: 2px solid var(--launch-colour);
}
#state3-3{
    background: var(--worky-colour);
    background-image: url('/public/reciept-white.png');
    background-position:center;
    background-repeat: no-repeat;
    background-size: 15px;
}


.statetextholder{
    height: auto;
    width: 250px;
    margin-top: 70px;
    display: flex;
    justify-content:space-around ;


}

.progress-text{
    width: 66px;
    float: left;
    text-align: center;
    /* background: var(--launch-highlight); */
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
}
#statetext1-1{color: var(--worky-colour);font-weight: 600;}
#statetext1-2{color: var(--worky-colour);font-weight: 600;}
#statetext1-3{color: var(--worky-colour);font-weight: 600;}


#statetext2-1{color: #888;}
#statetext2-2{color: var(--worky-colour);font-weight: 600;}
#statetext2-3{color: var(--worky-colour);font-weight: 600;}

#statetext3-1{color: #888;}
#statetext3-2{color: #888;}
#statetext3-3{color: var(--worky-colour);font-weight: 600;}









.card-content-d{
    height: 100%;
    width: 100%;
    /* background-color: var(--launch-colour); */
    float: right;
    display: flex;

    /* background: red; */
    /* background-color:#888888; */
}

.card-content-d-title{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 25px;
    color: var(--worky-colour);  
    height: 60px;
    width: 100%;
    /* top: 50px; */
}


.card-content-d-subtext{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 14px;
    color: var(--text-colour);  
    padding-left: 15px;
    padding-right: 15px;
}



.card-content-m-minus{
    height: 75px;
    width: 75px;
    border: none;
    border-radius: 75px;
    background: var(--launch-colour2);
    margin-left: 0px;
    margin-right: 0px;
    font-size: 25px;
    text-align: center;
    color: var(--text-colour);

}

.card-content-m-value{
    height: 150px;
    width: 100px;
    border: none;
    border-radius: 150px;
    color: var(--text-colour);
    /* padding-top: 30px; */
    font-size: 100px;
    margin-left: 0px;
    margin-right: 0px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 500;
    font-style: normal;
    text-align: center;

}


.card-content-m-plus{
    height: 75px;
    width: 75px;
    border: none;
    border-radius: 75px;
    background: var(--launch-colour2);
    margin-left: 0px;
    margin-right: 0px;
    font-size: 25px;
    text-align: center;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: var(--text-colour);

}

/* .card-content-d-minus:hover{
    background:var(--launch-colour);
}
.card-content-d-minus:active{
    background:var(--worky-colour);
    color:white;
}
.card-content-d-plus:hover{
    background:var(--launch-colour);
}
.card-content-d-plus:active{
    background:var(--worky-colour);
    color:white;
} */


















.card-content-d-content2{
    height: auto;
    width: auto;
    margin: auto;
    align-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: blue; */
}


.card-content-d-content2-timeholder{
    height: 70px;
    width: 320px;
    /* background: red; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.time-title-card2{
    /* background: red; */
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    text-align: center;
    width: 50%;
    padding-bottom: 2px;
    color: var(--text-colour);
    float: left;

}





/* page width page3 */
.card-content-d-content3{
    min-height: 300px;
    height: auto;
    width: 76%;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: relative;
    flex-direction: column;

}

.content3-nums{
    /* height: 260px; */
    height: auto;
    max-height: 232px;
    width: 100%;
    border-radius: 30px;
    border: 2px solid var(--launch-colour);
    width: calc(100% - 4px);
    overflow: scroll;
    padding-top: 2.5px;    
    padding-bottom: 2.5px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.content3-nums::-webkit-scrollbar {
    display: none;
  }

.content3-final{
    height:80px;
    border-radius: 30px;
    border: 2px solid var(--launch-colour);
    width: calc(100% - 4px);
    display: flex;
    flex-direction: row;
    justify-content:stretch;
    align-items: center;

    /* margin-top: 20px; */
}

.content3-jobber-details{
    border-radius: 15px;
    height: 55px;
    width: calc(100% - 30px);
    background: var(--launch-colour2);
    margin: 12.5px auto;
    transition: 0.1s ease-in-out all;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.details-type-icon{
    height: 40px;
    width: 40px;
    margin-left: 10px;
    background: white;
    border-radius: 10px;
}

.details-date{
    height: 40px;
    width: 135px;
    display: flex;
    align-items:center;
    justify-content: space-between;
    /* justify-content: center; */
    /* background: white; */
    /* background:red; */

}

.details-date-title{
    width: 30px;
    height: auto;
    /* background-color: red; */
    font-size: 10px;
    text-align: center;
    padding-right: 5px;
}


.details-date-info{
    height: 40px;
    width: 100px;
    border-radius: 10px;
    background: white;
    font-size: 13px;
    border: none;
    font-weight: bold;
    color: var(--text-colour);
}

.details-jobber-count{
    height: 40px;
    width: 85px;
    display: flex;
    align-items:center;
    justify-content: space-between;
    /* background:red; */
}

.details-jobber-count-title{
    width: 40px;
    height: auto;
    font-size: 10px;
    /* background-color: red; */
    text-align: center;
}

.details-jobber-count-info{
    height: 40px;
    width: 40px;
    font-size: 13px;
    border: none;
    font-weight: bold;
    border-radius: 10px;
    background: white;
}

.details-start-time-holder{
    height: 40px;
    width: 125px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.details-time-title{
    width: 40px;
    height: auto;
    font-size: 10px;
    text-align: center;
}

.details-start-time{
    height: 40px;
    width: 85px;
    background: white;
    text-align: center;
    font-size: 13px;
    border: none;
    font-weight: bold;    
    border-radius: 10px;
    color: var(--text-colour);
}

.details-end-time-holder{
    height: 40px;
    width: 125px;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.details-end-time{
    height: 40px;
    width: 85px;
    background: white;
    font-size: 13px;
    border: none;
    font-weight: bold;
    text-align: center;
    border-radius: 10px;
    color: var(--text-colour);
}



.details-edit{
    height: 35px;
    width: 35px;
    border: none;
    border-radius: 30px;
    background: var(--launch-colour);
    background-image: url('/public/edit-grey.png');
    background-position: center;
    background-repeat:no-repeat;
    background-size: 19px;
    margin-right: 10px;
}

.details-edit:hover{
    background-image: url('/public/edit-blue.png');
}

.content3-title{
    text-align: left;
    text-indent: 40px;
    margin-bottom: 2.5px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 17px;
    color: var(--text-colour);

}
#content3-title2{
    margin-top: 15px;
}
.cal{color: var(--text-colour);}







.content3-reciept{
    border-radius: 15px;
    height: 55px;
    width: calc(100%);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

}

.reciept-holder{
    height: 40px;
    width: auto;
    display: flex;
    flex-direction: row;
}

.reciept-title{
    font-size: 9px;
    height: auto;
    width: 38px;
    text-align: center;
    align-self: center;
}


.reciept-value{
    background: none;
    border: none;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: bold;
    color: var(--text-colour);
}




.srpage-title-holder{
    height: 35px;
    width: auto;
    display: flex;
    gap: 15px;
    position: absolute;
    left:12.7%;
    top: 100px;
}
.srpage1-title-title{
    font-size: 35px;
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
}


.srpage1-mobile-title{
    font-size: 35px;
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    /* background: red; */
    margin-top: 70px;
    padding-top: 30px;
    padding-left: 7.5%;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.srpage1-title-title-mobile{

    display: none;
}








.org-newsr{
    height: 470px;
    width: 900px;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 20px;
}

.org-newsr-mobile{
    height: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 20px;
    /* background: red; */
}



/* sr page 1 */
.srpage1{

    margin: auto;
    width: 100%;
    /* background: red; */
    /* background:white; */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 30px;
    padding-top: 20px;
    border-radius: 30px;
}

.srpage1-mobile{
    height: auto;
    margin: auto;
    width: 90%;
    /* background: blue; */
    /* background:white; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}


.srpage1-left-subtitle{
    font-size: 30px;
    height: 30px;
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    /* background:red; */
    color: var(--text-colour);
    padding-bottom: 30px;
    padding-left: 20px;
}

.srpage1-left-subtitle-mobile{
    font-size: 25px;
    height: 30px;
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    /* font-family: 'Montserrat', sans-serif; */

    font-weight: 500;
    font-style: normal;

    color: var(--text-colour);
    padding-bottom: 15px;
    padding-left: 20px;
}


.srpage1-right{
    width: 100%;
    height: auto;
}

.srpage1-numjobbers{
    height: 280px;
    /* min-width: 500px;
    max-width: 600px; */
    width: 90%;
    background-color: white;
    /* background: var(--launch-colour2); */
    border-radius: 30px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);

}


.srpage1-numjobbers-mobile{
    height: 400px;
    width: 100%;
    background-color: white;
    /* background: var(--launch-colour2); */
    border-radius: 30px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
    margin-bottom: 10px;
}
.srpage1-numjobbers:hover{
    box-shadow: 0 0 4px rgba(0,0,0,0.1);

}

.srpage1-numjobbers-minus{
    height: 75px;
    width: 75px;
    border: none;
    border-radius: 75px;
    /* background: var(--launch-colour2); */
    background: white;
    font-size: 25px;
    text-align: center;    cursor: pointer;

    color: var(--text-colour);}
.srpage1-numjobbers-value{
    height: 150px;
    width: 150px;
    border: none;
    /* background: var(--launch-colour2); */
    background: white;
    border-radius: 150px;
    color: var(--text-colour);
    font-size: 100px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 500;
    font-style: normal;
    text-align: center;}
.srpage1-numjobbers-plus{
    height: 75px;
    width: 75px;
    border: none;
    border-radius: 75px;    cursor: pointer;

    /* background: var(--launch-colour2); */
    background-color: white;
    font-size: 25px;
    text-align: center;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: var(--text-colour);}
.srpage1-numjobbers-minus:hover{
    background:var(--launch-colour);}
.srpage1-numjobbers-minus:active{
    background:var(--launch-highlight);}
.srpage1-numjobbers-plus:hover{
    background:var(--launch-colour);}
.srpage1-numjobbers-plus:active{
    background:var(--launch-highlight);}
/* sr page 1 */





/* sr page 2 */


.srpage2{
    display: flex;
    flex-direction: row;
    /* justify-content: baseline; */
    align-items:baseline;
    margin: auto;
    width: auto;
    gap: 30px;
    /* background-color: white; */
    border-radius: 30px;
    /* background:white; */
    /* background: green; */
}

.srpage2-mobile{
    display: flex;
    flex-direction: column;
}

.srpage2-left{
    width: auto;
    height: auto;
    /* background:white; */
    display: flex;
    padding: 30px;
    flex-direction: column;
    border-radius: 30px;
}

.srpage2-left-mobile{
    width: auto;
    height: auto;
    /* background:white; */
    display: flex;
    /* padding: 30px; */
    margin: auto;
    padding-bottom: 30px;
    padding-top: 10px;
    flex-direction: column;
    border-radius: 30px;
}


.srpage2-left-title{
    text-indent: 25px;
    width: auto;
    font-size: 30px;
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
}

.srpage2-left-title-mobile{
    /* text-indent: 25px; */
    width: auto;
    font-size: 25px;
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
}

.srpage2-left-calender{
    text-indent: 0;
    height: auto;
    width: auto;
    /* background: var(--launch-colour2); */
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
    border-radius: 20px;
    border: 2px solid white;
    /* overflow: hidden; */
}
.srpage2-left-calender:hover{
    box-shadow: 0 0 4px rgba(0,0,0,0.1);

}

.srpage2-right{
    /* height: 30px; */
    height: 312px;

    /* background: white; */
    /* background-color: red; */

    width: 330px;
    display: flex;
    /* margin: auto; */
    flex-direction: column;
    /* gap: 30px; */
}
.srpage2-right-mobile{
    height: auto;
    width: 82%;
    margin: auto;
    margin-bottom: 30px;
}




.srpage2-right-inner{
    background: white;
    /* padding: 30px; */
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);

}
.srpage2-right-inner:hover{
    box-shadow: 0 0 4px rgba(0,0,0,0.1);

}

.srpage2-right-title{
    height: 30px;
    width: 100%;
    /* padding-top: 15px; */
    font-size: 30px;
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    margin-bottom: 22px;
    /* text-indent: 25px; */
}

.srpage2-right-subtitle{
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
    font-size: 17px;
    height: 20px;
    width: 100%;
    text-indent: 15px;
    /* background: red; */
    margin-bottom: 5px;
}

.srpage2-starttime{
    margin-bottom: 30px;
    /* margin-top: 30px; */
    height: 60px;
    background: var(--launch-colour2);
    border: none;
    width: 100%;
    float: left;
    border-radius: 15px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #888;
    font-size: 14px;
    text-align: center;
    /* padding-right: 15px; */
}

.srpage2-endtime{
    /* margin-top: 30px; */

    height: 60px;
    width: 100%;
    float: right;
    background: var(--launch-colour2);
    border: none;
    border-radius: 15px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #888;
    font-size: 14px;
    text-align: center;
    /* padding-right: 15px; */
}



.card-content-d-progress{
    height: 40px;
    width: 100%;
    /* background: blue; */
    padding-top: 25px;
}

.card-content-d-progress-mobile{
    height: auto;
    width: 90%;
    /* background: red; */
    display: flex;
    gap: 20px;
    align-items: center;
    margin: auto;
    justify-content: end;
    padding-bottom: 50px;
}



.card-content-d-back{
    float: left;
    height: 40px;
    padding-left: 25px;
    padding-right: 25px;
    width: auto;
    background: var(--launch-colour);
    color: #888888;
    height: 45px;
    padding-left: 30px;
    padding-right: 30px;
    width: auto;
    border: none;
    border-radius: 15px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    cursor: pointer;

}
.card-content-d-back-mobile{

    height: 55px;
    width: 55px;
    background: var(--launch-colour);
    color: #888888;
    border: none;
    border-radius: 100%;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    cursor: pointer;

}


/* 
    height: 55px;
    width: 55px;
    color: white;
    border: none;
    border-radius: 100%;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    background: var(--worky-colour);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

*/

.card-content-d-back:hover{
    background: var(--launch-highlight);
    color: white;
}


.card-content-d-next{
    float: right;
    height: 45px;
    padding-left: 30px;
    padding-right: 30px;
    width: auto;
    color: white;
    border: none;
    border-radius: 15px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    background: var(--worky-colour);
    cursor: pointer;
}

.card-content-d-next-mobile{
    height: 55px;
    width: 55px;
    color: white;
    border: none;
    border-radius: 100%;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    background: var(--worky-colour);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}


.card-content-d-next:hover{
    background: var(--hightlight-colour);
}

.card-content-d-submit{
    float: right;
    height: 45px;
    padding-left: 30px;
    padding-right: 30px;
    width: auto;
    color: white;
    border: none;
    border-radius: 15px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 400;
    font-style: normal;
    background: var(--worky-colour);
    cursor: pointer;

}

.card-content-d-submit-mobile{
    height: 55px;
    width: 120px;
    color: white;
    border: none;
    border-radius: 27.5px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    background: var(--worky-colour);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    /* justify-content: space-evenly; */
    gap: 10px;
}



.card-content-d-submit:hover{
    background: var(--hightlight-colour);
}



.srpage3{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin: auto;
    width: auto;
    /* gap: 15px; */
    /* background: white; */
    /* background:red; */
    /* border-radius: 30px; */
}


.srpage3-mobile{
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    margin: auto;
    width: 90%;
    padding-top: 10px;
    /* gap: 15px; */
    /* background: white; */
    /* background:red; */
    /* border-radius: 30px; */
}



.srpage3-left{
    height: auto;
    max-height: 360px;
    /* width: 300px; */
    width: 75%;
    display: flex;
    margin: auto;
    flex-direction: column;
    /* padding: 30px; */
    gap: 8px;
    /* background: white; */
    /* border-radius: 30px; */
    overflow-x:hidden;

}


.srpage3-left-mobile{
    height: auto;
    width: 100%;
    display: flex;
    margin: auto;
    flex-direction: column;
    /* padding: 30px; */
    padding-top: 30px;
    padding-bottom: 30px;

    /* gap: 20px; */
    /* background: white; */
    border-radius: 30px;
    overflow-x:hidden;
}

.srpage3-left-title{
    height: 30px;
    width: 100%;
    /* background: white; */
    text-align: left;
    font-size: 30px;
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
}


.srpage3-jobber-holder {
    height: auto;
    /* width: 270; */
    /* background: red; */
    /* display: flex;
    flex-direction: column;
    gap: 5px; */
    /* overflow: scroll; */
    overflow-x:hidden;
    background: white;
    padding: 30px;
    border-radius: 30px;    
    box-shadow: 0 0 2px rgba(0,0,0,0.1);

  }
  .srpage3-jobber-holder:hover {
    box-shadow: 0 0 4px rgba(0,0,0,0.1);

  }

  
  
  .prev-jobber {
    height: 40px;
    margin-top: 7.5px;
    margin-bottom: 7.5px;
    width: 100%;
    background: var(--launch-colour2);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

  }



  .each-prev-jobber-holder{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 2.5px;
    margin-bottom: 2.5px;
    /* background: red; */
    
    justify-content: space-between;
    display: inline-flex;
    flex-wrap: wrap;
  }

  .jobber-holder{
    height: auto;
    flex: 9;
    overflow:hidden;

    /* background: green; */
  }
  .checkbox-holder{
    height: 45px;
    flex: 1;
    overflow:hidden;
    cursor: pointer;
    /* width: calc(15% - 20px);
    padding-left: 10px;
    padding-right: 10px; */
    /* position:absolute; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background:blue; */
    /* margin-left: 200px; */
  }
  
  .prev-jobber-checkbox{
    cursor: pointer;
    overflow:hidden;

    /* margin-right: 15px; */
  }

  .prev-jobber-label{
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    font-family: "mendl-sans-dusk", sans-serif;
  }

  .prev-jobber:hover{
    background: var(--launch-colour);
  }


.prev-jobber-profile-pic-holder{
    height: 30px;
    width: 30px;
    border-radius: 100%;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prev-jobber-profile-icon{
    height: 30px;
    border-radius: 100%;
}






.select-all-prev-jobbers{
    height: 35px;
    width: 100%;
    background: var(--worky-colour);
    border: none;
    border-radius: 10px;
    color: white;
    font-weight: 500;
    font-size: 15px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    cursor: pointer;
}
.select-all-prev-jobbers:hover{
    background: var(--hightlight-colour);

}
.select-all-prev-jobbers:active{
    background: var(--hightlight-colour);
}


.srpage3-right{
    height: 300px;
    width: 300px;
    display: flex;
    /* margin: auto; */
    flex-direction: column;
    padding: 30px;
    /* gap: 30px; */
    /* background: white; */
    border-radius: 30px;
}
.srpage3-right-mobile{
    height: 300px;
    width: 100%;
    display: flex;
    /* margin: auto; */
    flex-direction: column;
    /* padding: 30px; */
    padding-bottom: 30px;
    /* gap: 30px; */
    /* background: white; */
    border-radius: 30px;
}



.srpage3-right-title{
    height: 30px;
    width: 100%;
    /* background: white; */
    text-align: left;
    font-size: 30px;
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    margin-bottom: 20px;
}

.srpage3-right-title-mobile{
    height: 30px;
    width: 90%;
    margin-left: 5%;
    /* background: white; */
    text-align: left;
    font-size: 25px;
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
    margin-bottom: 10px;
}

.srpage3-right-inner{
    background: white;
    padding: 30px;
    border-radius: 30px;    
    box-shadow: 0 0 2px rgba(0,0,0,0.1);

}

.srpage3-right-inner:hover{
    box-shadow: 0 0 4px rgba(0,0,0,0.1);

}


.srpage3-right-subtitle{
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
    font-size: 17px;
    height: 20px;
    width: 100%;
    /* background: red; */
    margin-bottom: 10px;
}

.srpage3-right-setpay{
    height: 85px;
    width: 100%;
    margin-bottom: 30px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
    font-size: 67.5px;
    display: flex;
}
.dollar{
    height: 100%;
    width: 100%;

    background: var(--launch-colour2);
    border-radius: 25px;
    /* padding-left: 20px; */
    /* padding-right: 10px; */
}

.input-jobberpay{
    height: 83px;
    background: var(--launch-colour2);
    border: none;
    width: 185px;
    text-indent: 15px;
    border-radius: 20px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
    font-size: 67.5px;
}

.input-workertype{
    height: 55px;
    width: 100%;
    border: none;
    text-indent: 25px;
    border-radius: 15px;
    background: var(--launch-colour2);
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    color: var(--text-colour);
}


.srpage3-right-workertype{
    height: 55px;
    width: 100%;
    /* background: red; */
    /* margin-bottom: 30px; */
}

.srpage3-right-workertype2{
    height: 55px;
    width: 100%;
    /* background: red; */
    /* margin-bottom: 30px; */
    margin-bottom: 15px;

}














.srpage4{
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    margin: auto;
    margin-top: 30px;
    width: auto;
    gap: 20px;
    /* background: white; */
    border-radius: 30px;
    /* padding: 30px; */
}

.srpage4-mobile{
    /* background: red; */
    margin: auto;
    margin-top:10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 90%;
}


.srpage4-inner{
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    margin: auto;
    width: auto;
    gap: 30px;
    background: white;
    border-radius: 30px;
    padding: 30px;box-shadow: 0 0 2px rgba(0,0,0,0.1);
}

.srpage4-inner:hover{    box-shadow: 0 0 4px rgba(0,0,0,0.1);

}

.srpage4-title{
    height: 30px;
    width: 100%;
    /* background: white; */
    text-align: left;
    font-size: 30px;
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
}
.srpage4-title-mobile{
    height: 30px;
    width: 100%;
    /* background: white; */
    text-align: left;
    font-size: 25px;
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
    padding-left: 20px;
}

.srpage4-reciept{
    max-height: 320px;
    height: auto;
    min-width: 375px;
    max-width: 700px;
    width: auto;
    /* overflow-y: scroll; */
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* background: red; */
}

.srpage4-reciept-mobile{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.srpage4-reciept-title{
    text-align: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: var(--text-colour);
    font-size: 17px;
    height: auto;
    /* width: calc(100% - 15px); */
    width:100%;
    /* padding-left: 15px; */
    /* background: red; */
    display: flex;
    justify-content: space-between;
}

.reciept-value1{
    font-weight: 600;
}






.stepper-holder{
    height: 75px;
    width: 45%;
    /* background: red; */
    margin: auto;
    padding-top: 65px;
    /* margin-left: 360px; */
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    transition: 0.3s ease-in-out;}

.stepper-holder-mobile{
    /* padding-top: 20px; */
    padding-top: 5px;
    height: 55px;
    width: 85%;
    /* width: 45%; */
    /* background: red; */
    margin: auto;
    margin-top: 0px;
    /* margin-top: 70px; */
    margin-bottom: -15px;
    /* margin-bottom: 25px; */
    display: flex;
    justify-content: space-between;
    transition: 0.3s ease-in-out;
}



.step{
    height: 75px;
    width: 100px;
    /* background: green; */
    display: flex;
    flex-direction: column;    
    transition: 0.3s ease-in-out;
}
.step-mobile{
    height: 75px;
    width: 70px;
    /* background: green; */
    display: flex;
    flex-direction: column;    
    transition: 0.3s ease-in-out;
}


.step-icon{
    height: 40px;
    width: 40px;
    background: white;
    margin:auto;
    margin-top: 5px;
    border-radius: 45px;
    border: 2px solid var(--launch-colour);
    display: flex;
    align-items: center;
    justify-content: center;    transition: 0.3s ease-in-out;

}
.step-text{
    height: 17px;
    padding-top: 8px;
    width: 100px;
    /* background:blue; */
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    color: var(--text-colour);    transition: 0.3s ease-in-out;
    display: none;

}
.step-text-mobile{
    height: 17px;
    padding-top: 8px;
    width: 70px;
    /* background:blue; */
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    color: var(--text-colour);    transition: 0.3s ease-in-out;
    display: none;

}



.stepline{
    height: 3px;
    width: calc(100%);
    background: var(--launch-colour);
    margin-top: 26px;
    border-radius: 10px;    transition: 0.3s ease-in-out;
}



#stepline-active{
    background: var(--worky-colour);
}

.step-icon-inner{
    height: 27px;
    width: 25px;
    padding-left: 2px;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--launch-colour);
}

.step-icon-inner1{
    height: 27px;
    width: 27px;
    background-image: url('/public/add-jobber-icon.png');
    background-size: 25px;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: -2px;
    margin-left: 1px;
}
.step-icon-inner2{
    height: 27px;
    width: 27px;
    background-image: url('/public/calender-icon.png');
    background-size: 25px;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 2px;

}
.step-icon-inner3{
    height: 27px;
    width: 27px;
    background-image: url('/public/payment-icon.png');
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 3px;
    margin-left: 1px;

}
.step-icon-inner4{
    height: 27px;
    width: 27px;
    background-image: url('/public/confirm-icon.png');
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
}




.submit-success{
    height: auto;
    width: 400px;
    padding: 25px;
    border-radius: 35px;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.submit-success-title{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 33px;
    color: var(--text-colour);
    padding-bottom: 5px;
}

.submit-success-icon{
    padding-top: 20px;
    padding-bottom: 20px;
}


.submit-success-subtitle{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: var(--text-colour);
    
}

.submit-success-button{
    margin-top: 25px;
    height: 45px;
    width: 125px;
    border-radius: 10px;
    font-size: 15px;
    border: none;
    background: var(--worky-colour);
    color: white;
    font-weight: 500;    
    font-family: "mendl-sans-dawn", sans-serif;
    cursor: pointer;

}

.submit-success-button:hover{
    background: var(--hightlight-colour);
    font-weight: 500;
}


.start-timepicker{
    padding-bottom: 30px;
}

.start-timepicker input{
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
}
.start-timepicker a{
    display: none;
}

.shiftback-buttons{
    height: 50px;
    padding-bottom: 5px;
    width: 265px;
    margin: auto;
    display: flex;
    align-items: center;
    /* background-color: red; */
    justify-content: space-between;
}


.srpage2-recurring{
    height: auto;
    width: 100%;
    background: white;
    margin-top: 20px;
    border-radius: 20px;
    display: flex;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);

}

.srpage2-recurring-title{
    height: 65px;
    width: 70%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
    font-size: 17px;
    /* padding-left: 10px; */
}

.srpage2-recurring-switch{
    /* background: blue; */
    height: 65px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
    font-size: 15px;
    padding-right: 10px;
}


.recurring-shift-modal{
    height: auto;
    width: 300px;
    padding: 30px;
    background: white;
    border-radius: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}


.recurring-shift-modal-title{
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    color: var(--text-colour);
}

.recurring-shift-modal-dow-holder{
    height: 50px;
    width: auto;
    /* background: red; */
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.recurring-dow{
    background: var(--launch-colour);
    border: none;
    border-radius: 10px;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--text-colour);
    cursor: pointer;
}


.recurring-dow:hover{
    /* background: var(--hightlight-colour); */
    background: var(--worky-colour);
    color: white;
}

.recurring-dow.selected {
    background:  var(--worky-colour);
    color: white;

}

.recurring-confirm{
    height: 35px;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    background: var(--worky-colour);
    color: white;
    border: none;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    margin-top: 20px;
}

.recurring-confirm:hover{
    background: var(--hightlight-colour);
}


.calendly-app{
    background: white;
    height: auto;
}






.calendly-inline-widget{
    height: 500px;
}



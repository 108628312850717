:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');



.new-shift-body{
    height: auto;
    width: auto;
    /* background: var(--launch-colour2); */
    /* background: red; */
    padding-top: 125px;
}

.new-shift-body-mobile{
    height: auto;
    width: auto;
    /* background: var(--launch-colour2); */
    /* background: red; */
}


.new-shift-page{
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    /* background: red; */
    /* gap: 30px; */
}
.new-shift-page-mobile{
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    align-items: center;
    justify-content: center;
    /* background: red; */
    /* gap: 30px; */
}
.new-shift-page-mobile{
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;

    align-items: center;
    /* background: red; */
}

.accordian-shift-dates{
    font-size: 13px;
    font-family: "mendl-sans-dusk", sans-serif;
    display:flex;
    gap: 7px;
    /* height: 50px; */
    /* background: red; */
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap; 
}

.accordian-shift-dates-each-mobile{
    height:100%;
    /* width: 65px; */
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    display: flex;/* Display child divs inline */
    /* background: blue; */
    /* white-space: wrap; */
    /* text-overflow: ellipsis; */
    background: var(--launch-colour2);
}
.accordian-shift-dates-each{
    height:100%;
    /* width: 65px; */
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    display: flex;
    background: var(--launch-colour);
}

.accordian-shift-dates div:hover{

    background: var(--launch-colour);
}

.new-shift-page-holder{
    height: 500px;
    width: 77.5%;
    background: white;
    margin: auto;
    /* border-radius: 20px; */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
}
.new-shift-page-holder-mobile{
    height: auto;
    width: 90%;
    /* background: white; */
    margin: auto;
    /* border-radius: 20px; */
    /* border-top-left-radius: 20px; */
    /* border-top-right-radius: 20px; */
    /* background: red; */
    display: flex;
    flex-direction: column;
}


.new-shift-stepper{
    height: 100%;
    width: 27.5%;
    /* background-color: blue; */
    display: flex;
    align-content: center;
    justify-content: center;
}

.new-shift-stepper-mobile{
    height: auto;
    width: 100%;
    /* background-color: blue; */
    display: flex;
    align-content: center;
    justify-content: center;
}

.new-shift-stepper-holder{
    height: 312px;
    padding-top: 37.5px;
    width: auto;
    /* background: red; */
    margin: auto;   
}

.new-shift-stepper-holder-mobile{
    width:100%;
    z-index: 2;
}

.new-shift-pages{
    height: 90%;
    width: 72.5%;
    /* background: red; */
}
.new-shift-pages-mobile{
    height: 100%;
    width: 100%;

}

.new-shift-button-holder{
    background: white;
    display: flex;
    width: calc(77.5% - 40px);
    margin: auto;
    align-items: center;
    justify-content: flex-end;
    /* margin-top: 25px; */
    padding: 20px;
    padding-top: 10px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.new-shift-button-holder-mobile{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 25px;
    padding: 20px;
    padding-top: 10px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}


.preffered-jobber-holder-mobile{
    background: white;
    border-radius: 20px;
    padding: 20px;
    max-height: 250px;
    overflow-y: auto;
}







.new-shift-page-title{
    height: 120px;
    font-size: 28px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    color: var(--text-colour);
    width: calc(100% - 70px);
    /* padding-left: 70px; */
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 7.5px;
    /* background-color: red; */
}
.new-shift-page-title-mobile{
    display: none;
}

.simple-blue{
    color: var(--worky-colour);

}

.new-shift-selection-holder{

    /* max-width: 800px; */
    width: calc(100% - 20px);
    margin-right: 20px;
    height: 100%;
    background-color: white;
    /* background-color: red; */
    /* border-radius: 30px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* gap: 70px; */
    justify-content: space-evenly;
}
.new-shift-selection-holder-mobile{

    /* max-width: 800px; */
    width: 100%;
    height: auto;
    /* background-color: white; */
    /* background-color: red; */
    /* border-radius: 30px; */
    display: flex;
    flex-direction: column;
}

.new-shift-selection-holder2{

    /* max-width: 800px; */
    width: calc(100%);
    /* margin-right: 20px; */
    height: 100%;
    /* background-color: white; */
    /* background-color: red; */
    /* border-radius: 30px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 70px; */
    justify-content: space-evenly;
}
.new-shift-selection-holder2-mobile{

    /* max-width: 800px; */
    width: 100%;
    height: 100%;
    /* background-color: white; */
    /* background-color: red; */
    /* border-radius: 30px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 70px; */
    justify-content: space-evenly;
}



.new-shift-daypicker-holder{
    background-color: var(--launch-colour2);
    max-width: 350px;
    margin-top: 10px;
    border-radius: 15px;
    background: white;
    border: 2px solid var(--launch-colour2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}



.datepicker-holder{
    height: auto;
    width: 50%;
    /* background:white; */
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
   
}
.datepicker-holder-mobile{
    height: auto;
    width: 100%;
    /* background:white; */
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
   
}

.isrecurring-holder{
    height: 37px;
    width: 350px;
    margin: auto;
    /* width: 300px; */
    padding-left: 3px;
    padding-right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: red; */
    font-family: "mendl-sans-dusk", sans-serif;
    background: var(--launch-colour2);
    border-radius: 10px;
}
.isrecurring-holder-mobile{
    height: 37px;
    width: 100%;
    margin: auto;
    /* width: 300px; */
    padding-left: 3px;
    padding-right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: red; */
    font-family: "mendl-sans-dusk", sans-serif;
    background: var(--launch-colour);
    border-radius: 10px;
}

.recurring-button{
    height: 31px;
    width: 50%;
    border: none;
    background: none;
    cursor: pointer;
    border-radius: 8px;
    color: var(--launch-highlight);
    font-family: "mendl-sans-dusk", sans-serif;
    font-size: 15px;
}


.recurring-button:hover{
    background: var(--launch-colour2);
    font-family: "mendl-sans-dusk", sans-serif;
}
.recurring-button:disabled:hover{
    /* background: var(--launch-colour2); */
    font-family: "mendl-sans-dusk", sans-serif;
}


.recurring-button:disabled{
    height: 31px;
    width: 50%;
    background: none;
    background: white;
    border: none;
    color: var(--worky-colour);
    font-weight: 500;
    font-family: "mendl-sans-dusk", sans-serif;
}

.timepicker-holder{
    width: auto;
    width: 50%;

    /* height: calc(100% - 120px); */
    padding-top: 0px;
    display:flex;
    flex-direction: column;
    align-items: center;
    /* gap: 10px; */
    /* background:Red; */
}

.timepicker-title{
    height: 30px;
    /* background-color: red; */
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-size: 20px;
    padding-left: 7.5px;
    color: var(--text-colour);
    margin-bottom: -25px;
}
.timepicker-title-mobile{
    height: 30px;
    width: 90%;
    text-align: left;
    /* background-color: red; */
    /* width: 100%; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: left; */
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-size: 20px;
    padding-left: 7.5px;
    color: var(--text-colour);
    margin-bottom: 10px;
    /* margin-bottom: -25px; */
}







/* page 1 styling */


.num-jobbers-holder{
    height: 100%;
    width: calc(50% - 20px);
    /* background-color: green; */
}
.num-jobbers-holder-mobile{
    height: auto;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    /* background-color: green; */
}


.num-jobbers-v2{
    height: 200px;
    max-width: 400px;
    width: calc(100% - 30px);
    background: white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border: 2px solid var(--launch-colour2);
}
.num-jobbers-v2-mobile{
    height: 200px;
    width: 100%;
    background: white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border: 2px solid var(--launch-colour2);
}

.num-jobbers-button{
    height: 55px;
    width: 55px;
    border-radius: 100%;
    border: none;
    background: white;
    font-size: 20px;
    font-family: "mendl-sans-dawn", sans-serif;
    cursor: pointer;
}

.num-jobbers-button:hover{
    background: var(--launch-colour2);

}



.num-jobbers-value{
    height: 100px;
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: none;
    font-size: 80px;
    font-family: "mendl-sans-dawn", sans-serif;
    border-radius: 20px;
    color: var(--text-colour);
    font-weight: 500;
}


.num-jobbers-title{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-size: 20px;
    padding-left: 7.5px;
    color: var(--text-colour);
    text-align: left;
    padding-bottom: 15px;
}





.preferred-jobbers-holder{
    height: 100%;
    max-height: 500px;
    width: calc(50% - 20px);
    /* background-color: green; */
}
.preferred-jobbers-holder-mobile{
    height: auto;
    width: 100%;
    /* background-color: green; */
}


.preferred-jobbers-accordian{
    background: white;
}






















/* TIME PICKER STYLING */





.timepickerv4{
    height: 65px;
    width: 220px;
    display: flex;
    gap: 5px;
    align-items: center;
    font-family: "mendl-sans-dawn", sans-serif;
    font-style: normal;
    color: var(--launch-highlight);
    font-weight: 600;
    background-color: white;

    /* box-shadow: 0 0 3px rgba(0,0,0,0.08); */
    border: 2px solid var(--launch-colour2);
    /* background: var(--launch-colour2); */
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 25px;
    border-radius: 10px;
}


.timepickerv4-mobile{
    background: white;
    width: 90%;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 22.5px;
    border-radius: 20px;
    margin-bottom: 20px;
    font-size: 18px;
}

.time-input{
    height: 40px;
    width: 45px;
    text-align: center;
    border: none;
    border-radius: 10px;
    /* border: 2px solid var(--launch-colour2); */
    font-family: "mendl-sans-dawn", sans-serif;
    font-style: normal;
    color: var(--text-colour);
    font-weight: 500;
    font-size: 15px;
    margin-left: 5px;
    margin-right: 5px;
    /* border: 2px solid white; */
    background: var(--launch-colour2);
}
.time-input-mobile{
    height: calc(40px * 1.5);
    width: calc(50px * 1.5);
    text-align: center;
    border: none;
    border-radius: 10px;
    /* border: 2px solid var(--launch-colour2); */
    font-family: "mendl-sans-dawn", sans-serif;
    font-style: normal;
    color: var(--text-colour);
    font-weight: 600;
    font-size: 20px;
    margin-left: 5px;
    margin-right: 5px;
    /* border: 2px solid white; */
    background: var(--launch-colour2);
}

.time-input:hover{
    background-color: var(--launch-colour);
}

.ampm-button-holder{
    height: 100%;
    /* width: 60px; */
    display: flex;
    align-items: center;
    margin-left: 20px;
}
.ampm-button-holder-mobile{
    height: 100%;
    margin-top: 25px;
    /* width: 60px; */
    display: flex;
    align-items: center;
    /* margin-left: 20px; */
}

.am-button{
    height: 30px;
    width: 50%;
    border: none;
    border-radius: 10px;
    background: none;
    cursor: pointer;
    color: var(--launch-highlight);
    font-family: "mendl-sans-dawn", sans-serif;
    padding-left: 10px;
    padding-right: 10px;
}


.am-button:disabled{
    height: 30px;
    width: 50%;
    background: none;
    border: none;
    /* background: var(--launch-colour2); */

    color: var(--worky-colour);
    font-weight: 700;
    font-family: "mendl-sans-dawn", sans-serif;

}

.pm-button{
    height: 30px;
    width: 50%;
    border: none;
    background: none;
    border-radius: 10px;
    cursor: pointer;
    color: var(--launch-highlight);
    font-family: "mendl-sans-dawn", sans-serif;
    padding-left: 10px;
    padding-right: 10px;
}


.pm-button:disabled{
    height: 30px;
    width: 50%;
    background: none;
    /* background: var(--launch-colour2); */
    border: none;
    color: var(--worky-colour);
    font-weight: 700;
    font-family: "mendl-sans-dawn", sans-serif;

}

.pm-button:hover{
    background: var(--launch-colour2);
}

.am-button:hover{
    background: var(--launch-colour2);
}



.new-shift-back{
    height: 47.5px;
    width: 111px;
    background: none;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    color: var(--text-colour);
    font-family: "mendl-sans-dusk", sans-serif;

}

.new-shift-back:hover{
    /* background: var(--launch-colour); */
    font-weight: 600;

}

.new-shift-next{
    height: 47.5px;
    width: 111px;
    background: var(--worky-colour);
    border: none;
    border-radius: 15px;
    color: white;
    cursor: pointer;
    font-family: "mendl-sans-dusk", sans-serif;

}

.new-shift-next:disabled{
    background: var(--launch-colour);
}

.new-shift-next:disabled:hover{
    background: var(--launch-highlight);
    border: 2px solid var(--launch-highlight);
    font-weight: 500;
}

.new-shift-next:hover{
    background: var(--hightlight-colour);
    font-weight: 600;
}


.reciept-v2{
    height: 100%;
    width: 100%;
    /* background: red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 7px;
}
.reciept-v2-mobile{
    height: 100%;
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    overflow: hidden;
    /* align-items: center; */
    /* justify-content: center; */
    /* gap: 10px; */
}

.reciept-info-holder{
    height: 50px;
    width: 100%;
    background: var(--launch-colour2);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "mendl-sans-dusk", sans-serif;
    color: var(--text-colour);
}
.reciept-info-holder-mobile{
    height: 50px;
    width: 100%;
    /* box-shadow: 0 0 3px rgba(0,0,0,0.08); */
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "mendl-sans-dusk", sans-serif;
    color: var(--text-colour);
}

.reciept-item{
    height: 100%;
    width: auto;
    padding-left: 25px;
    display: flex;
    align-items: center;
    font-weight: 500;
}
.reciept-value{
    font-weight: 700;
    height: 100%;
    width: auto;
    padding-right: 25px;
    display: flex;
    align-items: center;
    font-family: "mendl-sans-dusk", sans-serif;
    color: var(--text-colour);
}

.reciept-col1{
    /* background-color: red; */
    height: 100%;
    width: 65%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.reciept-col1-mobile{
    /* background-color: red; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
}

.reciept-col2{
    /* background-color: green; */
    height: 90%;
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 10px;
}
.reciept-col2-mobile{
    /* background-color: green; */
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* gap: 10px; */
}


.reciept-accordian::before{
    display: none;
    
}




.rdp-row:hover .rdp-day{
    font-style: italic;
    background: var(--launch-colour2);
}

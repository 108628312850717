:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}


.jobber-onboarding{
    width: 100%;
    display:flex;
    flex-direction: column;
    gap: 30px;
}

.jobber-onboarding-state-holder{
    height: 80px;
    width: 100%;
    /* background:red; */
    /* padding-top: -200px; */
}

.card-info-de-progress{
    /* background: green; */

    margin-left: auto;
    margin-right: auto;

    /* margin-top: -55px; */
    width: 250px;

        height: 65px;

        /* background: blue; */
        /* margin-top: -25px; */
    margin-top: 10px;
        display: flex;
        /* flex-direction: row; */
    
        align-items: center;
        transition: 0.5s ease-in-out;
    


}


.jobber-form {
    height: auto;
    width: 90%;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 100;
    font-style: normal;
    color: var(--text-colour);
    transition: all ease-in-out 0.12s;
    display: flex;
    flex-direction: column;
    transition: 0.5s ease-in-out;

    /* background: white; */
    /* background: red; */
    margin: 0 auto;
  }


.jobber-input{
    height: 40px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    text-indent: 20px;

    border: 2px solid var(--launch-colour);
}

.jobber-input:hover{
    border: solid 2px var(--launch-highlight);
}

.page1{
    width: 100%;
    /* width: 100%; */
    /* background:red; */
}

.page2{
    /* width: 100%; */
    /* background: blue; */
}

.page3{
    width: 100%;
    /* background: green; */
}

#label3{
    padding-top: 15px;
}

.jobber-label{
    height: 25px;
    /* width: 100%; */
    text-align: left;
    text-indent: 20px;
}


.jobber-dob-holder{
    height: 80px;
    /* width: 100%; */
    padding-left: 15px;
    padding-top: 7.5px;
    /* background: red; */
}

.jobber-dob-field-label-holder{
    height: 15px;
    /* background: red; */
    width: 100%;
}

#jobber-dob-label{
    width: 77.5px;
    margin-left: 5px;
    margin-right: 5px;
    float: left;
    height: 12.5px;
    padding: none;
    border-radius: 0px;
    text-indent: 15px;
    border: none;
    text-align: left;
    font-size: 12.5px;
    /* background: red; */
}

#jobber-dob-field{
    width: 70px;
    margin-left: 5px;
    margin-right: 5px;
    float: left;
}

#jobber-workstatus{
    height: 45px;
    width: 93.5%;
}






.ji-days {
    height: 50px;
    width: 100%;
    border: none;
    padding-left: 15px;
    background:none;
    /* overflow-x: scroll; */
    /* flex-direction: column; */

    padding-bottom: 10px;

}

.ji-days:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.ji-dayvalue {
    text-align: center;
    height: 15px;
    width: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: solid 2px var(--launch-colour);
    background: white;
    border-radius: 13px;
    margin: 5px;
    display: inline-block;
    overflow: auto;
    float: left;
}
.ji-dayvalue:checked {
    border: solid 2px var(--worky-colour);

}

.ji-dayvalue:hover{
    border: solid 2px var(--launch-highlight);
}



/*  */

.ji-times {
    height: 50px;
    width: 100%;
    border: none;
    padding-left: 15px;
    background: none;
    padding-bottom: 10px;
    margin-bottom: 10px;

}

.ji-times:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.ji-timevalue {
    text-align: center;
    height: 15px;
    width: 56px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: solid 2px var(--launch-colour);
    background: white;
    border-radius: 13px;
    margin: 5px;
    display: inline-block;
    overflow: auto;
    float: left;
}
.ji-timevalue:checked {
    border: solid 2px var(--worky-colour);

}

.ji-timevalue:hover{
    border: solid 2px var(--launch-highlight);
}











#special-skills-field{
    width: 93.5%;
    margin: 0 auto;
}


  #ji-streetname{
    width: calc(90% - 40px);
    float: left;
  }
  #ji-unit{
    width: calc(12% - 12px);
    float: left;
    margin-left: 10px;
  }

  #ji-city{
    margin-top: 10px;
    width: calc(50% - 20px);
    float:left;
  }

  #ji-postalcode{
    margin-top: 10px;
    width: calc(50% - 20px);
    float:left;
    margin-left: 10px;
  }

  #ji-province{
    margin-top: 10px;
    height: 45px;
    width: calc(50% - 5px);
    float:left;
  }

  #ji-country{
    margin-top: 10px;
    width: calc(50% - 5px);
    float:left;
    height: 45px;
    margin-left: 10px;
  }



  .ji-tempholder{
    height: auto;
    width: 93.5%;
    margin: 0 auto;
    padding-bottom: 15px;
  }


/* 
  .page3{
    height: 340px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 10px;

  } */

/* 
#firstname{
    width: 42.5%;
    float: left;
    margin-left: 4%;
    margin-right: 2.5%;
}
#lastname{
    width: 42.5%;
    float: left;

} */
#dob{
    margin-top: 20px;
    width: 90%;
    height: 45px;
}
.jobber-select{
    height: 13px;
    width:13px;
    float: right;
    margin-right: 10px;
}




/* 

#sex{
    width: 42.5%;
    float: left;
    margin-left: 4%;
    margin-right: 2.5%;
    height: 50px;
} */

.submit-button{
    height: 40px;
    width: 110px;
    border-radius: 20px;
    float: right;
    background: var(--worky-colour);
    color: var(--launch-colour);
    border: 2px var(--worky-colour) solid;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 100;
    font-style: normal;
    transition: all ease-in-out 0.12s;
    margin-bottom: 20px;
    margin-right: 25px;
    margin-top: 40px;
}


.list{
    height: 21.5px;
    padding-left: 10px;
    padding-right: 10px;

    white-space: nowrap;

    border: 2px solid var(--worky-colour);
    border-radius: 15px;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 4px;
    margin-bottom: 4px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 200;
    font-style: normal;
    font-size: 15px;
    padding-top: 3px;
    padding-bottom: 1px;

}
.total-list{
    width: 100%;
    height: 45px;
    margin: 0;
    padding: 0;
    /* margin-left: -10px; */
    /* background: red; */
}


.next{
    height: 40px;
    width: 110px;
    border-radius: 20px;
    float: right;
    background: var(--worky-colour);
    color: white;
    border: 2px var(--worky-colour) solid;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 100;
    font-style: normal;
    transition: all ease-in-out 0.12s;
    margin-bottom: 20px;
    margin-right: 25px;
    margin-top: 30px;
}
.next:hover{
    background: var(--hightlight-colour);
    border: var(--hightlight-colour);
}

.prev{
    height: 40px;
    width: 110px;
    border-radius: 20px;
    float: left;
    background: var(--launch-colour);
    color: var(--dark-text);
    border: 2px var(--launch-colour) solid;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 100;
    font-style: normal;
    transition: all ease-in-out 0.12s;
    margin-bottom: 20px;
    margin-left: 25px;
    margin-top: 40px;
}

.prev2{
    height: 40px;
    width: 110px;
    border-radius: 20px;
    float: left;
    background: var(--launch-colour);
    color: var(--dark-text);
    border: 2px var(--launch-colour) solid;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 100;
    font-style: normal;
    transition: all ease-in-out 0.12s;
    margin-bottom: 20px;
    margin-left: 25px;
    margin-top: 25px;
}

.prev:hover{
    background: #b3b3b3;
    border: #b3b3b3;
}
.prev2:hover{
    background: #b3b3b3;
    border: #b3b3b3;
}


.prev-employ-holder{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 5px;
}

#prev-employ{
    width: 92%;
}

.workstatus-holder{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

#ji-sex{
    width: 93.5%;
    height: 45px;
}




.radio-holder{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.jobber-label-2{
    /* height: 25px; */
    /* max-width: 202px; */
    /* min-width: 50px; */
    /* position:fixed; */
    width: auto;
    padding-right: 10px;
    text-align: left;
    text-indent: 20px;
    font-weight: 600;
    font-size: 15px;
    color: var(--text-colour);
    padding-bottom: 5px;
    /* background: white; */
}

.jobber-submission-data{    
    height: 50px;
    width: 100%;
    font-size: 12px;
    /* background:red; */
}

.ji-statetextholder{    
    height: auto;
    width: 232px;
    margin-top: 70px;
    display: flex;
    justify-content:space-between;

    padding-left: 10px;
}


.ji-progress-text{
    width: 66px;



    float: left;
    text-align: center;
    /* background: var(--launch-highlight); */
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
}

.ji-progress-bar-inner-1{
    height: 3px;
    width: 90px; /*55,135,190*/
    background: var(--worky-colour);
    align-self: center;
    transition: 0.5s ease-in-out;
}
.ji-progress-bar-inner-2{
    height: 3px;
    width: 170px; /*55,135,190*/
    background: var(--worky-colour);
    align-self: center;
    transition: 0.5s ease-in-out;
}



.ji-submit-success-screen{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ji-submit-success-icon{
    height: 150px;
    width: 150px;
    background-image: url('/public/success-check.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.ji-submit-nosuccess-icon{
    height: 150px;
    width: 150px;
    background-image: url('/public/unsucessful-cross.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.ji-submit-success-title{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: var(--text-colour);
    font-size: 50px;
}

.ji-submit-success-subtext{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 200;
    font-style: normal;
    color: var(--text-colour);
    font-size: 17.5px;
}










.shifttimes-holder{
    height: auto;
    padding-left: 20px;
    padding-bottom: 20px;


}




  .dow-holder{
    height: auto;
    width: calc(100% - 40px);
    padding-bottom: 20px;
    padding-left: 20px;

    /* background: red; */
  }
:root {
  --primary-color: #2C68BD;
  --click-color: #3C528C;
  --text-color:#333;
  --background-color:#f1f1f1;
  --desktop-block-width:515px;
  --desktop-block-margin:295px;
}

body{
  background-color: var(--background-color);
  padding-left:5%;
  padding-right:5%;
  padding-bottom: 150px;
  margin-top: 25px;
  text-align: center;
}


.App-link {
  color: #61dafb;
}



.page_content.mobile{
  margin-top: 80px;
  padding-top: 0.5px;
  height: auto;
  padding-bottom: 75px;
  color: var(--text-color);
  text-align: left;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}





.previousSR_holder.mobile{
  height: auto;
  padding-bottom: 25px;
}

.previousSR_button.mobile{
  margin-top: 20px;
  height:35px;
  width: 85%;
  background: var(--primary-color);
  color: white;
  font-size: 14px;
  border-radius: 15px;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  border: none;
  transition: 0.02s ease-in-out;
}

.previousSR_list.mobile{
  height: 85px;
  width: 85%;
  background:white;
  border-radius: 20px;
  margin-left: 7.5% ;
  margin-top: 10px;
  padding-top: 10px;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
  transition: 0.2s ease-in-out;
}

.previousSR_date.mobile{
  height: 35px;
  width: 95%;
  margin-left: 2.5%;
  background: var(--background-color);
  font-size: 14px;
  border-radius: 15px;
  border: none;
  margin-bottom: 5px;
}

.page_content.mobile{
  height: 500px;
  width: 100%;
  background: red;
}

.left_holder.mobile{
  height: auto;
  width: auto;
  
}


.right_holder.mobile{
  height: auto;
  width: auto;

}






.states.mobile{
  height: auto;
  width: 100%;
  padding-bottom: 25px;
  
}


.states_title.mobile{
  text-indent: 60px;
  text-align: left;
}





.state_holder.mobile{
  height:230px;
  margin: 0 auto;
  width: 85%;
  background: white;
  border-radius: 20px;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
}



.state.mobile{
  width: 100%;
  float: left;

  padding-top:20px;
  padding-bottom: 20px;
  text-align: center;
}


.state_quantafier.mobile{
  height:40px;
  width: 100%;
  float: left;
}


.state_counter.mobile{
  height: 40px;
  width: 114px;
  text-align: center;
  color: var(--text-color);
  font-size: 14px;
  border-radius: 40px;
  background-color:var(--background-color);
  margin: 0 auto;
}

.minus.mobile{
  height: 33px;
  width: 33px;
  border-radius: 33px;
  margin-top: 3.5px;
  margin-left: 4px;
  background-color: #fff;
  float: left;
  text-align: center;
  border: none;
}
.minus:active.mobile{
  background-color:var(--primary-color);
  color: #fff;
}
.count.mobile{
  height: 35px;
  padding-top: 8px;
  width: 40px;
  float: left;
  text-align: center;
  border: none;
}
.plus.mobile{
  height: 33px;
  width: 33px;
  border-radius: 33px;
  margin-top: 3.5px;
  margin-right: 4px;
  background-color:#fff;
  float: left;
  text-align: center;
  border: none;

}
.plus:active.mobile{
  background-color:var(--primary-color);
  color: #fff;
}









.calender_holder.mobile{
  height: auto;
  width: 100%;
  text-align: left;
  /*text-indent: 60px;*/
  padding-bottom: 25px;

}


.calender.mobile{
  height: 335px;
  background:white;
  width: 85%;
  margin-left: 7.5%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  overflow: hidden;
}




.timing_holder.mobile{
  height: auto;
  width: 100%;
  text-align: left;
  text-indent: 60px;
  padding-bottom: 25px;
}


.timing.mobile{
  padding-top: 25px;
  height: auto;
  padding-bottom: 25px;
  width: 85%;
  margin: 0 auto;
  background: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}



.next.mobile{
  height: 50px;
  width: 100px;
  color: #fff;
  text-align: center;
  border-radius: 15px;
  border: none;
  font-size: 15px;
  background: var(--primary-color);
  float:right;
  margin-right: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: 0.02s ease-in-out;
}

.next.mobile:active{
  background: #333;
  color: #f1f1f1;
}

.sr_que_holder.mobile{
  text-align: left;
  text-indent: 30px;
  height: 250px;
  width: 85%;
  margin-left: 7.5%;
  padding-bottom: 25px;
}

.sr_que.mobile{
  height: 231px;
  width: 100%;
  border-radius: 25px;
  background: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 25px;
}

























.previousSR_holder.desktop{
  display: none;
}



.left_holder.desktop{
  height: auto;
  float: left;
  width: var(--desktop-block-width);
  margin-left: 110px;
  display: block;
}

.right_holder.desktop{
  height: 600px;
  float: right;
  width: var(--desktop-block-width);
  display: block;
}


.states.desktop{
  text-align: left;
  height: 150px;
  width: 100%;

}

.state_holder.desktop{
  height: 100px;
  width: 100%;
  border-radius: 25px;
  background: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);

}

.calender_holder.desktop{
  height: 500px;
  width: 100%;
  margin-top: 10px;
  text-align: left;
  /*text-indent: 30px;*/
}


.calender.desktop{
  height: 480px;
  width: 100%;
  background: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  overflow: hidden;
}



.timing_holder.desktop{
  text-align: left;

  height: auto;
  width: 100%;
}

.timing.desktop{
  padding-top: 25px;
  padding-bottom: 25px;
  width: 100%;
  border-radius: 25px;
  background: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 25px;
  overflow: auto;
  height: auto;
  max-height: 586px;
  min-height: 50px;
}

.back_next_holder.desktop{
  height: 50px;

  padding-right: 20px;
  padding-left: 20px;
}


.next.desktop{
  height: 50px;
  width: 100px;
  color: #fff;
  text-align: center;
  border-radius: 15px;
  border: none;
  font-size: 15px;
  background: var(--primary-color);
  float:right;
  
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
  transition: 0.02s ease-in-out;
}
.next.desktop:active{
  background: #333;
  color: #f1f1f1;
}

.back.desktop{
  height: 50px;
  width: 100px;
  color: #fff;
  text-align: center;
  border-radius: 15px;
  border: none;
  font-size: 15px;
  background: var(--primary-color);
  float:left;
  margin-right: 20px;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
  transition: 0.02s ease-in-out;
}
.back.desktop:active{
  background: #333;
  color: #f1f1f1;
}

.back.desktop:disabled{
  background: #e1e1e1;
  color: #333;
  box-shadow: none;
}


.sr_que_holder.desktop{
  text-align: left;
  height: 250px;
  width: 100%;
  padding-bottom: 25px;
}
.sr_que{
  height: 150px;
  width: 100%;
  background: white;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 15px;

}


.state.desktop{
  width: 100%;
  float: left;
  padding-top:15px;
  padding-bottom: 15px;
  text-align: center;

}


.state_quantafier.desktop{
  height:40px;
  width: 100%;
  float: left;

}


.minus.desktop{
  height: 33px;
  width: 33px;
  border-radius: 33px;
  margin-top: 3.5px;
  margin-left: 4px;
  background-color: #fff;
  float: left;
  text-align: center;
  border: none;
}
.minus:active.desktop{
  background-color:var(--primary-color);
  color: #fff;
}

.count.desktop{
  height: 35px;
  padding-top: 8px;
  width: 40px;
  float: left;
  border:none;
}

.plus.desktop{
  height: 33px;
  width: 33px;
  border-radius: 33px;
  margin-top: 3.5px;
  margin-right: 4px;
  background-color:#fff;
  float: right;
  text-align: center;
  border: none;

}
.plus:active.desktop{
  background-color:var(--primary-color);
  color: #fff;
}

.state_counter.desktop{
  height: 40px;
  width: 114px;
  text-align: center;
  color: var(--text-color);
  font-size: 14px;
  border-radius: 40px;
  background-color:var(--background-color);
  margin: 0 auto;
}





.responsive_calender.mobile{
  font-size: 9.5px;
  border-radius: 20px;
  box-shadow: none;
  margin: 0 auto;
}

.responsive_calender.desktop{
  font-size: 13px;
  box-shadow: none;
  width: 85%;
  margin: -5px auto 0px;
  padding-top: 20px;
  height: 100px;
}

/* time picker test */

.test{
    margin-left: 100px;
}

.timepicker-container{
    height: 62.5px;
    width: 200px;
    background: white;
    border-radius: 20px;
    border: 2px solid var(--launch-colour);
    display: flex;
    align-items: center ;
    justify-content: space-evenly;
    cursor: pointer;
}
.timepicker-container:hover{
    border: 2px solid var(--launch-highlight);

}

.timepicker-lefticon{
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.timepicker-middle{
    height: 62.5px;
    width: 110px;
    /* background: grey; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--launch-highlight);
    font-size: 18px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
}


.timepicker-dropdown{
    height: 220px;
    width: 115px;
    position: absolute;
    background: white;
    transform: translate(45px,152px);
    border-radius: 20px;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
    cursor: default;
    display: flex;
    justify-content: space-evenly;
}


.timepicker-dropdown-time-column{
    height: 200px;
    width: 50px;
    /* background: red; */
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
    margin-top: 10px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.timepicker-dropdown-time-column::-webkit-scrollbar {
    display: none;
  }


.timepicker-dropdown-time{
    width: 85%;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    color: var(--text-colour);
    font-weight: 500;
    border: none;
    background: none;
}
.timepicker-dropdown-time:hover{
    color: var(--worky-colour);
    font-weight: 600;
}




.timepicker-dropdown-ampm-column{
    height: 80px;
    width: 35px;
    /* background: blue; */
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.timepicker-dropdown-ampm{
    width: 35px;
    height: 25px;
    border: none;
    background:white;
    border: 1px solid var(--launch-highlight);
    color: var(--launch-highlight);
    border-radius: 7px;
    cursor: pointer;
}
.timepicker-dropdown-ampm:hover{
    background:var(--launch-highlight);
    border: 1px solid var(--launch-highlight);
    color: white;
}




.testt{
    height: 100px;
    width: 250px;
    background: white;
    margin: auto;
    padding-top: 10px;   
}

.timepicker2{
    margin: auto;
    margin-top: 10px;
    margin-bottom: 25px;
    height: 60px;
    width: 215px;
    border-radius: 10px;
    display: flex;
}

.tt2-hour{
    height: 58px;
    width: 58px;
    border: none;
    border-radius: 10px;
    background: var(--launch-colour2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-colour);
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

.tt2-col{
    height: 60px;
    /* width: 20px; */
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: 600;
    color: var(--launch-highlight);
    margin-top: -3px;
    margin-left: 5px;
    margin-right: 5px;
}

.tt2-mins{
    height: 58px;
    width: 58px;
    border: none;
    text-align: center;
    border-radius: 10px;
    background: var(--launch-colour2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-colour);
    font-size: 24px;
    font-weight: 600;
}

.tt2-ampm{
    margin-left: 15px;
    height: 60px;
    width: 60px;
    border-radius: 10px;
    background: var(--launch-colour2);
}
.tt2-am{
    height: 30px;
    width: 60px;
    background: none;
    border: none;
    border-radius: 10px;
    background: var(--launch-colour2);
    cursor: pointer;
}

#ampm-active{
    background:var(--worky-colour);
    border: 1px solid var(--worky-colour);
    color: white;
    font-weight: 600;
}

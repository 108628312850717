:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}


.org-app{
    min-height: 100vh;
    width: 100%;
    background: var(--launch-colour2);
}




.topnav-holder{
    height: 30px;
    background: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    transition: 0.02s ease-in-out;
}

.topnav-cushion-left{
    height: 30px;
    width: 7.5%;
    background: var(--launch-colour2);
    border-top-right-radius: 15px;
}

.topnav-cushion-right{
    height: 30px;
    width: 7.5%;
    background: var(--launch-colour2);
    border-top-left-radius: 15px;
}


  
  .topnav-logo:hover {
    color: var(--hightlight-colour);
  }
  

  
  
  .topnav {
    height: 45px;
    width: 85%;
    background: white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .topnav-logo {
    height: 45px;
    width: auto;
    background: none;
    border: none;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 22px;
    color: var(--worky-colour);
    user-select: none;
    padding-left: 20px;
    cursor: pointer;
    /* background: blue; */
  }
  .company-name{
    color: var(--text-colour);
    font-size: 17px;
    font-weight: 500;
    /* background: red; */
  }

  .topnav-option-holder {
    height: 45px;
    width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .topnav-option {
    height: 45px;
    border: none;
    background: none;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    color: var(--text-colour);
    cursor: pointer;
  }


  .topnav-option-hover{
    border: none;
    font-weight: 600;
    color: var(--worky-colour);
    border-bottom: solid var(--worky-colour) 2px;
    height: 44px;
    cursor: pointer;
    background: none;
    font-family: "mendl-sans-dusk", sans-serif;
    /* width: calc(500px / 4); */
  }
  
  .topnav-option:hover {
    font-weight: 600;
    color: var(--worky-colour);
    border-bottom: solid var(--worky-colour) 2px;
    height: 44px;
  }
  
  .topnav-right {
    height: 45px;
    width: 155px;
    padding-right: 10px;
    /* background: red; */
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    align-items: center;
  }

  .topnav-right-static{
    height: 95px;
    width: 155px;
    padding-right: 10px;
    /* background: red; */
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content:flex-end;
    align-items: center;
  }


  .topnav-right-hover{
    /* height: 40px; */
    display:none;
    height: 75px;
    width: 155px;
    padding-bottom: 15px;
    padding-top: 15px;
    z-index: 1000000;
  }


  
  .topnav-right:hover {
    height: 95px;
    background: white;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;

  }

  .topnav-right:hover .topnav-right-hover{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 5px;
    justify-content: center;
    align-items: center;
    height: 20px;
  }

  .topnav-right:hover .topnav-right-static{
    /* height: 95px;   */
    /* padding-bottom: 5px;   */
    position: relative;
    /* margin-top: 80px; */
    /* background: red; */
  }
  
  .topnav-right-signout{
    height: 35px;
    width: 131px;
    background: var(--launch-colour2);
    border: none;
    /* border: solid var(--launch-highlight) 2px; */
    margin-left: 10px;
    margin-top: -15px;
    border-radius: 10px;
    color: var(--text-colour);
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    cursor: pointer;
  }

  .topnav-right-signout:hover{
    color: white;
    background: var(--worky-colour);
    border: solid white 2px;
  }
  .topnav-right-signout:active{
    color: white;
    background: var(--hightlight-colour);
  }




  .topnav-right-name{
    /* height: 100%; */
    /* background-color: blue; */
    max-width: 120px;
    display: flex;
    justify-self: center;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    color: var(--text-colour);
    padding-right: 7.5px;
    cursor:default ;
  }

  .topnav-right-icon{
    height: 33px;
    width: 33px;
    background-image: url('/public/org-icon.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 100%;
  }

  .topnav-org-icon{
    max-width: 29px;
    max-height: 29px;
    border-radius: 50px;
    margin: auto;
    margin-top:2px;
}

















.srstatus-container{
    height: 275px;
    width: auto;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
}
.srstatus-container-hp{
    height: 275px;
    width: auto;
    max-width: 47.5%;
    margin-left: 10%;
    /* margin-left: auto; */
    /* margin-right: auto; */
    /* margin-top: 70px; */
    /* background: red; */
}
.srstatus-container-hp-mobile{
    height: 275px;
    width: 100%;
    /* background: red; */
}


.srstatus-container-mobile{
    height: 275px;
    width: auto;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
    /* background: red; */
}


.srstatus-title{
    height: 35px;
    width: 100%;
    font-size: 35px;
    text-align: left;
    text-indent: 50px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    padding-bottom: 10px;
}
.srstatus-title-hp{
    height: 35px;
    width: 100%;
    font-size: 35px;
    text-align: left;
    text-indent: 50px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.srstatus-title-mobile{
    height: 35px;
    width: 90%;
    font-size: 35px;
    /* margin-left: -2.5%; */
    text-align: left;
    /* text-indent: 50px; */
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    padding-bottom: 10px;
    /* background: blue; */
    padding-top: 30px;
    padding-bottom: 15px;
    display: flex;
    padding-left: 5%;
}
.srstatus-title-current-mobile{
    height: 35px;
    width: 90%;
    font-size: 35px;
    /* margin-left: -2.5%; */
    text-align: left;
    /* text-indent: 50px; */
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    padding-bottom: 10px;
    /* background: blue; */
    padding-top: 30px;
    padding-bottom: 15px;
    display: flex;
    padding-left: 0%;
}

.srstatus-holder{
    height: 190px;
    padding: 20px;
    width: auto;
    position: absolute;
    max-width: calc(80% - 40px);
    /* background: white; */
    border-radius: 35px;
    display: flex;
    overflow-y: scroll;
    flex-wrap:nowrap;
    align-items: center;
    gap: 20px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    /* background: red; */
    scroll-behavior: smooth;
}
.srstatus-holder-hp{
    height: 190px;
    padding: 20px;
    width: auto;
    /* position: absolute; */
    /* max-width: calc(80% - 40px); */
    max-width: 100%;
    /* background: white; */
    display: flex;
    overflow-y: scroll;
    flex-wrap:nowrap;
    align-items: center;
    gap: 20px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    /* background: red; */
    scroll-behavior: smooth;
    padding-top: 5px;
    /* background: green; */
    /* border-radius: 50px; */
}

.srstatus-holder-mobile{
    height: 190px;
    /* padding: 20px; */
    width: auto;
    position: absolute;
    /* max-width: calc(80% - 40px); */
    max-width: 87.5%;
    margin-left: 5%;
    /* background: white; */
    /* border-radius: 35px; */
    display: flex;
    overflow-y: scroll;
    flex-wrap:nowrap;
    align-items: center;
    gap: 20px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    /* margin-bottom: 10px; */
    /* background: red; */
    scroll-behavior: smooth;
}



.srstatus-left{
    position: absolute;
    z-index: 1;
    left: 10%;
    margin-top: 90px;
    height: 50px;
    width: 50px;
    border: none;
    background: var(--launch-colour);
    border-radius: 100%;
    background-color: white;
    box-shadow: 0 0 2px rgba(0,0,0,0.5);
    color: var(--worky-colour);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.srstatus-left-hp{
    position: absolute;
    z-index: 1;
    left: 10%;
    margin-top: 80px;
    height: 50px;
    width: 50px;
    border: none;
    background: var(--launch-colour);
    border-radius: 100%;
    background-color: white;
    box-shadow: 0 0 2px rgba(0,0,0,0.5);
    color: var(--worky-colour);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.srstatus-left-mobile{
    position: absolute;
    z-index: 1;
    left: 3%;
    margin-top: 70px;
    height: 50px;
    width: 50px;
    border: none;
    background: var(--launch-colour);
    border-radius: 100%;
    background-color: white;
    box-shadow: 0 0 2px rgba(0,0,0,0.5);
    color: var(--worky-colour);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.srstatus-right{
    position: absolute;
    z-index: 1;
    right: 10%;
    margin-top: 90px;
    height: 50px;
    width: 50px;
    border: none;
    background: var(--launch-colour);
    border-radius: 100%;
    background-color: white;
    box-shadow: 0 0 2px rgba(0,0,0,0.5);
    color: var(--worky-colour);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.srstatus-right-hp{
    position: absolute;
    z-index: 1;
    right: 42.5%;
    margin-top: 80px;
    height: 50px;
    width: 50px;
    border: none;
    background: var(--launch-colour);
    border-radius: 100%;
    background-color: white;
    box-shadow: 0 0 2px rgba(0,0,0,0.5);
    color: var(--worky-colour);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.srstatus-right-mobile{
    position: absolute;
    z-index: 1;
    right: 3%;
    margin-top: 70px;
    height: 50px;
    width: 50px;
    border: none;
    background: var(--launch-colour);
    border-radius: 100%;
    background-color: white;
    box-shadow: 0 0 2px rgba(0,0,0,0.5);
    color: var(--worky-colour);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}


.srstatus-holder::-webkit-scrollbar {
    display: none;
  }



.srstatus-left:hover{
    background: var(--launch-colour);
}
.srstatus-right:hover{    
    background: var(--launch-colour);
}
.srstatus-left-hp:hover{
    background: var(--launch-colour);
}
.srstatus-right-hp:hover{    
    background: var(--launch-colour);
}




.srstatus{
    height: 175px;
    width: 210px;
    flex: 0 0 auto;
    border-radius: 35px;
    background:white;
    transition: 0.1s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 3px solid white;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);

}

#sr-assigned:hover {
    border: 3px solid #b2df8f;
    cursor: pointer;

}
#sr-assigned:hover .srstatus-ratio{
    background: #b2df8f;
    cursor: pointer;

}
#sr-inprogress:hover{
    border: 3px solid #ffe176;
    cursor: pointer;

}
#sr-inprogress:hover .srstatus-ratio{
    background: #ffe176;
    cursor: pointer;

}
#sr-ongoing-back:hover{
    border: 3px solid #b2df8f;
    cursor: pointer;

}

.srstatus:hover{
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    cursor: pointer;

}

.srstatus-inner{
    height: 165px;
    width: 160px;
    /* background: white; */
    border-radius: 30px;
    /* background: blue; */
    cursor: pointer;

}


.srstatus-date{
    height: 30px;
    width: 100%;
    padding-top: 20px;
    font-size: 28px;
    color: var(--text-colour);
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;

}

.srstatus-time{
    height: 30px;
    width: 100%;
    font-size: 18px;
    color: var(--text-colour);
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;

}

.srstatus-ratio-holder{
    height:70px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.srstatus-ratio{
    height: 50px;
    width: 90px;
    border-radius: 50px;
    background: var(--launch-colour2);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: var(--text-colour);
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    cursor: pointer;

}




.assigned-jobber{
    height: 40px;
    width: calc(50% - 5px);
    /* width: auto; */
    background: var(--launch-colour2);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.assigned-jobber-icon{
    height: 30px;
    width: 30px;
    border-radius: 100%;
    /* margin-left: 15px; */
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--launch-colour);
}

.assigned-jobber-name{
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    /* background: green; */
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    color: var(--text-colour);
}





.srstatus-modal{
    height: auto;
    width: 400px;
    border-radius: 30px;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding-bottom: 25px;
}

.srstatus-modal-mobile{
    height: auto;
    width: 90%;
    border-radius: 30px;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding-bottom: 25px;
}

.srstatus-modal-title{
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 20px;
    color: var(--text-colour);
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    /* text-align: left; */
    /* text-indent: 35px; */
}
.srstatus-modal-details{
    height: auto;
    width: 90%;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5%;
    /* background: red; */
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.srstatus-modal-detail{
    height: 45px;
    width: 90%;
    padding-right: 20px;
    padding-left: 20px;
    background: var(--launch-colour2);
    border-radius: 15px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    color: var(--text-colour);

    display: flex;
    justify-content: space-between;
}

.modal-assigned-jobbers{
    height: 70%;
    display: flex;
    width: auto;
    align-items: center;
    justify-content: right;
    overflow-x: hidden;
    /* background: red; */
    padding-left: 10px;
    padding-right: 10px;
    /* background: var(--launch-colour); */
    border-radius: 12px;
    /* overflow: scroll; */ 
}

.srstatus-assigned-jobbers-holder{
    height: auto;
    max-height: 120px;
    overflow-y: scroll;
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    /* align-items: flex-start; */
    gap: 5px;
    flex-wrap: wrap;
}

/* Hide scrollbar for WebKit browsers */
.srstatus-assigned-jobbers-holder::-webkit-scrollbar {
    width: 0.5em; /* Set the width of the scrollbar */
}

.srstatus-assigned-jobbers-holder::-webkit-scrollbar-thumb {
    background-color: transparent; /* Set the color of the thumb */
}

.srstatus-assigned-jobbers-holder::-webkit-scrollbar-track {
    background-color: transparent; /* Set the color of the track */
}



#modal-detail-heading{
    font-weight: 400;
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;

}
.srstatus-modal-actions{
    height: 106px;
    width: 90%;
    margin: auto;
    /* background: red; */
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.modal-action{
    height: 44px;
    width: calc(50% - 5px);
    border-radius: 15px;
    background:var(--launch-colour2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    border: 4px solid var(--launch-colour2);
    transition: 0.03s ease-in-out;
    color: var(--text-colour);
    /* background: red; */
}

#modal-action-add:hover{
    background:#4f9eff;
    border: 2px solid #4f9eff;
    color:white;
    font-weight: 500;
}
#modal-action-extend:hover{
    background:#4f9eff;
    border: 2px solid #4f9eff;
    color:white;
    font-weight: 500;
}
#modal-action-report:hover{
    border: 2px solid #ff8282;
    background: #ff8282;
    color:white;
    font-weight: 500;
}
#modal-action-cancel:hover{
    background:var(--launch-colour);
    border: 2px solid var(--launch-colour);
    font-weight: 500;
}















.org-bottomrow{
    height: 320px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;


    /* background: red; */
}

.org-bottomrow-mobile{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    /* height: 500px; */
    /* background: blue; */
}

.org-toprow{
    height: 600px;
    width: 80%;
    position: absolute;
    /* margin-left: auto;
    margin-right: auto;
    margin-top: 75px; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    /* background: red; */
}

.org-toprow-mobile{
    margin-top: 70px;
    /* height: 600px; */
    /* background: red; */
}






.srnew-container{
    height: calc(100% - 20px);
    width: calc(50% - 5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.srnew-container-mobile{
    /* height: calc(100% - 20px); */
    /* width: calc(50% - 5px); */
    height: 235px;
    width: 100%;
    padding-top: 30px;
    /* padding-bottom: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}
.srprev-container-mobile{
    /* height: calc(100% - 20px); */
    /* width: calc(50% - 5px); */
    height: 235px;
    width: 100%;
    margin-top: -40px;
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}


.srnew-title{
    height: 35px;
    width: 100%;
    font-size: 35px;
    text-align: left;
    text-indent: 50px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    padding-bottom: 25px;
}
.srnew-title-mobile{
    /* height: 35px; */
    height: 0px;
    display: none;
    width: 92.5%;
    padding-left: 7.5%;
    padding-bottom: 20px;
    font-size: 35px;
    text-align: left;
    /* text-indent: 50px; */
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    /* padding-bottom: 25px; */
    /* background: red; */

}

.srnew-holder{
    height: calc(100% - 45px);
    width: calc(100% - 30px);
    background: linear-gradient(90deg, #4f9eff,#2c68bd);
    border-radius: 35px;
    /* margin-left: 20px; */
    display: flex;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
    align-items: center;

}

.srnew-holder-mobile{
    height: calc(100% - 70px);
    /* width: calc(100% - 30px); */
    width: 92%;
    background: linear-gradient(90deg, #4f9eff,#2c68bd);
    border-radius: 35px;
    /* margin-left: 20px; */
    display: flex;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
    align-items: center;
    justify-content: center;
}


.srnew-holder:hover{
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
}

.srnew-icon{
    height: 100%;
    width:250px;
    /* background: red; */
    background-image: url('/public/srplus.png');
    background-size: 150px;
    background-position: center;
    background-repeat: no-repeat;
}

.srnew-textholder{
    height: 100px;
    /* width: calc(100% - 250px); */
    /* background: green; */
    text-align: left;
    color: white;
    font-size: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.srnew-textholder-mobile{
    height: 100px;
    /* background: red; */
    /* width: calc(100% - 250px); */
    /* background: green; */
    text-align: left;
    color: white;
    font-size: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.srnew-subtitle{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.srnew-subtext{
    height: 80px;
    width: 280px;
    font-size: 18px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-style: normal;

}






.srprev-container{
    height: calc(100% - 20px);
    width: calc(50% - 5px);
    /* margin-left: 10px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.srprev-title{
    height: 35px;
    width: 100%;
    /* background: green; */
    font-size: 35px;
    text-align: left;
    text-indent: 50px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    padding-bottom: 25px;
}
.srprev-holder{
    height: calc(100% - 45px);
    width: calc(100% - 15px);
    background: linear-gradient(90deg, #D3D3D3,#C0C0C0);
    border-radius: 35px;
    margin-left: 15px;
    display: flex;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
    align-items: center;
}

.srprev-holder-mobile{
    height: calc(100% - 70px);
    width: calc(100% - 30px);

    border-radius: 35px;
    /* margin-left: 20px; */
    display: flex;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #D3D3D3,#C0C0C0);
 
}

.srprev-holder:hover{
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
}


.srprev-icon{
    background-image: url('/public/history-icon.png');
    background-size: 150px;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width:250px;

}

.modal-info{
    padding-left: 10px;
    font-weight: 500;
}

.modal-contact{
    height: 400px;
    max-width: 375px;
    width: 75%;
    padding: 30px;
    background: white;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 300;
    font-style: normal;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
#modal-title2{
    padding-top: 10px;
    /* padding-bottom: 20px; */
}
#modal-title-2{
    padding-bottom: 15px;
}
.modal-email{display: none;}
.modal-title{
    height: 25px;
    width: calc(100% - 10px);
    font-size: 13px;
    text-align: left;
    display: flex;
    align-items: center;
    padding-left: 10px;
    color: var(--text-colour);
}
.modal-email{
    height: 38px;
    width: calc(100% - 4px);
    border-radius: 15px;
    border: 2px solid var(--launch-colour);
    text-indent: 20px;

}

.modal-message{
    min-height: 40px;
    max-height: 101px;
    height: 101px;
    width: calc(100% - 44px);
    border-radius: 15px;
    border: 2px solid var(--launch-colour);
    margin-bottom: 75px;
    padding: 20px;
    min-width: calc(100% - 44px);
    max-width: calc(100% - 44px);

}

.modal-submit{
    height: 45px;
    width: 100px;
    position:inherit;
    right: 30px;
    bottom: 30px;
    border-radius: 15px;
    background: var(--worky-colour);
    color: white;
    border: none;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    cursor: pointer;
}

.modal-submit:hover{
    background: var(--hightlight-colour);
    font-weight: 500;

}


.modal-extend-shifts{
    height: 335px;
    max-width: 365px;
    width: 90%;
    padding-top: 15px;
    padding-bottom: 65px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 25px;
    background: white;
}

.extend-modal-submit{
    height: 45px;
    width: 100px;
    position:absolute;
    right: 20px;
    bottom: 20px;
    border-radius: 15px;
    background: var(--worky-colour);
    color: white;
    border: none;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    cursor: pointer;
}




.new-start-time{
    display: flex;
    /* background: blue; */
    height: 100px;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 20px;
}

.previous-time{
    height: 100%;
    /* width: 320px; */
    /* background: red; */
    display: flex;
    flex-direction: column;
}
.new-time{
    height: 100%;
    /* width: 320px; */
    /* background: red; */
    display: flex;
    flex-direction: column;
}

#modal-prev-time{
    padding-top: 0px;
    color: var(--launch-highlight);
    height: 60px;
    /* background: green; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.extend-modal-submit:hover{
    background: var(--hightlight-colour);
    font-weight: 500;

}



.confirm-shiftchange-modal{
    height: auto;
    max-width: 360px;
    width: 80%;
    padding: 20px;
    position: absolute;
    background: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.cancel-shiftchange-button-holder{
    height: 40px;
    width: 195px;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    gap: 15px;
}

.cancel-shiftchange-button{
    height: 40px;
    width: 90px;
    border-radius: 10px;
    border: none;
    background: var(--launch-colour2);
    color: var(--text-colour);
    font-weight: 500;    font-family: "mendl-sans-dusk", sans-serif;
    cursor: pointer;
}

.cancel-shiftchange-button:hover{
    background: var(--launch-colour) ;
    font-weight: 600; 
}

#cancel-shiftchange-button-no{
    background: var(--worky-colour);
    color: white;
}

.confirm-shiftchange-reciept-holder{
    padding: 15px;
    border-radius: 20px;
    background: var(--launch-colour2);
    height: 300px;
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 17px;

}








.worky-footer{
    height: 35px;
    width: 100px;
    /* position: absolute; */
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 22px;
    color: var(--launch-highlight);
    user-select: none;
    transition:cubic-bezier(0.215, 0.610, 0.355, 1) 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.worky-footer:hover{
    color: var(--worky-colour);
}


.modal-close{
    background: none;
    border: none;
    position:absolute;
    top: 15px;
    right: 15px;
    color: var(--launch-highlight);
    cursor: pointer;
}
.modal-close:hover{
    color: var(--text-colour);
}



.top-hp{
    height: auto;
    width: 100%;
    margin-top: 70px;
    /* background: green; */
    padding-top: 20px;
    display: flex;
}
.top-hp-mobile{
    height: 500px;
    width: 100%;
    margin-top: 70px;
    /* background: green; */
    display: flex;
    flex-direction: column-reverse;

}


.mybudget{
    height: 275px;
    width: 30%;
    /* margin-top: 70px; */
    position: absolute;
    right:10%;
    display: flex;
    flex-direction: column;
    /* background: red; */
}

.mybudget-mobile{
    height: 275px;
    width: 100%;
    /* margin-top: 70px; */
    /* position: absolute; */
    right:10%;
    display: flex;
    flex-direction: column;
    /* background: red; */
    /* background: blue; */
}

.mybudget-card{
    height: 175px;
    width: calc(100% - 15px);
    display: flex;
    background: white;
    margin-top: 15px;
    border-radius: 30px;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
    transition: 0.1s ease-in-out;
    border:none;
    cursor: pointer;
}
.mybudget-card-mobile{
    height: 175px;
    width: calc(90%);
    margin: auto;
    display: flex;
    background: white;
    margin-top: 10px;
    border-radius: 30px;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
    transition: 0.1s ease-in-out;
    border:none;
    cursor: pointer;
}


.mybudget-card:hover{
    box-shadow: 0 0 5px rgba(0,0,0,0.222);
}

.mybudget-card-left{
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    align-items: center;
    /* margin-top: 20px; */
    gap: 2.5px;
    justify-content: center;
    /* background: red; */
}
.mybudget-left-title{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
    font-size: 15px;
}
.circle-inner-value{
    /* background: red; */
    width: 100px;
    position: absolute;
    /* margin-top: 32.5px; */
    /* margin-left: 20px; */
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
}
.circle-inner-value-modal{
    /* background: red; */
    /* padding-left:15px ; */
    width: 100px;
    position: absolute;
    /* margin-top: 32.5px; */
    /* margin-left: 20px; */
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 22px;
    color: var(--text-colour);
}

.progress-holder{
    /* background: red; */
    /* padding-top: 10px; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;

}

.mybudget-card-right{
    height: 97.5%;
    margin-top: 2.5%;
    width: 55%;
    /* background: blue; */
}

.weekly-budget-holder{
    height: 100%;
    width: 100%;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: center
}

.weekly-progress-container{
    /* height: 140px; */
    width: 100%;
    /* background: green; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap:8px;
}


.weekday-holder{
    /* height: 125px; */
    width: 21px;
    /* background: blue; */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    justify-content: space-between;
}

.weekly-progress-outer{
    height: 90px;
    width: 10px;
    background: var(--launch-colour);
    border-radius: 5px;
    display: flex;
    
}

.weekly-progress-inner{
    width: 10px;
    background: var(--worky-colour);
    border-radius: 5px;
    margin-top: auto;
    max-height: 100%;
}

.weekday{
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
    font-size: 14px;
}

.calendar-button{
    padding: 7px;
    background: none;
    border: none;
    /* background: var(--launch-colour); */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 10px;
    color: var(--text-colour);
    border-radius: 5px;
}
.calendar-button-mobile{
    padding: 7px;
    background: none;
    border: none;
    /* background: var(--launch-colour); */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 10px;
    color: var(--text-colour);
    border-radius: 5px;
}

.calendar-button:hover{
    /* color: white; */
    background: var(--launch-colour);

}


.modal-budget{
    height: auto;
    width: calc(90% - 60px);
    max-width: 650px;
    display: flex;
    flex-direction: column;
    max-height: 75vh;
    overflow: hidden;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 30px;
    background: white;
    border-radius: 25px;

}
.modal-budget-mobile{
    height: auto;
    width: calc(90% - 60px);
    max-width: 650px;
    display: flex;
    flex-direction: column;
    max-height: 75vh;
    overflow-y: scroll;



    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 30px;
    background: white;
    border-radius: 25px;

}

.budget-modal-info{
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-top: 10px;
}


.budget-modal-holder-top{
    height: 100%;
    width: 60%;
    /* background: red; */
    /* background-color: var(--launch-colour2); */
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    /* padding-top: 15px; */
    padding-bottom: 5px;
}
.budget-modal-holder-top-mobile{
    height: 100%;
    width: 100%;
    /* background: red; */
    /* background-color: var(--launch-colour2); */
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    padding-top: 15px;
    padding-bottom: 5px;
}


.modal-budget-top-left{
    height: auto;
    width: 100%;
    /* background: green; */
    display: flex;
    flex-direction: column;
}


.modal-budget-top-right{
    height: 100%;
    width: 100%;
    /* background: blue; */
    display: flex;
    flex-direction: column;
    align-items: center;
}


.modal-budget-progress-title {
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
    font-size: 20px;
    padding-top: 15px;
    padding-bottom: 5px;
    text-align: center;
    /* padding-left: 25px; */
}
  
.modal-budget-progress-holder {

    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-left: 40px; */
    /* background: red; */
    padding-top: 10px;
    height: 100%;
}


.modal-budget-allotment {}

.modal-budget-allotment-title {
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
    font-size: 16px;
    padding-top: 14px;
    text-align: center;
}

.modal-budget-allotment-value {
    height: 64px;
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 30px;
    /* text-indent: 10px; */
    color: var(--dark-text);
}

.budget-modal-holder-bottom{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.weekday-spend{
    height: 50px;
    /* width: calc(100% - 60px); */
    background-color: var(--launch-colour2);
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
    justify-content: space-between;
}
/* style={{ marginBottom: "0px", boxShadow: "none", minHeight: "40px", display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", background: "#f5f5f7", borderRadius: "20px" }} */

.weekday-accordian::before{
    display: none;
}
.weekday-acc-inner{
    /* background: red; */

    
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-colour);
}

.weekday-spend-val{
    font-weight: 600;

}


.modal-calendar{
    height: auto;
    width: calc(90% - 60px);
    max-width: 1000px;







    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 30px;
    background: white;
    border-radius: 25px;

}

.calendar-title-hp{
    height: 35px;
    /* width: auto; */
    /* padding-left: 12%; */
    font-size: 35px;
    text-align: center;
    /* text-indent: 25px; */
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    padding-bottom: 50px;
    margin-top: 20px;
}

.calendar-container{
    height: 500px;
    width: calc(80% - 80px);
    padding: 40px;
    padding-bottom: 70px;
    margin: auto;
    background: white;
    margin-top: 20px;
    border-radius: 30px;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
    overflow: hidden;
}

.calendar-title-mobile-hp{
    height: 35px;
    /* width: auto; */
    /* padding-top: 30px; */
    /* padding-left: 2.5%; */
    font-size: 35px;
    text-align: left;
    /* text-indent: 25px; */
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--text-colour);
    padding-bottom: 30px;
    /* margin-top: 70px; */
}





.weekday-num-shifts-count-holder{
    /* height: 40px; */
    /* width: 100%; */
    /* background: red; */
    margin-top: -15px;
    border-top: 1px solid var(--launch-colour);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: var(--text-colour);
    font-size: 15px;
    padding-top: 5px;
}

.weekday-num-shifts-count-title{
    margin-left: 20px;
}

.weekday-num-shifts-count-value{
    margin-right: 24px;
    font-weight: 600;

}
:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
    --sr-complete:lightgreen;
    --sr-failed:red;
    --sr-inprogress:gold;

}

.opex-card{
    height: calc(100% - 60px);
    width: calc(100% - 130px);
    border-radius: 30px;
    display: absolute;
    margin-left: 100px;
    margin-top: 30px;
    display: flex;
    gap: 30px;

}



.operations-pool{
    height: 100%;
    width: calc(70% - 15px);
    border-radius: 30px;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    background: white;

}

.jobber-pool{
    height: 100%x;
    width: calc(30% - 15px);
    border-radius: 30px;
    background: white;    
    box-shadow: 0 0 5px rgba(0,0,0,0.1);

}


.opex-title{
    padding-top: 20px;
    padding-bottom: 20px;

    width: 100%;
    /* background: red; */
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
    color: var(--text-colour);
}


.sr-block-holder{
    width: calc(100% - 60px);
    height: calc(100% - 100px);
    margin: auto;
    /* background: lightgrey; */
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.sr-block-holder::-webkit-scrollbar {
    display: none;
  }

.sr-block{
    height: auto;
    width:100%;
    background: var(--launch-colour);
    margin-bottom: 30px;
    border-radius: 30px;
    padding-bottom: 1px;

}

.sr-block-orgtitle{
    height: 20px;
    width: 100%;
    font-size: 21px;
    text-align: left;
    text-indent: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 700;
    color: var(--hightlight-colour);
}

.sr-subblock{
    height: 70px;
    width: calc(100% - 30px);
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-around;
    align-items: center;
    border-radius: 30px;
    margin-bottom: 15px;
}

.sr-subblock-date{
    /* height: 100%; */
    width: 150px;
    /* background: white; */
}

.sr-subblock-jobbers{
    height: 100%;
    width: 250px;
    background: white;
    overflow: scroll;
    overflow-x: hidden;
}

.sr-subblock-jobber-profile{
    height: 30px;
    width: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    background: var(--launch-colour);
    border-radius: 10px;
}

.sr-subblock-assign{
    height: 50px;
    width: 100px;
}

.sr-subblock-status{
    height: 50px;
    width: 50px;
    background: var(--sr-complete);
    border-radius: 50px;
    margin-right: 10px;
    border: solid 2px black;
}






.sr-profile-holder{
    height: calc(100% - 90px);
    width: calc(100% - 60px);
    margin-left: auto;
    margin-right: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    /* background: red; */
}

.sr-jobber-profile{
    height: 60px;
    width: 100%;
    background: var(--launch-colour);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-around;
    margin-bottom: 15px;
}

.sr-jobber-profile-name{
    height: auto;
    width: auto;
    padding: 10px;
}

.sr-jobber-profile-phone{
    height: auto;
    width:90px;
    text-align: center;

}

.sr-jobber-profile-status{
    height: 40px;
    width: 40px;
    border-radius: 50px;
    border: 2px solid black;
    background: var(--sr-complete);
}



.tester{
    height: 600px;
    width: 80%;
    margin: auto;
}
:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --launch-colour2:#f5f5f7;
    --launch-highlight:#bbbbbb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}




.mobile-app-nav{
    height: 55px;
    width: 90%;
    position:fixed;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.078);
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    margin-top: -50px;
}

.mobile-app-nav-v2{
    /* background: red; */
    margin-left: 5%;
    position: absolute;
    margin-top: -50px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    width: 90%;
    /* background: yellow; */
}


.mobile-company-name{
    color: var(--text-colour);
    font-family: "mendl-sans-dawn", sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding-top: 2.5px;
}

.mobile-app-nav-left{
    height: 45px;
    width: 128px;
    display:flex;
    align-items: center;
    justify-content: space-between;
    /* background: blue; */
}



.mobile-app-logo{
    font-size: 26px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: var(--worky-colour);
    margin-top: -0.5px;

    /* padding-left: 20px; */
    /* height: 45px; */
    /* width: 190px; */
    /* background: red; */
    cursor: pointer;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
}

.mobile-app-logo:hover{
    color: var(--hightlight-colour);
}


.mobile-app-tray{
    height: 45px;
    /* width: 45px; */
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: none;
    border: none;
}










.mobile-app-nav-right{
    /* background: red; */
}





.mobile-profile-icon{
    height: 35px;
    width: 35px;
    /* border: solid 2px var(--text-colour); */
    /* background: red; */
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Added overflow property */
    z-index: 10000000000;
    /* margin-left:10px; */
}

.mobile-profile-icon img{
    height: 37px;
    width: 37px;
    border-radius: 100%;
}

#mobile-profile-popper-open{
    width: 200px;
    display: flex;
    flex-direction: column;
}


.profile-info-popper-point-container{
    height: 10px;
    width: 100%;
}

.profile-info-popper{
    height: 140px;
    width: 100%;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 50px rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    z-index: 100000000;
}

.popper-row-name{
    height: 1px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: "mendl-sans-dusk", sans-serif;
    color: var(--text-colour);
    font-weight: 500;
    font-size: 13px;
}

.popper-row-icon{
    height: 100%;
    width: 22.5%;
    margin-left: 7.5%;
    /* background: blue; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-colour);
    color: var();
}


.popper-row-info{
    height: 100%;
    /* background: red; */
    width: 70%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    font-family: "mendl-sans-dusk", sans-serif;
    color: var(--text-colour);
    font-weight: 500;
    font-size: 14px;
}

.popper-row{
    height: 20px;
    width: 100%;
    display: flex;
    cursor: pointer;
    /* background: red; */
}




.popper-line{
    height: 1px;
    width: 80%;
    background: var(--launch-colour);
    /* background: black */
}





















.mobile-app-options-container{
    height: 220px;
    width: 90%;
    margin: auto;
    /* margin-top: -50px; */
    margin-left: 5%;
    margin-right: 5%;
    /* background: red; */
    margin-top: 15px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.078);
    background: white;
    padding-bottom: 15px;
    z-index: 100;
}

.mobile-app-option{
    height: 40px;
    width: auto;
    font-size: 16px;
    background: none;    
    border: none;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: var(--text-colour);
    cursor: pointer;
}

#mobile-app-option-trynow{
    background: var(--worky-colour);
    /* width: 150px; */
    width: 80%;
    text-align: center;
    /* margin-right: calc(80% - 150px); */
    color: white;
    border-radius: 10px;
    /* padding-left: 30px; */
    /* padding-right: 30px; */
}



.app-tray{
    height: auto;
    padding-bottom: 10px;
    padding-top: 20px;
    /* width: ; */
    width: 100%;
    position: absolute;
    background: white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
}


.app-tray-row{
    height: 40px;
    width: 70%;
    /* background: red; */
    display: flex;
    flex-direction: row;
    /* background: var(--launch-colour2); */
    border-radius: 10px;
    cursor: pointer;
}

.app-tray-row:hover{
    /* background: var(--launch-colour); */
    background: var(--launch-colour2);
}

#tray-worky-logo{
    padding-bottom: 20px;
}
.app-tray-icon{
    height: 100%;
    width: 25%;
    /* background: blue; */
    padding-left: 5%;

    display:flex;
    align-items: center;
    /* justify-content: center; */
    color: var(--launch-highlight);
}

.app-tray-info{
    height: 40px;
    width: 70%;
    /* background: green; */
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding-left: 12.5%;
    font-size: 16px;
    font-family: "mendl-sans-dusk", sans-serif;
    color: var(--text-colour);
    font-weight: 500;
    /* background: red; */
    position: absolute;
    /* margin-left: -30%; */
}


/* .app-tray-close{
    height: 40px;
    width: 40px;
    background: var(--launch-colour2);
    border: none;
    display:flex;
    align-items: center;
    justify-content: center;
    color: var(--launch-highlight);
    border-radius: 100%;
    margin-top: 20px;
    cursor: pointer;

} */
.app-tray-close{
    height: 5px;
    width: 80px;
    background: var(--launch-colour2);
    border: none;
    display:flex;
    align-items: center;
    justify-content: center;
    color: var(--launch-highlight);
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;

}

.app-tray-close:hover{
    background: var(--launch-colour);
}
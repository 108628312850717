:root{
    --worky-colour:#2c68bd;
    --launch-colour:#ebebeb;
    --text-colour:#4c4c4c;
    --dark-text:#333333;
    --hightlight-colour:#1d438a;
}


.body{
    /* background: var(--launch-colour); */
    height: 92.5vh;
    width: 100vw;
    background: var(--launch-colour);
    padding-top: 7.5vh;
}

.view-d{
    height: 85vh;
    width: 90vw;
    /* background: var(--launch-colour); */
    /* background-image: url('/public/vector.jpg'); */
    /* background-image: url('/public/test.jpg'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 
    /* background: white; */
    margin: auto;
    border-radius: 30px;
    /* margin-top: 7.5vh; */
    overflow: hidden;
    box-shadow: 0 0 40px rgba(0,0,0,0.1);
}


.left-d{
    height: 100%;
    width: 37%;
    float: left;
}

.right-d{
    height: 100%;
    width: 63%;

    float: left;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
}


.right-span{
    color: var(--worky-colour);
}

.right-title-d{
    padding-top: 12%;
    padding-left: 5%;
    padding-right: 5%;

    height: 10%;
    width: 90%;
    /* background: red; */
    color: #a0a0a0;
    /* color: var(--hightlight-colour); */
    font-size: 50px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.right-img-d{
    height: 70%;
    width: 100%;
    /* background-image: url('/public/time.jpg'); */
    background-color: #fff;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; 
}


.left-logo-d{
    width: 100%;
    /* background:green; */
    margin-top: 75px;
    font-size: 50px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: var(--worky-colour);
    transition: all ease-in-out 0.15s;
    text-decoration: none;

}

a:link{
    text-decoration: none;
}

.left-logo-d:hover{
    /* color: var(--hightlight-colour); */
    /* font-weight: 900; */
    /* font-size: 55px; */
    color: var(--hightlight-colour);

}


.optionholder-d{
    height: 50px;
    /* background: red; */
    margin-top: 25px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    

}


.option-d{

    /* border-bottom: var(--launch-colour) solid 2px; */


    height: 50px;
    background: none;
    border: none;
    width: 120px;
    float: left;

    margin-left: 15px;
    margin-right: 15px;
    border-bottom: white solid 2.5px;




    font-size: 16px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 100;
    font-style: normal;    transition: all ease-in-out 0.12s;

}
.option-d:hover{
    border-bottom: var(--launch-colour) solid 2.5px;
}

.option-d.active{
    border-bottom: var(--worky-colour) solid 2.5px;
}


.loginholder-d{
    height: 140px;
    background: white;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
    padding-top: 25px;
    width: 80%;
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    border-radius: 30px;
    
}


.login-user-d{
    height: 45px;
    width: 85%;
    background: white;
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px;
    border: solid 2px var(--launch-colour);
    text-indent: 30px;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 100;
    font-style: normal;

}
.login-pass-d{
    height: 45px;
    width: 85%;
    text-indent: 30px;

    background: white;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 100;
    font-style: normal;
    border-radius: 30px;
    border: solid 2px var(--launch-colour)
}

.login-button{
    height: 40px;
    width: 150px;
    border-radius: 25px;
    margin-top: 75px;
    background: var(--worky-colour);
    color: white;
    border: 2px var(--worky-colour) solid;
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 100;
    font-style: normal;
    transition: all ease-in-out 0.12s;

}

.login-button:hover{
    background: var(--hightlight-colour);
    border: 2px var(--highlight-colour) solid;
}


.forgot-pass{
    border: none;
    width: 100%;
    background: none;
    color: var(--worky-colour);
    font-size: 12px;
    font-family: "mendl-sans-dusk", sans-serif;
    font-weight: 500;
    font-style: normal; 
    transition: all ease-in-out 0.12s;
    margin-top: 45px;
}


.forgot-pass:hover{
    color: var(--hightlight-colour);
}